import React from "react";
import { formatNumber, formatNumberandComma } from "../../../services";

import "./index.css";

const PurchaseListings = ({ purchaseListing }) => {
  return (
    <>
      {purchaseListing.length ? (
        <div className="card my-2 rounded-0">
          <div className="card-header">
            <h5 className="text-capitalize">Supplier Orders</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive sellers">
              <table className="table table-border">
                <thead>
                  <tr>
                    <th scope="col" className="column">
                      Order Id
                    </th>
                    <th scope="col" className="column">
                      Order Amount
                    </th>
                    <th scope="col" className="column">
                      Pending Amount
                    </th>
                    <th scope="col" className="column">
                      Order Date
                    </th>
                    <th scope="col" className="column">
                      Paid
                    </th>
                    <th scope="col" className="column">
                      Recieved
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseListing?.map((order, ind) => (
                    <tr key={ind}>
                      <td>{order.id}</td>
                      <td>{formatNumberandComma(order.total_order_amount)}</td>
                      <td>
                        {order?.pending_amount
                          ? formatNumberandComma(
                              Math.floor(order?.pending_amount * 100) / 100
                            )
                          : 0}
                      </td>
                      <td>
                        {new Date(order?.created_at).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                      </td>
                      <td>{order.is_paid === "YES" ? "Yes" : "No"}</td>

                      <td>{order.is_received === "YES" ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PurchaseListings;
