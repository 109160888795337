import React, { useEffect, useState } from "react";

import "./index.css";
import Tiles from "./Tiles";
import DashboardListing from "./Listings";
import Charts from "./Charts";
import { getStatsService } from "../../services";
import CustomLoader from "../../components/common/customLoader/customLoader";
import show_Toast from "../../helpers/toast.helper";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";

const Dashboard = () => {
  const [isLoading, setLoading] = useState(false);

  const [stats, setStats] = useState({
    totaProducts: "",
    totalCustomers: "",
    totalSuppliers: "",
    totalUsers: "",
  });

  const [pieChartValues, setPieChatValues] = useState({
    pieChartExpenses: 0,
    pieChartPurchase: 0,
    pieChartSales: 0,
  });

  const [barChartValues, setBarChatValues] = useState({
    barChartPurchase: [],
    barChartSales: [],
  });

  const [listings, setListings] = useState({
    expensesListing: [],
    purchaseListing: [],
    salesListing: [],
  });

  const getStats = async () => {
    try {
      setLoading(true);
      const { data } = await getStatsService();
      if (data?.status === "Success") {
        setStats((pre) => ({
          ...pre,
          totaProducts: data?.data?.products,
          totalCustomers: data?.data?.customers,
          totalSuppliers: data?.data?.suppliers,
          totalUsers: data?.data?.users,
        }));
        setPieChatValues((pre) => ({
          ...pre,
          pieChartExpenses: data?.data?.pie_chart_expenses,
          pieChartPurchase: data?.data?.pie_chart_purchase,
          pieChartSales: data?.data?.pie_chart_sales,
        }));
        setBarChatValues((pre) => ({
          ...pre,
          barChartPurchase: data?.data?.bar_chart_purchase,
          barChartSales: data?.data?.bar_chart_sales,
        }));
        setListings((pre) => ({
          ...pre,
          expensesListing: data?.data?.expenses_listing,
          purchaseListing: data?.data?.purchase_listing,
          salesListing: data?.data?.sales_listing,
        }));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <>
      <HelmetComponent titleText={PAGE_TITLES["DASHBOARD"]} />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <Tiles stats={stats} />
          <Charts
            pieChartValues={pieChartValues}
            barChartValues={barChartValues}
          />
          <DashboardListing listings={listings} />
        </div>
      )}
    </>
  );
};

export default Dashboard;
