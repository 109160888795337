import { combineReducers } from "@reduxjs/toolkit";
import login from "./login";
import menus from "./menus";
import configurations from "./configurations";
import profile from "./profile";
import profiles from "./profiles";
import products from "./products";
import publicConfigurationsList from "./publicConfigurations";

export default combineReducers({
  login,
  profiles,
  menus,
  configurations,
  profile,
  products,
  publicConfigurationsList,
});
