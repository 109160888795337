import React, { Fragment, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import CustomLoader from "../../components/common/customLoader/customLoader";
import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import SelectField from "../../components/form/select";
import { useHistory } from "react-router-dom";
import { UpdatePasswordValidations } from "../../utils/validations/profile.validations";
import ButtonField from "../../components/form/button";
import httpRequest from "../../axios";
import show_Toast from "../../helpers/toast.helper";
import { useSelector } from "react-redux";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const UpdatePassword = () => {
  const history = useHistory();
  const id = useSelector((state) => state.login.user.id);

  const [isLoading, setLoading] = useState(false);

  const [initialValues, setInitialValues] = useState({
    current_password: "",
    password: "",
  });

  const ChangePassword = async (values, setSubmitting) => {
    setLoading(true);
    try {
      var { data } = await httpRequest.post("/users/change/password", values);
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: true,
        message: data.status === "Success" && "Password Updated successfully",
      });
      //   history.push("/profiles/" + profileTypes);
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["ChangePassword"]} />
      <Breadcrumb
        title="Update Password"
        parent="Update Password"
        parentlink="Update Password"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="text-capitalize">{`Change Password`}</h5>
              </div>
              <>
                {isLoading ? (
                  <CustomLoader />
                ) : (
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                      ChangePassword(values, setSubmitting);
                    }}
                    validationSchema={UpdatePasswordValidations}
                    enableReinitialize
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        handleBlur,
                        handleChange,
                        resetForm,
                        isSubmitting,
                      } = props;
                      return (
                        <Form className="form theme-form">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <InputField
                                  placeholder="Current Password"
                                  label="Current  Password"
                                  type="password"
                                  name="current_password"
                                  value={values.current_password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={52}
                                  errorMessage={
                                    errors.current_password &&
                                    touched.current_password &&
                                    errors.current_password
                                  }
                                  error={
                                    errors.current_password &&
                                    touched.current_password
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <div className="col-md-6">
                                <InputField
                                  placeholder="New Paswsord"
                                  label="New Paswsord"
                                  type="password"
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errorMessage={
                                    errors.password &&
                                    touched.password &&
                                    errors.password
                                  }
                                  error={
                                    errors.password && touched.password
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="card-footer d-flex justify-content-end gap-2">
                            <ButtonField
                              className={"btn btn-red"}
                              buttonText={"Reset"}
                              type="button"
                              //   onClick={() => handleReset(resetForm)}
                            />
                            <ButtonField
                              className={"btn btn-primary"}
                              buttonText={"Update"}
                              disabled={isSubmitting}
                              type="submit"
                            />
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdatePassword;
