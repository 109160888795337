import React, { useState } from "react";
import { Formik, Form } from "formik";
import logo from "../../assets/images/endless-logo.png";
import { FORGRTPASSWORD, LOGIN } from "../../constant";
import { forgotPasswordSchema } from "../../utils/validations/auth.validations";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setIsAuthenticated,
  setPermissions,
  setUser,
} from "../../store/slices/login";
import { setAttributeMenus, setUnitsMenus } from "../../store/slices/menus";
import httpRequest from "../../axios/index";
import show_Toast from "../../helpers/toast.helper";
import { FORGET_ENDPOINT } from "../../constant/apiEndPoints";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ArrowDownLeft, ArrowLeft } from "react-feather";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const ForgetPassword = () => {
  const history = useHistory();

  const [initialValues, setInitialValues] = useState({
    email: "",
  });
  const handleChangeEmail = (e) => {
    const { name, value } = e.target;
    setInitialValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const sendEmail = async (values, setSubmitting) => {
    try {
      const response = await httpRequest.post(FORGET_ENDPOINT, values);
      if (response?.data?.status === "Success") {
        show_Toast({
          status: true,
          message: response?.data?.data,
        });
        history.push("/login");
      } else {
        show_Toast({
          status: false,
          message: "Something went wrong",
        });
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <div>
      <HelmetComponent titleText={PAGE_TITLES["ForgotPassword"]} />
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{FORGRTPASSWORD}?</h4>
                          <p>No worries, we’ll send you reset instructions.</p>
                        </div>
                        <Formik
                          initialValues={initialValues}
                          validationSchema={forgotPasswordSchema}
                          onSubmit={(values, { setSubmitting }) => {
                            sendEmail(values, setSubmitting);
                          }}
                          enableReinitialize
                        >
                          {(props) => {
                            const {
                              values,
                              touched,
                              errors,
                              handleBlur,
                              handleChange,
                              isSubmitting,
                            } = props;
                            return (
                              <Form className="theme-form">
                                <InputField
                                  placeholder="Email Address"
                                  label="Email Address"
                                  type="email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChangeEmail}
                                  onBlur={handleBlur}
                                  errorMessage={
                                    errors.email &&
                                    touched.email &&
                                    errors.email
                                  }
                                  error={
                                    errors.email && touched.email ? true : false
                                  }
                                />
                                <div className="form-group form-row mt-3 mb-0 d-grid">
                                  <ButtonField
                                    buttonText="Send email"
                                    type="Submit"
                                    // onClick={handleSubmit}
                                    // disabled={isSubmitting}
                                  />
                                </div>
                                <Link
                                  to="/login"
                                  className="my-3 d-flex justify-content-center align-items-center gap-2"
                                >
                                  <ArrowLeft size={18} />
                                  <span>Back to Login</span>
                                </Link>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
