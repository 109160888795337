import JsPDF, { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import SweetAlert from "sweetalert2";
import httpRequest from "../axios";
import html2pdf from "html2pdf.js";

export const sweetAlertWarning = async (title, text, apiCall) => {
  const result = await SweetAlert.fire({
    title: title,
    text: text,
    // icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes",
    cancelButtonText: "Cancel",
    reverseButtons: true,
  });

  if (result.value) {
    try {
      const responseData = await apiCall();
      if (responseData.status === "Success") {
        SweetAlert.fire({
          title: "Success",
          text: responseData?.data || "",
          // icon: "success",
        });
      } else {
        SweetAlert.fire({
          title: "Error",
          text: responseData?.data || "",
          // icon: "error",
        });
      }
      return responseData;
    } catch (error) {
      SweetAlert.fire({
        title: "Error",
        text: error?.message || "",
        // icon: "error",
      });
      throw error;
    }
  }
};

// import SweetAlert from "sweetalert2";

// export const sweetAlertWarning = (
//   title,
//   text,
//   successMessage,
//   failureMessage,
//   apiCall
// ) => {
//   SweetAlert.fire({
//     title: title,
//     text: text,
//     icon: "warning",
//     showCancelButton: true,
//     confirmButtonText: "Yes",
//     cancelButtonText: "Cancel",
//     reverseButtons: true,
//   }).then((result) => {
//     if (result.value) {
//       apiCall()
//         .then(() => {
//           // API call success
//           SweetAlert.fire(successMessage);
//         })
//         .catch(() => {
//           // API call failure
//           SweetAlert.fire(failureMessage);
//         });
//     } else {
//       SweetAlert.fire(failureMessage);
//     }
//   });
// };

export const getValuesofSingleOrder = async (id, type) => {
  try {
    const { data } = await httpRequest.get(
      type === "customer" ? "customerOrders/" + id : "orders/" + id
    );

    if (data.status === "Success") {
      let order = data?.data || null;

      if (order && order?.data?.length) {
        let products = order?.data?.map((item) => {
          let units;
          if (item.requested_stock.length) {
            units = item.requested_stock.map((stocks) => ({
              id: stocks.unit_id,
              value: parseInt(stocks.stock),
            }));
          }
          return {
            name: item.product_id,
            price: item.product_cost,
            units: units,
          };
        });

        // Cache the values
        return {
          ...order,
          products: products,
        };
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const HtmlToPdf = (html, billFormat, popup = false) => {
  const element = document.createElement("div");
  element.innerHTML = html;
  const elementCopy = element.cloneNode(true);
  elementCopy.style.position = "absolute";
  elementCopy.style.left = "-9999px";
  document.body.appendChild(elementCopy);
  const divHeight = elementCopy.offsetHeight;
  elementCopy.remove();
  const height = divHeight / 96 + (divHeight <= 5 ? 0.5 : 1.5);

  let pdfOptions = {
    scale: billFormat === "Regular" ? 0.5 : 1,
    filename: "bill.pdf",
    margin: [0, 0],
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { dpi: 192, letterRendering: true },
    jsPDF: {
      format: billFormat === "Regular" ? [4, height] : [8.27, height],
      autoPaging: false,
      unit: "in",
      orientation: "portrait",
      hotfixes: ["px_scaling"],
    },
  };

  html2pdf()
    .set(pdfOptions)
    .from(element)
    .outputPdf("blob")
    .then((pdfBlob) => {
      const pdfUrl = URL.createObjectURL(pdfBlob);
      if (popup) {
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        iframe.onload = () => {
          iframe.contentWindow.print();
        };

        iframe.src = pdfUrl;
      } else {
        window.open(pdfUrl, "_blank");
      }
    });
};

export const ConvertHtmlToPdf = (html, format, popup = true) => {
  try {
    const doc = new jsPDF({
      unit: "px",
      format: format,
      margins: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
      contentArea: { width: "100%", height: "100%" },
      autoPaging: false,
    });

    doc.html(html, {
      y: 10,
      width: "100%",
      async callback(doc) {
        const blob = doc.output("blob");
        const blobUrl = URL.createObjectURL(blob);

        if (popup) {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          document.body.appendChild(iframe);

          iframe.onload = () => {
            iframe.contentWindow.print();
          };

          iframe.src = blobUrl;
        } else {
          window.open(blobUrl, "_blank");
        }
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const calculateHeightOfRegularBillHtml = (html) => {
  // Create a temporary DOM element to hold the HTML.
  const tempElement = document.createElement("div");
  tempElement.innerHTML = html;

  // Append the temporary DOM element to the document body.
  document.body.appendChild(tempElement);

  // Get the height of the temporary DOM element.
  const height = tempElement.offsetHeight;

  // Remove the temporary DOM element from the document body.
  document.body.removeChild(tempElement);

  return height;
};
