import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { AddNewUser } from "../../constant";

import UserForm from "./form";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const AddUser = () => {
  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Users"]} />
      <Breadcrumb title="Add New User" parent="Users" parentlink="users" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="text-capitalize">{AddNewUser}</h5>
              </div>
              <UserForm />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddUser;
