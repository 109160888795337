import React, { useEffect, useState } from "react";
import InputField from "../../../components/form/input/input";
import ButtonField from "../../../components/form/button";
import SearchSelectField from "../../../components/form/select/SearchSelect";
import { FieldOptions, PaymentOptions } from "../../../constant";
import FilterAccordian from "../../../components/common/Filter/filter";
import { useSelector } from "react-redux";
import {
  getAllCustomerProfiles,
  getAllSupplierProfiles,
} from "../../../services";

const Filterform = ({ type, setApply, filters, setFilters, onChange }) => {
  const [profilesList, setProfilesList] = useState();
  const { customerProfiles, supplierProfiles } = useSelector(
    (state) => state.profiles
  );

  const handleReset = () => {
    setFilters({
      profile: "",
      OrderId: "",
      orderStatus: "",
      isReceived: "ALL",
      isPaid: "ALL",
      paymentMethod: "",
      createdAt: "",
      totalAmount: "",
      from: "",
      to: "",
      tag: "",
    });
  };

  useEffect(() => {
    if (type === "customer") {
      setProfilesList(customerProfiles);
    } else {
      setProfilesList(supplierProfiles);
    }
  }, [type]);

  return (
    <FilterAccordian>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setApply((pre) => !pre);
        }}
      >
        <div className="border-top p-3">
          <div className="row">
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Profile
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="profile"
                value={filters.profile}
                valueKey="id"
                labelKey="name"
                onChange={(e) => onChange("profile", e.id)}
                options={profilesList}
              />
            </div>
            <div className="col-2">
              <InputField
                label="Order id"
                placeholder={"Order Id"}
                name="OrderId"
                value={filters.OrderId}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Payment method
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="paymentMethod"
                value={filters.paymentMethod}
                valueKey="id"
                labelKey="name"
                onChange={(e) => onChange("paymentMethod", e.id)}
                options={PaymentOptions}
              />
            </div>
            <div className="col-2">
              <InputField
                type="date"
                label="Start Date"
                placeholder={"From"}
                classes={"mb-0"}
                name="from"
                value={filters.from}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-2">
              <InputField
                type="date"
                label="End Date"
                placeholder={"To"}
                classes={"mb-0"}
                name="to"
                value={filters.to}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>

            <div className="col-2">
              <InputField
                label="Tag"
                placeholder={"Tag"}
                name="tag"
                value={filters.tag}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <label className="text-capitalize">Paid</label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="isPaid"
                value={filters.isPaid}
                valueKey="value"
                labelKey="label"
                onChange={(e) => onChange("isPaid", e.value)}
                options={FieldOptions}
              />
            </div>

            <div className="col-2">
              <label className="text-capitalize">Received</label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="isReceived"
                value={filters.isReceived}
                valueKey="value"
                labelKey="label"
                onChange={(e) => onChange("isReceived", e.value)}
                options={FieldOptions}
              />
            </div>
            <div className="col-2">
              <div className="d-flex align-items-end gap-2 h-100">
                <ButtonField
                  buttonText="Reset"
                  onClick={handleReset}
                  className="btn btn-danger"
                />
                <ButtonField type="Submit" buttonText="Apply" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FilterAccordian>
  );
};

export default Filterform;
