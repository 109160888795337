import Dropdown from "react-bootstrap/Dropdown";
import { MoreHorizontal } from "react-feather";
import { hasPermissions } from "../../../helpers/permissions.helper";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import "./index.scss";

const DropdownMenue = ({ dropdownList, id }) => {
  const { permissions } = useSelector((state) => state.login);
  return (
    <Dropdown
      className="position-static"
      style={{ position: "static !importnat" }}
    >
      <Dropdown.Toggle
        variant="none"
        id="dropdown-basic"
        className="dropdown-toggle p-0"
      >
        <MoreHorizontal />
      </Dropdown.Toggle>

      <Dropdown.Menu className="absolute-drop">
        {dropdownList
          .filter((item) => hasPermissions(permissions, item.givenPermissions))
          .map((item, index) => {
            return (
              <>
                {item.link ? (
                  <Link
                    className={`linkEdit ${
                      item?.disabled ? "text-secondary" : ""
                    }`}
                    to={`${item.link}${id}`}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <Link
                    className={`linkEdit ${
                      item?.disabled ? "text-secondary" : ""
                    }`}
                    to="#"
                    onClick={
                      item?.disabled ? (e) => e.preventDefault() : item.onclick
                    }
                  >
                    {item.name}
                  </Link>
                )}
              </>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenue;
