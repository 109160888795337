import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import Spinner from "../Spinner/Spinner";

const ModalBox = ({
  isOpen,
  toggle,
  children,
  title,
  handlePriceUpdate,
  loader,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          onClick={() => handlePriceUpdate()}
          style={{
            width: "110px",
          }}
        >
          {!loader ? (
            "Update"
          ) : (
            <Spinner color={"#fff"} loading={loader} size={15} />
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalBox;
