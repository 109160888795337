import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../components/common/breadcrumb";
import ProductFrom from "./productFrom";
import { getProductById } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const AddProducts = () => {
  const location = useLocation();
  const [preSelctedProducts, setPreSelctedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: "",
    code: "",
    gst: "",
    description: "",
    status: "ACTIVE",
    unit_price: "",
    attributes: [
      {
        name: "",
        attribute_value: "",
      },
    ],
    units: [],
    category_unit_id: "",
    selling_unit_id: "",
  });
  const [cloneProduct, setCloneProduct] = useState(null);

  useEffect(() => {
    const clonedProductId = localStorage.getItem("cloneProduct");
    if (clonedProductId) {
      setCloneProduct(clonedProductId);
    } else {
      setLoading(false);
    }
  }, [cloneProduct]);

  useEffect(async () => {
    if (cloneProduct) {
      try {
        setLoading(true);
        const { data } = await getProductById(cloneProduct);
        const {
          data: {
            data: { status },
          },
        } = await getProductById(cloneProduct);
        if (data.status === "Success") {
          const { category_unit_id } = data?.data;
          const {
            attributes,
            code,
            description,
            gst,
            name,
            unit_price,
            units,
            selling_unit_id,
          } = data?.data?.data;
          let preAtt = [];
          let attributeMOdify = attributes?.map((element, i) => {
            preAtt.push(parseInt(element.key_id));
            return {
              name: element.key_id,
              attribute_value: parseInt(element.value_id),
            };
          });
          let UnitModify = units?.map((item) => {
            return {
              ...item,
              value: item.unit_id,
              label: item.name,
              id: item.unit_id,
            };
          });
          setPreSelctedProducts(preAtt);
          setInitialValues({
            attributes: attributeMOdify,
            code,
            description,
            gst,
            name,
            selling_unit_id,
            status: status,
            unit_price,
            units: UnitModify,
            category_unit_id: category_unit_id,
          });
        }
        setLoading(false);
        localStorage.removeItem("cloneProduct");
      } catch (error) {
        show_Toast({
          status: false,
          message:
            error?.response?.data?.message ||
            "Something went wrong please reload the page or contact with admin",
        });
      }
    }
  }, [cloneProduct]);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Products"]} />
      <Breadcrumb
        title="Add New product"
        parent="Products"
        parentlink="products"
      />
      {loading && cloneProduct ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mt-2">
                <div className="card-header">
                  <h5 className="text-capitalize">Add New Product</h5>
                </div>
                <ProductFrom
                  initialValues={initialValues}
                  type="add"
                  preSelctedProducts={preSelctedProducts}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AddProducts;
