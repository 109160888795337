import React, { Fragment } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import Bookmark from "./bookmark";

const Breadcrumb = ({ title, parent, parentlink }) => {
  return (
    <Fragment>
      <div className="container-fluid" style={{ display: "none" }}>
        <div className="page-header">
          <div className="row">
            <div className="col">
              <div className="page-header-left">
                <h3>{title}</h3>
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <Link to={`/users`}>
                      <Home />
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to={`/${parentlink}`}>{parent}</Link>
                  </li>
                  <li className="breadcrumb-item ">{title}</li>
                </ol>
              </div>
            </div>
            {/* <!-- Bookmark Start--> */}
            <Bookmark />
            {/* <!-- Bookmark Ends--> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Breadcrumb;
