import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import SelectField from "../../components/form/select";
import ButtonField from "../../components/form/button";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getExpenseCategoryService } from "../../services";
import httpRequest from "../../axios";
import { CREATE_UNITS, UNITS, UPDATE_UNITS, EXPENSES, CREATE_EXPENSES, UPDATE_EXPENSES } from "../../constant/apiEndPoints";
import { useSelector } from 'react-redux';
import show_Toast from "../../helpers/toast.helper";
import { ExpenseValidations } from "../../utils/validations/expenses.validations";
import CustomLoader from "../../components/common/customLoader/customLoader";
import { hasPermissions } from "../../helpers/permissions.helper";
import { PERMISSION_NAMES } from "../../constant/routePermissions";
import { Route, Redirect } from "react-router-dom";

const ExpenseForm = ({
  initialValues = {
    expense_category: "",
    amount: "",
    description: "",
  },
  type = "add",
  setTransition,
  formSuccessHandler = () => { },
  editId = "",
}) => {
  const history = useHistory();

  const { permissions } = useSelector(
    (state) => state.login
  );

  const [unitCategoriesList, setUnitCategoriesList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [hideFormNew, setHideFormNew] = useState(false);

  const getExpenseCategoryList = async () => {
    try {
      const { data } = await getExpenseCategoryService();
      if (data.status === "Success") {
        setUnitCategoriesList(data?.data?.data);
      }
    } catch (error) {
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getExpenseCategoryList();
  }, []);

  const createExpense = async (values, setSubmitting) => {
    setLoading(true);
    try {
      if (type === "add") {
        var { data } = await httpRequest.post(CREATE_EXPENSES, values);
      } else {
        var { data } = await httpRequest.put(
          UPDATE_EXPENSES + "/" + editId,
          values
        );
      }

      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: true,
        message:
          data.status === "Success" && type === "edit"
            ? "Expense Updated successfully"
            : "Expense created successfully",
      });
      formSuccessHandler();
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (permissions.length) {
          if (!hasPermissions(permissions, PERMISSION_NAMES["create_expense"])) {
            return <Redirect to={{ pathname: "/not-allowed" }} />;
          }
        }
        createExpense(values, setSubmitting);
      }}
      validationSchema={ExpenseValidations}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          resetForm,
          isSubmitting,
        } = props;
        return (
          <>

            {isLoading ? (
              <div>
                <CustomLoader />
              </div>
            ) :
              <Form className={`form theme-form`} >
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <InputField
                        placeholder="Amount"
                        label="Amount"
                        type="text"
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={errors.amount && touched.amount && errors.amount}
                        error={errors.amount && touched.amount ? true : false}
                      />
                    </div>
                    <div className="col-md-3">
                      <InputField
                        placeholder="Description"
                        label="Description"
                        type="text"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="exampleFormControlSelect9">
                          Category
                        </label>
                        <SelectField
                          labelKey={"title"}
                          className={"form-control digits"}
                          name="expense_category"
                          value={values.expense_category}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={unitCategoriesList}
                          errorMessage={errors.expense_category}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end gap-2">
                      <ButtonField
                        type="button"
                        className="btn btn-red"
                        onClick={() => {
                          setTransition(false)
                          resetForm()
                        }}
                        buttonText="Cancel"
                      />
                      <ButtonField
                        type="Submit"
                        className={"btn btn-primary me-2"}
                        buttonText={type === "edit" ? "Update" : "Create"}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            }
          </>
        );
      }}
    </Formik>
  );
};

export default ExpenseForm;
