import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import {
  getUnitCategoryService,
  getExpenseCategoryService,
} from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import ExpenseForm from "./form";
import { PER_PAGE_API } from "../../constant/pagination";
import { Pagination } from "../../components/common/pagination";
import Search from "../../components/common/search/search";
import { Edit } from "react-feather";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";

const ExpenseCategoriesListing = () => {
  const [expenseCategoriesList, setExpenseCategoriesList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [editId, setEditid] = useState("");
  const [formType, setFormType] = useState("add");
  const [transition, setTransition] = useState(false);
  const [initialValues, setIntialValues] = useState({
    title: "",
  });

  const [search, setSearch] = useState([]);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const getExpenseListing = async () => {
    try {
      setLoading(true);
      const { data } = await getExpenseCategoryService(
        paginationData.perPage,
        paginationData.currentPage,
        search
      );
      if (data.status === "Success") {
        setExpenseCategoriesList(data?.data?.data);
      }
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data.current_page,
        totalRecords: data?.data.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data.total / data?.data?.per_page),
      }));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getExpenseListing();
  }, [paginationData.currentPage, search]);

  const formSuccessHandler = () => {
    getExpenseListing();
  };
  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["ExpenseCategories"]} />
      <Breadcrumb parent="Units" title="listing" parentlink="users" />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div
                className={`card mt-2 section ${
                  transition ? "show-all-categories" : "hide"
                }`}
              >
                <ExpenseForm
                  type={formType}
                  initialValues={initialValues}
                  formSuccessHandler={formSuccessHandler}
                  editId={editId}
                  setTransition={setTransition}
                />
              </div>
            </div>
          </div>
          <div className={`row`}>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h5 className="text-capitalize">{"All Expenses"}</h5>

                  <PermissionBase
                    permissionName={["create_expense_categories"]}
                  >
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        setFormType("add");
                        setTransition(true);
                        setIntialValues({
                          title: "",
                        });
                      }}
                    >
                      Add New
                    </button>
                  </PermissionBase>
                </div>
                <div className="card-body">
                  <div className="mb-3 d-flex justify-content-end">
                    <Search
                      classes="form-control search-input"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPaginationData((pre) => ({
                          ...pre,
                          currentPage: 1,
                        }));
                      }}
                      value={search}
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Title</th>
                          <PermissionBase
                            permissionName={["update_expense_categories"]}
                          >
                            <th scope="col">Action</th>
                          </PermissionBase>
                        </tr>
                      </thead>
                      <tbody>
                        {expenseCategoriesList.length
                          ? expenseCategoriesList.map((categorie, ind) => (
                              <tr key={ind}>
                                <td>{categorie.id}</td>
                                <td>{categorie?.title}</td>
                                <PermissionBase
                                  permissionName={["update_expense_categories"]}
                                >
                                  <td>
                                    <span
                                      className="pointer-cursor"
                                      onClick={() => {
                                        setEditid(categorie.id);
                                        setTransition(true);
                                        setIntialValues({
                                          title: categorie.title,
                                        });
                                        setFormType("edit");
                                      }}
                                    >
                                      <Edit size={20} color="green" />
                                    </span>
                                  </td>
                                </PermissionBase>
                              </tr>
                            ))
                          : null}
                      </tbody>
                      {!isLoading && !expenseCategoriesList.length
                        ? "no data found"
                        : null}
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ExpenseCategoriesListing;
