import React, { useState } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "react-feather";

const Carosels = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="carousel">
      <button className="previous-button" onClick={handlePrevious}>
        <ChevronLeft />
      </button>
      <div className="items-container">
        <div
          className={`item item-hide-on-mobole ${
            currentIndex === (currentIndex + items.length - 1) % items.length
              ? "active"
              : ""
          }`}
        >
          <Link
            className="link-nav text-primary"
            to={
              items[(currentIndex + items.length - 1) % items.length]
                .replace(/\s+/g, "/")
                .toLowerCase() === "customers"
                ? "/profiles/customer"
                : items[(currentIndex + items.length - 1) % items.length]
                    .replace(/\s+/g, "/")
                    .toLowerCase() === "suppliers"
                ? "/profiles/supplier"
                : items[(currentIndex + items.length - 1) % items.length]
                    .replace(/\s+/g, "/")
                    .toLowerCase() === "customer/orders"
                ? "/orders/customer"
                : items[(currentIndex + items.length - 1) % items.length]
                    .replace(/\s+/g, "/")
                    .toLowerCase() === "supplier/orders"
                ? "/orders/supplier"
                : `/${items[(currentIndex + items.length - 1) % items.length]
                    .replace(/\s+/g, "/")
                    .toLowerCase()}`
            }
          >
            {items[(currentIndex + items.length - 1) % items.length]}
          </Link>
        </div>
        <div
          className={`item ${currentIndex === currentIndex ? "active" : ""}`}
        >
          <Link
            className="link-nav text-primary"
            to={
              items[currentIndex].replace(/\s+/g, "/").toLowerCase() ===
              "customers"
                ? "/profiles/customer"
                : items[currentIndex].replace(/\s+/g, "/").toLowerCase() ===
                  "suppliers"
                ? "/profiles/supplier"
                : items[currentIndex].replace(/\s+/g, "/").toLowerCase() ===
                  "customer/orders"
                ? "/orders/customer"
                : items[currentIndex].replace(/\s+/g, "/").toLowerCase() ===
                  "supplier/orders"
                ? "/orders/supplier"
                : `/${items[currentIndex].replace(/\s+/g, "/").toLowerCase()}`
            }
          >
            {items[currentIndex]}
          </Link>
        </div>
        <div
          className={`item item-hide-on-tab ${
            currentIndex === (currentIndex + 1) % items.length ? "active" : ""
          }`}
        >
          <Link
            className="link-nav text-primary"
            to={
              items[(currentIndex + 1) % items.length]
                .replace(/\s+/g, "/")
                .toLowerCase() === "customers"
                ? "/profiles/customer"
                : items[(currentIndex + 1) % items.length]
                    .replace(/\s+/g, "/")
                    .toLowerCase() === "suppliers"
                ? "/profiles/supplier"
                : items[(currentIndex + 1) % items.length]
                    .replace(/\s+/g, "/")
                    .toLowerCase() === "customer/orders"
                ? "/orders/customer"
                : items[(currentIndex + 1) % items.length]
                    .replace(/\s+/g, "/")
                    .toLowerCase() === "supplier/orders"
                ? "/orders/supplier"
                : `/${items[(currentIndex + 1) % items.length]
                    .replace(/\s+/g, "/")
                    .toLowerCase()}`
            }
          >
            {items[(currentIndex + 1) % items.length]}
          </Link>
        </div>
      </div>
      <button className="next-button" onClick={handleNext}>
        <ChevronRight />
      </button>
    </div>
  );
};

export default Carosels;
