import React, { useEffect } from "react";
import { calculateGST, formatNumber } from "../../../services";

const PayableAmount = ({
  payable,
  setPayable,
  setFieldValue,
  products,
  paid_amount,
  addons,
  walletBalance,
  total,
  isPaid,
  isEditMode,
}) => {
  let totalPriceSingleProduct = products.reduce(
    (sum, item) =>
      sum +
      parseFloat(
        item.totalPrice -
          parseFloat(item.discount || 0) +
          calculateGST(
            item.totalPrice - parseFloat(item.discount || 0),
            item.productGst
          ) || 0
      ),
    0
  );

  let totalAmountFromAddons = addons.reduce(
    (sum, addon) => sum + parseFloat(addon.amount || 0),
    0
  );

  const calculateOrderAmount = () => {
    let paidAmount = paid_amount ? paid_amount : 0;
    const TotalAmount = Number(totalPriceSingleProduct.toFixed(2));

    if (isPaid === "NO" && paidAmount < total) {
      paidAmount += walletBalance > 0 ? walletBalance : 0;
    }

    const pay = TotalAmount + totalAmountFromAddons - paidAmount;

    setPayable(Number(pay.toFixed(2)));
  };

  useEffect(() => {
    calculateOrderAmount();
  }, [products, paid_amount, walletBalance, addons]);

  // return <h3>Payable: {Math.floor(payable * 100) / 100}</h3>;
  return <h3>Payable: {payable >= 0 ? payable : 0}</h3>;
};

export default PayableAmount;
