import React from "react";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import SearchSelectField from "../../components/form/select/SearchSelect";
import FilterAccordian from "../../components/common/Filter/filter";
import { IsInStockOptions } from "../../constant";

const ProductFilters = ({ setApply, filters, setFilters, onChange }) => {
  const handleReset = () => {
    setFilters({
      productCode: "",
      status: "",
      inStock: "ALL",
    });
  };

  return (
    <FilterAccordian>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setApply((pre) => !pre);
        }}
      >
        <div className="border-top p-3">
          <div className="row">
            <div className="col-2">
              <InputField
                label="Product Code"
                placeholder={"Product Code"}
                name="productCode"
                value={filters.productCode}
                onChange={(e) => onChange(e.target.name, e.target.value)}
              />
            </div>
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Status
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="status"
                value={filters.status}
                valueKey="value"
                labelKey="name"
                onChange={(e) => onChange("status", e.value)}
                options={[
                  {
                    name: "Active",
                    value: "ACTIVE",
                  },
                  {
                    name: "Inactive",
                    value: "INACTIVE",
                  },
                ]}
              />
            </div>
            <div className="col-2">
              <label htmlFor="" className="text-capitalize">
                Stock
              </label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="inStock"
                value={filters.inStock}
                valueKey="value"
                labelKey="name"
                onChange={(e) => onChange("inStock", e.value)}
                options={IsInStockOptions}
              />
            </div>
            <div className="col-2">
              <div className="d-flex align-items-center gap-2 h-100">
                <ButtonField
                  buttonText="Reset"
                  onClick={handleReset}
                  className="btn btn-danger"
                />
                <ButtonField type="Submit" buttonText="Apply" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FilterAccordian>
  );
};

export default ProductFilters;
