import httpRequest from "../axios/index";
import { utils as XLSXUtils, writeFile as XLSXWriteFile } from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

import {
  ATTRIBUTE,
  ATTRIBUTE_KEYS,
  ATTRIBUTE_VALUES,
  ATTRIBUTE_VALUES_BY_KEY,
  GET_ALL_PROFILES,
  GET_ALL_USERS,
  ORDERS,
  PERMISSIONS,
  ROLES,
  SINGLE_USERS,
  UNITS,
  UNIT_CATEGORIES,
  EXPENSE_CATEGORIES,
  CREATE_EXPENSE_CATEGORIES,
  UPDATE_EXPENSE_CATEGORIES,
  EXPENSES,
  All_PRODUCTS,
  SINGLE_PRODUCT,
  CUSTOMER_ORDERS,
  GET_TRANSACTION,
  CONFIGRATIONS,
  ATTRIBUTESKEYS,
  ATTRIBUTESVALUES,
  GET_ALL_CUSTOMER_PROFILES,
  GET_ALL_SUPPLIER_PROFILES,
  PRODUCT_PRICE_UPDATE,
  STATS,
  All_RETURNS_SUPPLIER,
  All_RETURNS_CUSTOMER,
  ORDER_ADDON,
  WALLET_TRANSACTIONS,
  STOCK_IN_OUT,
} from "../constant/apiEndPoints";

export const getStatsService = () => {
  return httpRequest.get(STATS);
};

export const getRolesService = (pageSize = -1, pageNumber = 1, search = "") => {
  return httpRequest.get(
    ROLES +
      `?pageSize=${pageSize}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=asc${
        search ? `&search=${search}` : ""
      }`
  );
};

export const getPermissionsService = (pageSize = -1, pageNumber = 1) => {
  return httpRequest.get(
    PERMISSIONS +
      `?pageSize=${pageSize}&pageNumber=${pageNumber}&orderByColumn=name&orderByDirection=asc`
  );
};

export const getUsersService = (
  PER_PAGE_API = 5,
  currentPage = 1,
  search = ""
) => {
  return httpRequest.get(
    GET_ALL_USERS +
      `?with[]=role&orderByColumn=id&pageNumber=${currentPage}&pageSize=${PER_PAGE_API}&orderByDirection=asc${
        search ? `&search=${search}` : ""
      }`
  );
};

export const getPaymentHistoryService = (
  perPage = -1,
  currentPage = 1,
  search = "",
  id
) => {
  return httpRequest.get(
    GET_TRANSACTION +
      `?&orderByColumn=id&pageNumber=${currentPage}&pageSize=${perPage}&orderByDirection=asc${
        search ? `&search=${search}` : ""
      }&filters[profile_id]=${id}&with[]=profile`
  );
};

// orderByDirection=asc&search=murad&filters[profile_id]=3&with[]=profile
export const getAllAttributeKeys = (
  perPage = -1,
  pageNumber = 1,
  search = "",
  filters = {}
) => {
  return httpRequest.get(
    ATTRIBUTESKEYS +
      `?pageSize=${perPage}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=asc${
        search ? `&search=${search}` : ""
      }${filters.id ? `&filters[id]=${filters.id}` : ""}${
        filters.name ? `&filters[title]=${filters.name}` : ""
      }${
        filters.status === "ACTIVE" || filters.status === "INACTIVE"
          ? `&filters[status]=${filters.status}`
          : ""
      }`
  );
};
export const getAllAttributeService = () => {
  return httpRequest.get(ATTRIBUTE);
};

export const getAttributeKeys = () => {
  return httpRequest.get(ATTRIBUTE);
};

export const getAttributeValuesById = (id) => {
  return httpRequest.get(ATTRIBUTE_VALUES + "/" + id);
};

export const getUserById = (id) => {
  return httpRequest.get(SINGLE_USERS + "/" + id);
};

export const getRoleById = (id) => {
  return httpRequest.get(ROLES + "/" + id);
};

export const getAttributeValuesByKey = (id, perPage, pageNumber) => {
  return httpRequest.get(
    ATTRIBUTE_VALUES_BY_KEY +
      "/" +
      id +
      `?pageSize=${perPage}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=asc`
  );
};
export const getAllAttribute = (perPage, pageNumber, search, filters) => {
  return httpRequest.get(
    ATTRIBUTESVALUES +
      `?with[]=attribute_key&pageSize=${perPage}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=asc${
        filters.attribute_key
          ? `&filters[attribute_key_id]=${filters.attribute_key}`
          : ""
      }${
        filters.attribute_value
          ? `&filters[value]=${filters.attribute_value}`
          : ""
      }${search ? `&search=${search}` : ""}`
  );
};
// attribute_key: "",
//     attribute_value: "",
export const getAttributeKeyByKeyID = (id) => {
  return httpRequest.get(ATTRIBUTE_KEYS + "/" + id);
};

export const getUnitsService = (
  pageSize = -1,
  pageNumber = 1,
  search = "",
  filters = ""
) => {
  return httpRequest.get(
    UNITS +
      `?with[]=unitCategory&pageSize=${pageSize}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=asc${
        search ? `&search=${search}` : ""
      }${filters.name ? `&filters[name]=${filters.name}` : ""}${
        filters.symbol ? `&filters[symbol]=${filters.symbol}` : ""
      }${filters.value ? `&filters[value]=${filters.value}` : ""}${
        filters.unit_category_id
          ? `&filters[unit_category_id]=${filters.unit_category_id}`
          : ""
      }${
        filters.is_base_unit === 0 || filters.is_base_unit === 1
          ? `&filters[is_base_unit]=${filters.is_base_unit}`
          : ""
      }`
  );
};

export const getExpenseService = (
  pageSize = -1,
  pageNumber = 1,
  search = "",
  fromDate,
  toDate,
  selectedOption
) => {
  return httpRequest.get(
    EXPENSES +
      `?&pageSize=${pageSize}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=desc&with[]=expenseCategory&dateRangeActionFilter[actions][]=created_at${
        fromDate ? `&dateRangeActionFilter[from]=${fromDate}` : ``
      }${toDate ? `&dateRangeActionFilter[to]=${toDate}` : ``}${
        selectedOption ? `&filters[expense_category]=${selectedOption}` : ``
      }${search ? `&search=${search}` : ""}`
  );
};
export const getReportSummary = (
  pageSize = -1,
  pageNumber = 1,
  filters = {}
) => {
  let queryString = `?orderByColumn=id&orderByDirection=asc${
    pageNumber ? `&pageNumber=${pageNumber}` : ""
  }${
    filters.start_date && filters.end_date
      ? `&dateRangeActionFilter[actions][]=created_at`
      : ``
  }${
    filters.start_date
      ? `&dateRangeActionFilter[from]=${filters.start_date}`
      : ``
  }${filters.end_date ? `&dateRangeActionFilter[to]=${filters.end_date}` : ``}`;

  return httpRequest.get("/reports/product-summary-report" + queryString);
};
export const getReportDetail = (
  pageSize = -1,
  pageNumber = 1,
  filters = {}
) => {
  let queryString = `?orderByColumn=id&orderByDirection=asc${
    pageSize ? `&pageSize=${pageSize}` : ""
  }${pageNumber ? `&pageNumber=${pageNumber}` : ""}${
    filters.start_date && filters.end_date
      ? `&dateRangeActionFilter[actions][]=created_at`
      : ``
  }${
    filters.start_date
      ? `&dateRangeActionFilter[from]=${filters.start_date}`
      : ``
  }${filters.end_date ? `&dateRangeActionFilter[to]=${filters.end_date}` : ``}${
    filters.opotion_mode ? `&type=${filters.opotion_mode}` : ``
  }${
    filters.product_breakdown === "NO"
      ? `&product_breakdown=${false}`
      : filters.product_breakdown === "YES"
      ? `&product_breakdown=${true}`
      : ""
  }`;

  return httpRequest.get("/reports/product-detail-report" + queryString);
};

export const getSingleUnitsService = (id) => {
  return httpRequest.get(UNITS + "/" + id);
};

export const getSingleExpenseService = (id) => {
  return httpRequest.get(EXPENSES + "/" + id);
};

export const getSingleAddonService = (id) => {
  return httpRequest.get(ORDER_ADDON + "/" + id);
};

export const getUnitCategoryService = (
  pageSize = -1,
  pageNumber = 1,
  search = ""
) => {
  return httpRequest.get(
    UNIT_CATEGORIES +
      `?pageSize=${pageSize}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=asc${
        search ? `&search=${search}` : ""
      }`
  );
};

export const getExpenseCategoryService = (
  pageSize = -1,
  pageNumber = 1,
  search = ""
) => {
  return httpRequest.get(
    EXPENSE_CATEGORIES +
      `?pageSize=${pageSize}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=asc${
        search ? `&search=${search}` : ""
      }`
  );
};

export const getAddonServices = (
  pageSize = -1,
  pageNumber = 1,
  filters,
  search = ""
) => {
  return httpRequest.get(
    ORDER_ADDON +
      `?orderByColumn=id&orderByDirection=asc&pageSize=${pageSize}&pageNumber=${pageNumber}${
        filters.shopIncome && filters.shopIncome !== "ALL"
          ? `&filters[is_shop_income]=${filters.shopIncome}`
          : ``
      }${
        filters.status && filters.status !== "ALL"
          ? `&filters[status]=${filters.status}`
          : ``
      }${search ? `&search=${search}` : ""}`
  );
};

//transaction
export const getTransactionServices = (
  pageSize = -1,
  pageNumber = 1,
  filters,
  search = ""
) => {
  return httpRequest.get(
    GET_TRANSACTION +
      `?orderByColumn=id&orderByDirection=desc&with[]=profile&pageSize=${pageSize}&pageNumber=${pageNumber}${
        filters.date ? `&filters[deposit_date]=${filters.date}` : ``
      }${
        filters.profileType && filters.profileType !== "ALL"
          ? `&filters[profile_type]=${filters.profileType}`
          : ``
      }${search ? `&search=${search}` : ""}`
  );
};

export const getWalletTransactionServices = (
  pageSize = -1,
  pageNumber = 1,
  filters = {},
  search = ""
) => {
  const { order_id, profile_id, profile_type, type } = filters;
  return httpRequest.get(
    WALLET_TRANSACTIONS +
      `?orderByColumn=id&orderByDirection=desc&with[]=profile&pageSize=${pageSize}&pageNumber=${pageNumber}${
        order_id ? `&filters[order_id]=${order_id}` : ``
      }${
        profile_id !== "" && profile_id !== null
          ? `&filters[profile_id]=${profile_id}`
          : ``
      }${type && type !== "ALL" ? `&filters[type]=${type}` : ``}${
        profile_type && profile_type !== "ALL"
          ? `&filters[profile_type]=${profile_type}`
          : ``
      }${search ? `&search=${search}` : ""}`
  );
};

export const inOutStockServices = (
  pageSize = -1,
  pageNumber = 1,
  filters,
  search = ""
) => {
  return httpRequest.get(
    STOCK_IN_OUT +
      `?orderByColumn=id&orderByDirection=desc&pageSize=${pageSize}&pageNumber=${pageNumber}${
        filters?.order_id ? `&filters[order_id]=${filters?.order_id}` : ""
      }${
        filters?.profile_id !== "" && filters?.profile_id !== null
          ? `&filters[profile_id]=${filters?.profile_id}`
          : ``
      }${
        filters?.product_id !== ""
          ? `&filters[product_id]=${filters?.product_id}`
          : ``
      }${
        filters?.type && filters?.type !== "ALL"
          ? `&filters[type]=${filters?.type}`
          : ""
      }${search ? `&search=${search}` : ""}`
  );
};

// ;

export const getSingleUnitCategoryService = (id) => {
  return httpRequest.get(UNIT_CATEGORIES + "/" + id);
};

export const getAllProfiles = (
  pageSize = -1,
  pageNumber = 1,
  search = "",
  type = "customer"
) => {
  return httpRequest.get(
    (type === "customer"
      ? GET_ALL_CUSTOMER_PROFILES
      : GET_ALL_SUPPLIER_PROFILES) +
      `?pageSize=${pageSize}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=asc&filters[profile_type]=${
        type === "customer" ? "CUSTOMER" : "SUPPLIER"
      }
    ${search ? `&search=${search}` : ""}`
  );
};

export const getAllCustomerProfiles = (
  pageSize = -1,
  pageNumber = 1,
  search = "",
  type = "CUSTOMER"
) => {
  return httpRequest.get(
    GET_ALL_CUSTOMER_PROFILES +
      `?pageSize=${pageSize}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=asc&filters[profile_type]=${type}
    ${search ? `&search=${search}` : ""}`
  );
};

export const getAllSupplierProfiles = (
  pageSize = -1,
  pageNumber = 1,
  search = "",
  type = "SUPPLIER"
) => {
  return httpRequest.get(
    GET_ALL_SUPPLIER_PROFILES +
      `?pageSize=${pageSize}&pageNumber=${pageNumber}&orderByColumn=id&orderByDirection=asc&filters[profile_type]=${type}
    ${search ? `&search=${search}` : ""}`
  );
};

// &filters[data][profile_type]=${type}

export const getProfileById = (id) => {
  // customer
  return httpRequest.get(GET_ALL_CUSTOMER_PROFILES + "/" + id);
};

export const getSupplierProfileById = (id) => {
  // supplier
  return httpRequest.get(GET_ALL_SUPPLIER_PROFILES + "/" + id);
};

export const getAllOrders = (pageSize, pageNumber, searchQuery, filters) => {
  let queryString = `?orderByColumn=id&orderByDirection=desc${
    pageSize ? `&pageSize=${pageSize}` : ""
  }${pageNumber ? `&pageNumber=${pageNumber}` : ""}${
    filters.OrderId ? `&filters[id]=${filters.OrderId}` : ""
  }${
    filters.isReceived === "YES" || filters.isReceived === "NO"
      ? `&filters[is_received]=${filters.isReceived}`
      : ""
  }
  ${filters.orderStatus ? `&filters[status]=${filters.orderStatus}` : ""}${
    filters.totalAmount ? `&amount[total_amount]=${filters.totalAmount}` : ""
  }${
    filters.isPaid === "YES" || filters.isPaid === "NO"
      ? `&filters[is_paid]=${filters.isPaid}`
      : ""
  }
  ${
    filters.paymentMethod
      ? `&filters[payment_method]=${filters.paymentMethod}`
      : ""
  }${searchQuery ? `&search=${searchQuery}` : ""}${
    filters.from ? `&dateRangeActionFilter[from]=${filters.from}` : ""
  }${filters.to ? `&dateRangeActionFilter[to]=${filters.to}` : ""}${
    filters.actions
      ? `&dateRangeActionFilter[actions][]=${filters.actions}`
      : ""
  }${
    filters.from || filters.to
      ? `&dateRangeActionFilter[actions][]=created_at`
      : ""
  }${
    filters?.profile !== "" ? `&filters[profile_id]=${filters?.profile}` : ""
  }&with[]=supplier&filtersNotEqual[total_order_amount]=0`;
  return httpRequest.get(ORDERS + queryString);
};

export const getCustomerOrders = (
  pageSize,
  pageNumber,
  searchQuery,
  filters
) => {
  let queryString = `?orderByColumn=id&orderByDirection=desc${
    pageSize ? `&pageSize=${pageSize}` : ""
  }${pageNumber ? `&pageNumber=${pageNumber}` : ""}&with[]=customer${
    filters.OrderId ? `&filters[id]=${filters.OrderId}` : ""
  }${
    filters.isReceived === "YES" || filters.isReceived === "NO"
      ? `&filters[is_received]=${filters.isReceived}`
      : ""
  }${filters.tag ? `&filters[tag]=${filters.tag}` : ""}${
    filters.orderStatus ? `&filters[status]=${filters.orderStatus}` : ""
  }${
    filters.totalAmount ? `&amount[total_amount]=${filters.totalAmount}` : ""
  }${
    filters.isPaid === "YES" || filters.isPaid === "NO"
      ? `&filters[is_paid]=${filters.isPaid}`
      : ""
  }${
    filters.paymentMethod
      ? `&filters[payment_method]=${filters.paymentMethod}`
      : ""
  }${searchQuery ? `&search=${searchQuery}` : ""}${
    filters.from ? `&dateRangeActionFilter[from]=${filters.from}` : ""
  }${filters.to ? `&dateRangeActionFilter[to]=${filters.to}` : ""}${
    filters.actions
      ? `&dateRangeActionFilter[actions][]=${filters.actions}`
      : ""
  }${
    filters?.profile !== "" ? `&filters[profile_id]=${filters?.profile}` : ""
  }${
    filters.from || filters.to
      ? `&dateRangeActionFilter[actions][]=created_at`
      : ""
  }&filtersNotEqual[total_order_amount]=0`;

  return httpRequest.get(CUSTOMER_ORDERS + queryString);
};

export const getProductService = (
  pageSize = -1,
  pageNumber = 1,
  search = "",
  filters = {},
  in_stock = "ALL"
) => {
  let query = `?orderByColumn=id&orderByDirection=asc&pageNumber=${pageNumber}&pageSize=${pageSize}&filters[data][in_stock]=${in_stock}${
    filters?.productCode ? `&filters[data][code]=${filters?.productCode}` : ""
  }${filters?.status ? `&filters[status]=${filters?.status}` : ""}${
    search ? `&search=${search}` : ""
  }`;
  return httpRequest.get(All_PRODUCTS + query);
};

export const getCustomerReturnService = (
  pageSize = -1,
  pageNumber = 1,
  search = "",
  filters = {}
) => {
  let query = `?orderByColumn=id&orderByDirection=desc&pageNumber=${pageNumber}&pageSize=${pageSize}${
    filters?.profile !== "" ? `&filters[profile_id]=${filters?.profile}` : ""
  }${filters?.orderId ? `&filters[order_id]=${filters?.orderId}` : ""}${
    filters?.paymentMethod
      ? `&filters[data][transaction_type]=${filters?.paymentMethod}`
      : ""
  }${
    filters?.paymentReturn
      ? `&filters[data][return_adjust]=${filters?.paymentReturn}`
      : ""
  }${
    filters?.startDate || filters?.endDate
      ? `&dateRangeActionFilter[actions][]=created_at`
      : ""
  }${
    filters?.startDate
      ? `&dateRangeActionFilter[from]=${filters?.startDate}`
      : ""
  }${
    filters?.endDate ? `&dateRangeActionFilter[to]=${filters?.endDate}` : ""
  }${`&filters[profile_type]=CUSTOMER`}${search ? `&search=${search}` : ""}`;

  return httpRequest.get(All_RETURNS_CUSTOMER + query);
};

export const getSupplierReturnService = (
  pageSize = -1,
  pageNumber = 1,
  search = "",
  filters = {}
) => {
  let query = `?orderByColumn=id&orderByDirection=desc&pageNumber=${pageNumber}&pageSize=${pageSize}${
    filters?.profile !== "" ? `&filters[profile_id]=${filters?.profile}` : ""
  }${filters?.orderId ? `&filters[order_id]=${filters?.orderId}` : ""}${
    filters?.paymentMethod
      ? `&filters[data][transaction_type]=${filters?.paymentMethod}`
      : ""
  }${
    filters?.paymentReturn
      ? `&filters[data][return_adjust]=${filters?.paymentReturn}`
      : ""
  }${
    filters?.startDate || filters?.endDate
      ? `&dateRangeActionFilter[actions][]=created_at`
      : ""
  }${
    filters?.startDate
      ? `&dateRangeActionFilter[from]=${filters?.startDate}`
      : ""
  }${
    filters?.endDate ? `&dateRangeActionFilter[to]=${filters?.endDate}` : ""
  }${`&filters[profile_type]=SUPPLIER`}${search ? `&search=${search}` : ""}`;

  return httpRequest.get(All_RETURNS_SUPPLIER + query);
};

// &filters[data][profile_type]

export const getProductById = (id) => {
  return httpRequest.get(SINGLE_PRODUCT + "/" + id);
};

export const updateProductPriceById = (id) => {
  return httpRequest.put(PRODUCT_PRICE_UPDATE + "/" + id);
};

export const getConfigrationsService = (pageNumber, pageSize, search) => {
  return httpRequest.get(
    CONFIGRATIONS +
      `?orderByColumn=id&orderByDirection=asc&pageNumber=${pageNumber}&pageSize=${pageSize}${
        search ? `&search=${search}` : ""
      }`
  );
};

// download payment history in excel extension
export const exportToExcel = (data, headers, filename) => {
  const worksheet = XLSXUtils.json_to_sheet(data, { header: headers });
  const workbook = XLSXUtils.book_new();
  XLSXUtils.book_append_sheet(workbook, worksheet, "Sheet 1");
  XLSXWriteFile(workbook, `${filename}.xlsx`);
};

// download payment history in pdf extension

export const generatePdfFromTable = (tableId, callback) => {
  const doc = new jsPDF();

  setTimeout(() => {
    const table = document.getElementById(tableId);

    const data = [];
    const headers = [];
    const headerCells = table.querySelectorAll("thead th");
    headerCells.forEach((cell) => {
      headers.push(cell.innerText);
    });
    data.push(headers);
    const rows = table.querySelectorAll("tbody tr");
    rows.forEach((row) => {
      const rowData = [];
      const cells = row.querySelectorAll("td");
      cells.forEach((cell) => {
        rowData.push(cell.innerText);
      });
      data.push(rowData);
    });
    doc.autoTable({
      head: [data[0]],
      body: data.slice(1),
    });

    callback(doc);
  }, 0);
};

export const generatePdfFromApi = async (id, callback) => {
  const doc = new jsPDF();

  try {
    const res = await getPaymentHistoryService(-1, 1, "", id);
    const headers = ["Id", "Name", "Amount", "Payment Method", "Deposit Date"];
    const rows = res?.data?.data?.data.map((user) => [
      user?.id,
      user?.profile[0]?.data?.name,
      user?.amount,
      user?.transaction_type?.payment_method,
      user?.deposit_date,
    ]);

    doc.autoTable({
      head: [headers],
      body: rows,
    });

    callback(doc);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const findConfigurationByKeyPublic = (key) => {
  return httpRequest.get(`web/configurations/find?key=${key}`);
};

export const findConfigurationByKey = (key) => {
  return httpRequest.get(`/configurations/find?key=${key}`);
};

export const CharacterLimit = (str, limit) => {
  let result = str?.length > limit ? str?.substring(0, limit) + "..." : str;

  return result;
};

export const calculateGST = (totalAmount, gstPercentage) =>
  (totalAmount * gstPercentage) / 100;

export const calculateTotalAmount = (unitQuantity, amountPerUnit) =>
  unitQuantity * amountPerUnit;

export const formatNumber = (number) => {
  if (Number.isInteger(number)) {
    return number.toString();
  } else {
    return number.toFixed(2);
  }
};

export const formatNumberandComma = (number) => {
  if (Number?.isInteger(number)) {
    return number?.toLocaleString();
  } else {
    return number?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
};

export const TotalPriceSingleOrder = (products) => {
  return products.reduce(
    (sum, item) =>
      sum +
      parseFloat(
        item.totalPrice +
          calculateGST(item.totalPrice, item.productGst) -
          item.discount
      ),
    0
  );
};

export const CalculatePayableAmount = (paidAmount = 0, totalPrice) => {
  const payable = totalPrice - paidAmount;
  return payable;
};

export const getAllPublicConfigurations = (data) => {
  return httpRequest.get(
    "/web/configurations/findByKeysArray?key[]=BILL_FORMAT&key[]=ADDRESS&key[]=COMPANY_NAME&key[]=TELEPHONE&key[]=C_ORDER_TERMS_CONDITIONS&key[]=DISCOUNT_COLUMN&key[]=GST_COLUMN&key[]=COMPANY_SHORT_NAME&key[]=APP_MAIN_PAGE_LOGO&key[]=APP_LOGO"
  );
};

// customer order bill
export const BillHtmlA4 = (
  id,
  created_at,
  orderAmount,
  paymentMethod,
  name,
  tel,
  termsConditions,
  address,
  description,
  tag,
  data,
  customer,
  addOns,
  discountColumn,
  gstColumn,
  billFormat
) => {
  const hasDiscount = data.some((item) => item.discount > 0);
  const hasGst = data.some((item) => item.gst_amount > 0);

  const totalAmountProducts = data
    ?.map((item, index) => parseFloat(item.amount_without_gst))
    .reduce((sum, value) => sum + value, 0);

  const totalGStAmountProducts = parseFloat(
    data
      ?.map((item) => parseFloat(item.gst_amount))
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2)
  );

  const totalDiscountAmountProducts = parseFloat(
    data
      .map((item) => parseFloat(item.discount))
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2)
  );

  const totalIncludeGst =
    totalAmountProducts + totalGStAmountProducts - totalDiscountAmountProducts;

  return `
<div id="content" style="page-break-inside: avoid; font-size:12px; width: 445px; margin: 0px; padding: 10px; padding-top: 0px;">
<div>
<div style="text-align: center; margin: 20px;">
   <h3 style="color: #000;">${name}</h3>
   <div>
      <p style="margin:0px;">${address}</p>
      <p style="margin:0px;">Tel: ${tel}</p>
   </div>
   <div style="display: flex; justify-content: space-between;">
      <div style="text-align:start;">
         <p style="margin:0px; font-size:11px;"><strong>Invoice no:</strong> ${id}</p>
         ${
           tag !== null && tag !== ""
             ? `
         <p style="margin:0px; font-size:11px;" ><strong>Tag:</strong> ${tag}</p>
         `
             : ""
         }
      </div>
      <div style="text-align:start;">
         <p style="margin:0px; font-size:11px;"><strong>Name:</strong> ${
           customer || "walk-in"
         }
         </p>
         <p style="margin:0px; font-size:11px;"><strong>Date:</strong> ${created_at}</p>
      </div>
   </div>
</div>
<div style="margin-bottom: 20px;">
<table style="border-top: 1px solid black; border-bottom: 1px solid black; width: 100%; font-size: 12px;">
      <tr style="padding: 8px; text-align:start; color: #000;">
         <th style="text-align:start; border-bottom: 1px solid black; padding: 3px; padding-right: 15px; font-weight: normal;">#</th>
         <th style="text-align:start; border-bottom: 1px solid black; padding: 3px; font-weight: normal;">Qty</th>
         <th style="text-align:start; border-bottom: 1px solid black; padding: 3px; font-weight: normal;">Unit Price</th>
         <th style="text-align:start; border-bottom: 1px solid black; padding: 3px; font-weight: normal;">Price</th>
         ${
           hasGst || gstColumn === "YES"
             ? `<th style="text-align:start; border-bottom: 1px solid black; padding: 3px; font-weight: normal;">GST</th>`
             : ""
         }
         ${
           hasDiscount || discountColumn === "YES"
             ? `<th style="text-align:start; border-bottom: 1px solid black; padding: 3px; font-weight: normal;">Discount</th>`
             : ""
         }
         <th style="text-align:start; border-bottom: 1px solid black; padding: 3px; font-weight: normal;">Amount</th>
      </tr>
      ${data
        ?.map(
          (item, index) =>
            `
      <tr key=${index}>
         <td  style="padding: 3px; padding-right: 15px; white-space: nowrap;" >${
           index + 1
         }
         </td>
         <td colspan="4" style="padding: 3px; white-space: normal; word-wrap: break-word;" >
         ${item.product_name} ${item?.note ? `<br>( ${item?.note} )` : ``}
         </td>
      </tr>
      <tr ${
        index === data.length - 1
          ? 'style="border-bottom: none;"'
          : 'style="border-bottom:0.2px dashed gray;"'
      } >
         <td style="padding: 2px;"></td>
         <td style="padding: 2px;" >
            ${item?.stock_with_selling_unit}
         </td>
         <td style="padding: 2px;" >
            ${item?.product_cost}
         </td>
          <td style="padding: 2px;" >${item.amount_without_gst}
         </td>
        ${
          hasGst || gstColumn === "YES"
            ? `<td style="padding: 2px;" >${item?.gst}% (${item?.gst_amount})
         </td>`
            : ""
        }
         ${
           hasDiscount || discountColumn === "YES"
             ? `<td style="padding: 2px;" >${parseFloat(item?.discount)}</td>`
             : ""
         }
         <td style="padding: 2px;" >${
           item.amount_without_gst + item?.gst_amount - item?.discount
         }
         </td>
      </tr>`
        )
        .join("")}
 <tr style="border-top: 1px solid black; border-bottom: 1px solid black; width: 100%; font-weight:bold;">
<td style="padding: 3px;"></td>
<td style="padding: 3px;"></td>
<td style="padding: 3px; border-right: 1px solid black; text-align:right;"><span>Total&nbsp;</span></td>
  <td style="padding: 3px;">
  <span>
  ${data
    ?.map((item, index) => Number(item.amount_without_gst))
    .reduce((sum, value) => {
      return sum + value;
    }, 0)
    .toLocaleString("en-US", { maximumFractionDigits: 2 })}
  </span>
  </td>

  ${
    hasGst || gstColumn === "YES"
      ? `<td style="padding: 3px;">${parseFloat(
          data
            ?.map((item, index) => item.gst_amount)
            .reduce((sum, value) => {
              const number = parseFloat(value);
              return sum + number;
            }, 0)
            .toLocaleString("en-US", { maximumFractionDigits: 2 })
        )}</td>`
      : ""
  }
  ${
    hasDiscount || discountColumn === "YES"
      ? `<td style="padding: 3px;">${data
          .map((item) => item.discount)
          .reduce((sum, value) => sum + value, 0)
          .toLocaleString("en-US", { maximumFractionDigits: 2 })}</td>`
      : ""
  }
  <td style="padding: 3px;">${totalIncludeGst.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  })}</td>
</tr>
   </table>

    ${
      addOns && addOns?.length > 0
        ? `
        <div style="margin-top: 20px; padding-top: 10px;">
          <strong style="font-size: 12px; margin-left: 5px; margin-bottom: 8px;">Other Services</strong>
          <table style="border-top: 1px solid black; border-bottom: 1px solid black; width: 100%; font-size: 12px;">
            <tr style="padding: 8px; text-align: start; color: #000;">
              <th style="border-bottom: 1px solid black; padding: 3px; font-weight: normal;">#</th>
              <th style="border-bottom: 1px solid black; padding: 3px; font-weight: normal;">Description</th>
              <th style="border-bottom: 1px solid black; padding: 3px; font-weight: normal;">Amount</th>
            </tr>
            ${addOns
              .map(
                (item, index) => `
              <tr key=${index}>
                <td style="padding: 3px;">${index + 1}</td>
                <td style="padding: 3px;">${item.name}</td>
                <td style="padding: 3px;">${item.amount}</td>
              </tr>
            `
              )
              .join("")}
              <tr style="border-top: 1px solid black">
                <td style="padding: 3px;"></td>
                <td style="padding: 3px; border-right: 0.5px solid black; text-align:right; font-weight:bold;">Total</td>
                <td style="padding: 3px; font-weight:bold;">${addOns
                  .reduce((sum, item) => sum + parseFloat(item.amount), 0)
                  .toLocaleString("en-US", { maximumFractionDigits: 2 })}</td>
              </tr>
          </table>
        </div>
      `
        : ""
    }


    <div style="margin-top:15px; padding-right:10px; text-align:right; font-size: 12px">
     <strong style="text-align: right;"> Grand Total: &nbsp; &nbsp; </strong>
     <span style="text-align: left; font-weight:bold;">${orderAmount.toLocaleString(
       "en-US",
       {
         maximumFractionDigits: 2,
       }
     )}</span>
    </div>



      <div>
      ${
        description !== null && description !== ""
          ? `
   <div style="text-align: start; font-size:10px; margin: 20px;">
      <span style="font-weight:bold; margin-bottom:5px; display:block">Note:</span>
      <span style="display: inline-block; word-wrap: break-word;">${description}</span>
   </div>
   `
          : ""
      }
      </div>

      <div style="text-align: center; font-size:16px; margin-top:40px; border-top: 0.5px solid gray; ${
        termsConditions !== "" ? `border-bottom: 0.5px solid gray;` : ``
      } padding-top: 10px; padding-bottom: 10px;">
      <span>Thank You!</span>
   </div>


<div style="text-align: start; margin-top: 20px;">
     ${termsConditions ? termsConditions.replace(/<hr \/>/g, "") : ""}
</div>
</div>
`;
};

export const BillHtmlRegular = (
  id,
  created_at,
  orderAmount,
  paymentMethod,
  name,
  tel,
  termsConditions,
  address,
  description,
  tag,
  data,
  customer,
  addOns,
  discountColumn,
  gstColumn,
  billFormat
) => {
  const hasDiscount = data.some((item) => item.discount > 0);
  const hasGst = data.some((item) => item.gst_amount > 0);

  const totalAmountProducts = data
    ?.map((item, index) => parseFloat(item.amount_without_gst))
    .reduce((sum, value) => sum + value, 0);

  const totalGStAmountProducts = parseFloat(
    data
      ?.map((item) => parseFloat(item.gst_amount))
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2)
  );

  const totalDiscountAmountProducts = parseFloat(
    data
      .map((item) => parseFloat(item.discount))
      .reduce((sum, value) => sum + value, 0)
      .toFixed(2)
  );

  const totalIncludeGst =
    totalAmountProducts + totalGStAmountProducts - totalDiscountAmountProducts;
  return `
<div id="content" style="page-break-inside: avoid; font-size:6px; width: 220px; margin: 0px; padding: 10px; padding-top: 0px;">
<div>
<div style="text-align: center; margin: 20px;">
   <h6 style="color: #000;">${name}</h6>
   <div>
      <p style="font-size:6px; margin:0px;">${address}</p>
      <p style="font-size:6px; margin:0px;">Tel: ${tel}</p>
   </div>
   <div style="display: flex; justify-content: space-between;">
      <div style="text-align:start;">
         <p style="margin:0px; font-size:6px;"><strong>Invoice no:</strong> ${id}</p>
         ${
           tag !== null && tag !== ""
             ? `
         <p style="margin:0px; font-size:6px;" ><strong>Tag:</strong> ${tag}</p>
         `
             : ""
         }
      </div>
      <div style="text-align:start;">
         <p style="margin:0px; font-size:6px;"><strong>Name:</strong> ${
           customer || "walk-in"
         }
         </p>
         <p style="margin:0px; font-size:6px;"><strong>Date:</strong> ${created_at}</p>
      </div>
   </div>
</div>
<div style="margin-bottom: 20px;">
<table style="border-top: 0.5px solid black; border-bottom: 0.5px solid black; width: 100%; font-size: 6px;">
      <tr style="padding: 8px; text-align:start; color: #000;">
         <th style="text-align:start; border-bottom: 0.5px solid black; padding: 3px; padding-right: 15px; font-weight: normal;">#</th>
         <th style="text-align:start; border-bottom: 0.5px solid black; padding: 3px; font-weight: normal;">Qty</th>
         <th style="text-align:start; border-bottom: 0.5px solid black; padding: 3px; font-weight: normal;">Unit Price</th>
         <th style="text-align:start; border-bottom: 0.5px solid black; padding: 3px; font-weight: normal;">Price</th>
         ${
           hasGst || gstColumn === "YES"
             ? `<th style="text-align:start; border-bottom: 0.5px solid black; padding: 3px; font-weight: normal;">GST</th>`
             : ""
         }
         ${
           hasDiscount || discountColumn === "YES"
             ? `<th style="text-align:start; border-bottom: 0.5px solid black; padding: 3px; font-weight: normal;">Discount</th>`
             : ""
         }
         <th style="text-align:start; border-bottom: 0.5px solid black; padding: 3px; font-weight: normal;">Amount</th>
      </tr>
      ${data
        ?.map(
          (item, index) =>
            `
      <tr key=${index}>
         <td  style="padding: 3px; padding-right: 15px; white-space: nowrap;" >${
           index + 1
         }
         </td>
         <td colspan="4" style="padding: 3px; white-space: normal; word-wrap: break-word;" >
         ${item.product_name} ${item?.note ? `<br>( ${item?.note} )` : ``}
         </td>
      </tr>
      <tr ${
        index === data.length - 1
          ? 'style="border-bottom: none;"'
          : 'style="border-bottom:0.2px dashed gray;"'
      } >
         <td style="padding: 2px;"></td>
         <td style="padding: 2px;" >
            ${item?.stock_with_selling_unit}
         </td>
         <td style="padding: 2px;" >
            ${item?.product_cost}
         </td>
          <td style="padding: 2px;" >${item.amount_without_gst}
         </td>
        ${
          hasGst || gstColumn === "YES"
            ? `<td style="padding: 2px;" >${item?.gst}% (${item?.gst_amount})
         </td>`
            : ""
        }
         ${
           hasDiscount || discountColumn === "YES"
             ? `<td style="padding: 2px;" >${parseFloat(item?.discount)}</td>`
             : ""
         }
         <td style="padding: 2px;" >${
           item.amount_without_gst + item?.gst_amount - item?.discount
         }
         </td>
      </tr>`
        )
        .join("")}
 <tr style="border-top: 0.5px solid black; border-bottom: 0.5px solid black; width: 100%; font-weight:bold;">
<td style="padding: 3px;"></td>
<td style="padding: 3px;"></td>
<td style="padding: 3px; border-right: 0.5px solid black; text-align:right;"><span>Total&nbsp;</span></td>
  <td style="padding: 3px;">
  <span>
  ${data
    ?.map((item, index) => Number(item.amount_without_gst))
    .reduce((sum, value) => {
      return sum + value;
    }, 0)
    .toLocaleString("en-US", { maximumFractionDigits: 2 })}
  </span>
  </td>

  ${
    hasGst || gstColumn === "YES"
      ? `<td style="padding: 3px;">${parseFloat(
          data
            ?.map((item, index) => item.gst_amount)
            ?.reduce((sum, value) => {
              const number = parseFloat(value);
              return sum + number;
            }, 0)
            .toLocaleString("en-US", { maximumFractionDigits: 2 })
        )}</td>`
      : ""
  }
  ${
    hasDiscount || discountColumn === "YES"
      ? `<td style="padding: 3px;">${data
          ?.map((item) => item.discount)
          ?.reduce((sum, value) => sum + value, 0)
          ?.toLocaleString("en-US", { maximumFractionDigits: 2 })}</td>`
      : ""
  }
  <td style="padding: 3px;">${totalIncludeGst?.toLocaleString("en-US", {
    maximumFractionDigits: 2,
  })}</td>
</tr>
   </table>

    ${
      addOns && addOns?.length > 0
        ? `
        <div style="margin-top: 20px; padding-top: 10px;">
          <strong style="font-size: 6px; margin-left: 5px; margin-bottom: 8px;">Other Services</strong>
<table style="border-top: 0.5px solid black; border-bottom: 0.5px solid black; width: 100%; font-size: 6px;">
            <tr style="padding: 8px; text-align: start; color: #000;">
              <th style="border-bottom: 0.5px solid black; padding: 3px; font-weight: normal;">#</th>
              <th style="border-bottom: 0.5px solid black; padding: 3px; font-weight: normal;">Description</th>
              <th style="border-bottom: 0.5px solid black; padding: 3px; font-weight: normal;">Amount</th>
            </tr>
            ${addOns
              ?.map(
                (item, index) => `
              <tr key=${index}>
                <td style="padding: 3px;">${index + 1}</td>
                <td style="padding: 3px;">${item.name}</td>
                <td style="padding: 3px;">${item.amount}</td>
              </tr>
            `
              )
              .join("")}
              <tr style="border-top: 1px solid black">
                <td style="padding: 3px;"></td>
                <td style="padding: 3px; border-right: 0.5px solid black; text-align:right; font-weight:bold;">Total</td>
                <td style="padding: 3px; font-weight:bold;">${addOns
                  .reduce((sum, item) => sum + parseFloat(item.amount), 0)
                  .toLocaleString("en-US", { maximumFractionDigits: 2 })}</td>
              </tr>
          </table>
        </div>
      `
        : ""
    }


    <div style="margin-top:15px; padding-right:10px; text-align:right; font-size: 6px">
     <strong style="text-align: right;"> Grand Total: &nbsp; &nbsp; </strong>
     <span style="text-align: left; font-weight:bold;">${orderAmount.toLocaleString(
       "en-US",
       {
         maximumFractionDigits: 2,
       }
     )}</span>
    </div>



      <div>
      ${
        description !== null && description !== ""
          ? `
   <div style="text-align: start; font-size:6px; margin: 20px;">
      <span style="font-weight:bold; margin-bottom:5px; display:block">Note:</span>
      <span style="display: inline-block; word-wrap: break-word;">${description}</span>
   </div>
   `
          : ""
      }
      </div>

   <div style="text-align: center; font-size:10px; margin-top:40px; border-top: 0.5px solid gray; ${
     termsConditions !== "" ? `border-bottom: 0.5px solid gray;` : ``
   } padding-top: 10px; padding-bottom: 10px;">
      <span>Thank You!</span>
   </div>

<div style="margin-top: 20px;">
     ${termsConditions ? termsConditions.replace(/<hr \/>/g, "") : ""}
     </div>

</div>
`;
};
