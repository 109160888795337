import React from "react";
import ButtonField from "../../components/form/button";
import SearchSelectField from "../../components/form/select/SearchSelect";
import FilterAccordian from "../../components/common/Filter/filter";

const Filterform = ({
  filters,
  setFilters,
  onChange,
  onFilterApply,
  fieldOptions,
  onResetApply,
  statusOptions,
}) => {
  return (
    <FilterAccordian>
      <div className="border-top p-3">
        <div className="row">
          <div className="row">
            <div className="col-2">
              <label className="text-capitalize">Shop Income</label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="shopIncome"
                value={filters.shopIncome}
                valueKey="value"
                labelKey="label"
                onChange={(e) => {
                  onChange("shopIncome", e.value);
                }}
                options={fieldOptions}
              />
            </div>
            <div className="col-2">
              <label className="text-capitalize">Status</label>
              <SearchSelectField
                className={"inputclass rounded flex-grow-1"}
                name="status"
                value={filters.status}
                valueKey="value"
                labelKey="label"
                onChange={(e) => {
                  onChange("status", e.value);
                }}
                options={statusOptions}
              />
            </div>
            <div className="col-2">
              <div className="d-flex align-items-end gap-2 h-100">
                <ButtonField
                  buttonText="Reset"
                  onClick={onResetApply}
                  className="btn btn-danger"
                />
                <ButtonField
                  buttonText="Apply"
                  type="submit"
                  onClick={onFilterApply}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FilterAccordian>
  );
};

export default Filterform;
