import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import {
  getConfigrationsService,
  getUnitsService,
  getUsersService,
} from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import ConfigurationForm from "./form";
import Search from "../../components/common/search/search";
import { Pagination } from "../../components/common/pagination";
import { PER_PAGE_API } from "../../constant/pagination";
import { Edit } from "react-feather";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";

import "./index.scss";

const ConfigurationsListing = () => {
  const [configrationsList, setConfigrationsList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const [transition, setTransition] = useState(false);
  const formRef = useRef(null);

  const [editId, setEditid] = useState("");
  const [formType, setFormType] = useState("add");
  const [initialValues, setIntialValues] = useState({
    name: "",
    // key: "",
    value: "",
  });

  const getConfigrationsListing = async () => {
    try {
      setLoading(true);
      const { data } = await getConfigrationsService(
        paginationData.currentPage,
        paginationData.perPage,
        searchQuery
      );
      if (data.status === "Success") {
        setConfigrationsList(data?.data?.data);
      }
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getConfigrationsListing();
  }, [paginationData.currentPage, searchQuery]);

  const formSuccessHandler = () => {
    getConfigrationsListing();
  };

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Configurations"]} />
      <Breadcrumb parent="Units" title="listing" parentlink="users" />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div
                className={`card mt-2 section ${
                  // transition ? "show-congfigrations" : "hide"
                  transition
                    ? editId !== 13
                      ? "show-congfigrationsFormSmall"
                      : `show-congfigrationsForm`
                    : "hide"
                }`}
                ref={formRef}
              >
                <ConfigurationForm
                  type={formType}
                  initialValues={initialValues}
                  formSuccessHandler={formSuccessHandler}
                  editId={editId}
                  setTransition={setTransition}
                  formRef={formRef}
                  setIntialValues={setIntialValues}
                />
              </div>
            </div>
          </div>
          <div className={`row`}>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between items-center">
                  <h5 className="text-capitalize">{"All Configurations"}</h5>
                  {/* <PermissionBase permissionName={["create_configuration"]}>
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        setFormType("add");
                        setTransition(true);
                        setIntialValues({
                          name: "",
                          value: "",
                        });
                      }}
                    >
                      Add Configuration
                    </button>
                  </PermissionBase> */}
                </div>
                <div className="card-body">
                  <div className="mb-3 d-flex justify-content-end">
                    <Search
                      classes="form-control search-input"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setPaginationData((pre) => ({
                          ...pre,
                          currentPage: 1,
                        }));
                      }}
                      value={searchQuery}
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Name</th>
                          <th scope="col">Value</th>
                          {/* <th scope="col">Key</th> */}
                          <PermissionBase
                            permissionName={["update_configuration"]}
                          >
                            <th scope="col">Action</th>
                          </PermissionBase>
                        </tr>
                      </thead>

                      <tbody>
                        {configrationsList.length
                          ? configrationsList?.map((config, ind) => (
                              <tr key={ind}>
                                <td>{config?.id}</td>
                                <td>{config?.name}</td>
                                <td>{config?.value}</td>
                                {/* <td>{config?.key}</td> */}
                                <PermissionBase
                                  permissionName={["update_configuration"]}
                                >
                                  <td>
                                    <span
                                      className="pointer-cursor"
                                      onClick={() => {
                                        setEditid(config.id);
                                        setTransition(true);
                                        setIntialValues({
                                          ...initialValues,
                                          name: config.name,
                                          key: config.key,
                                          value: config.value,
                                        });
                                        setFormType("edit");
                                        window.scrollTo({
                                          top: formRef.current.offsetTop,
                                          behavior: "smooth",
                                        });
                                      }}
                                    >
                                      <Edit size={20} color="green" />
                                    </span>
                                  </td>
                                </PermissionBase>
                              </tr>
                            ))
                          : null}
                        {!isLoading && !configrationsList.length ? (
                          <p className="text-center d-flex">no data found</p>
                        ) : null}
                      </tbody>
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ConfigurationsListing;
