import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import AllAttributeListing from "./allAttrubuteListing";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";

const AddAttribute = () => {
  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Attributes"]} />
      <Breadcrumb title="Add Attribute" parent="Attribute" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <AllAttributeListing />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddAttribute;
