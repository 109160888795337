import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BarChartComponent = ({
  barChartValues: { barChartPurchase, barChartSales },
}) => {
  function getLastSevenDays() {
    const today = new Date();
    const lastSevenDays = [];

    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      lastSevenDays.push(`${year}-${month}-${day}`);
    }

    return lastSevenDays;
  }

  const lastSevenDays = getLastSevenDays();

  function filterDataForLastSevenDays(data, lastSevenDays) {
    return data.filter((entry) => lastSevenDays.includes(entry.date));
  }

  const filteredPurchaseData = filterDataForLastSevenDays(
    barChartPurchase || [],
    lastSevenDays
  );

  const filteredSalesData = filterDataForLastSevenDays(
    barChartSales || [],
    lastSevenDays
  );

  const combinedData = lastSevenDays.map((date) => {
    const purchaseEntry = filteredPurchaseData.find(
      (entry) => entry.date === date
    );
    const salesEntry = filteredSalesData.find((entry) => entry.date === date);
    return {
      date,
      purchaseCount: purchaseEntry ? purchaseEntry.count : 0,
      salesCount: salesEntry ? salesEntry.count : 0,
    };
  });

  return (
    <div className="chart-container d-flex justify-content-start align-items-center">
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          width={500}
          height={250}
          data={combinedData}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            interval={0}
            tick={(props) => (
              <text {...props} dy={14} fontSize={9} fill="#333333">
                {props.payload.value}
              </text>
            )}
          />
          <YAxis
          // domain={[0, "dataMax"]}
          // tickCount={5}
          // tick={[0, 1, 2, 3, 4]}
          // interval={0}
          />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="purchaseCount"
            fill="#8884d8"
            name="Purchase"
            label={{ position: "top" }}
          />
          <Bar
            dataKey="salesCount"
            fill="#82ca9d"
            name="Sales"
            label={{ position: "top" }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
