import React, { useState } from "react";
import { Eye, EyeOff } from "react-feather";

import "./index.scss";

const InputField = ({
  label,
  type,
  name,
  placeholder = "enter your name",
  value,
  onChange,
  onBlur,
  error,
  errorMessage,
  classes,
  disabled = false,
  max,
  min,
  step = "any",
  readonly = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="form-group mb-4">
      <label className="text-capitalize col-form-label pt-0">{label}</label>
      <div className="input_wrapper">
        <input
          className={`form-control inputclass text-dark ${
            error ? "inputError" : ""
          } ${classes}`}
          type={showPassword ? "text" : type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          max={max}
          min={min}
          step={step}
          readonly={readonly}
          onKeyDown={(e) => {
            if (
              e.target.value >= max &&
              e.key !== "Backspace" &&
              e.key !== "Delete"
            ) {
              e.preventDefault();
            }
          }}
        />
        {type === "password" && (
          <span
            className={`password-toggle ${showPassword ? "visible" : ""}`}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <EyeOff color="#4466F1" size={16} />
            ) : (
              <Eye color="#4466F1" size={16} />
            )}
          </span>
        )}
      </div>

      <span className="error-message"> {errorMessage ? errorMessage : ""}</span>
    </div>
  );
};

export default InputField;
