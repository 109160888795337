import React, { Fragment, useEffect, useState } from "react";
import { Pagination } from "../../components/common/pagination";
import Search from "../../components/common/search/search";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";
import Filterform from "./Filters";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import { formatNumberandComma } from "../../services";

const ReturnOrderListing = ({
  type,
  returnsList,
  setSearch,
  search,
  paginationData,
  setPaginationData,
  isLoading,
  filters,
  setFilters,
  setApply,
  onChange,
}) => {
  return (
    <>
      <div className="card-body">
        <div className="mb-3 d-flex justify-content-end">
          <Search
            classes="form-control search-input"
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
              setPaginationData((pre) => ({
                ...pre,
                currentPage: 1,
              }));
            }}
            value={search}
          />
        </div>

        <Filterform
          type={type}
          filters={filters}
          setFilters={setFilters}
          setApply={setApply}
          onChange={onChange}
        />

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Order Id</th>
                <th scope="col">Order Amount</th>
                <th scope="col">Returned Amount</th>
                <th scope="col">Payment Return</th>
                <th scope="col">Payment Method</th>
                <th scope="col">Return Date</th>
                <PermissionBase
                  permissionName={["read_return_orders_by_customer"]}
                >
                  <th scope="col">Action</th>
                </PermissionBase>
              </tr>
            </thead>
            <tbody>
              {returnsList?.length
                ? returnsList?.map((order, ind) => (
                    <tr key={ind}>
                      <td>{order?.order_id}</td>
                      <td>{formatNumberandComma(order?.total_order_amount)}</td>
                      <td>{formatNumberandComma(order?.return_amount)}</td>
                      <td>{order?.return_adjust}</td>
                      <td>
                        {order?.transaction_type === "Adjust in Wallet"
                          ? "-"
                          : order?.transaction_type}
                      </td>
                      <td>
                        {new Date(order?.created_at).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                      </td>
                      <PermissionBase
                        permissionName={["read_return_orders_by_customer"]}
                      >
                        <td>
                          <Link
                            className={"border-0 bg-transparent"}
                            to={`/return-order/${type}/${order?.id}`}
                          >
                            <Eye size={18} color="#4466F2" />
                          </Link>
                        </td>
                      </PermissionBase>
                    </tr>
                  ))
                : null}
              {!isLoading && !returnsList.length ? "no data found" : null}
            </tbody>
          </table>
          <div className="mt-4 mb-1">
            <Pagination
              selectedPage={paginationData.currentPage}
              pageCount={paginationData.totalPages}
              onPageChangeHandler={(page) => {
                setPaginationData({
                  ...paginationData,
                  currentPage: page,
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReturnOrderListing;
