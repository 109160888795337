import React, { Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./routes";
import httpRequest from "./axios";
import { CONFIGRATIONS } from "./constant/apiEndPoints";
import { useDispatch } from "react-redux";
import { setConfigurations } from "./store/slices/configurations";
import {
  findConfigurationByKeyPublic,
  getAllPublicConfigurations,
} from "./services";

const App = () => {
  const dispatch = useDispatch();
  const [shortName, setShortName] = useState("");
  const [logo, setLogo] = useState("");
  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: { data },
        } = await getAllPublicConfigurations();

        let ConfigObject = data.reduce(
          (acc, item) => ({ ...acc, [item.key]: item.value }),
          {}
        );

        dispatch(
          setConfigurations({
            companyShortName: ConfigObject["COMPANY_SHORT_NAME"] || "",
            mainLogo: ConfigObject["APP_MAIN_PAGE_LOGO"] || "",
            companyName: ConfigObject["COMPANY_NAME"] || "",
            appLogo: ConfigObject["APP_LOGO"] || "",
          })
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="App">
      <Suspense fallback={<div>Loading</div>}>
        <AppRoutes />
      </Suspense>
    </div>
  );
};

export default App;
