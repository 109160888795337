import React, { useState, Fragment, useEffect } from "react";
import UserMenu from "./userMenu";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./index.scss";
import Carosels from "../carousels";
import { hasPermissions } from "../../../helpers/permissions.helper";
import { useSelector } from "react-redux";

const Header = () => {
  const [itemNav, setitemNav] = useState(0);
  const [openMobileMenu, setOpenMobileMenu] = useState(true);
  const history = useHistory();
  const { permissions = [] } = useSelector((state) => state?.login);

  const navItems = [
    {
      id: 0,
      item: "Dashboard",
      link: "/",
      givenPermission: [
        "create_user",
        "read_user",
        "update_user",
        "delete_user",
      ],
    },
    {
      id: 1,
      item: "Users",
      link: "/users",
      givenPermission: [
        "create_user",
        "read_user",
        "update_user",
        "delete_user",
      ],
    },
    {
      id: 2,
      item: "Products",
      link: "/products",
      givenPermission: [
        "create_products",
        "read_products",
        "update_products",
        "delete_products",
      ],
    },
    {
      id: 3,
      item: "Customers",
      link: "/profiles/customer",
      givenPermission: [
        "create_profile",
        "read_profile",
        "update_profile",
        "delete_profile",
      ],
    },
    {
      id: 4,
      item: "Suppliers",
      link: "/profiles/supplier",
      givenPermission: [
        "create_profile",
        "read_profile",
        "update_profile",
        "delete_profile",
      ],
    },
    {
      id: 5,
      item: "Customer Orders",
      link: "/orders/customer",
      givenPermission: [
        "create_customer_orders",
        "read_customer_orders",
        "update_customer_orders",
        "delete_customer_orders",
      ],
    },
    {
      id: 6,
      item: "Supplier Orders",
      link: "/orders/supplier",
      givenPermission: [
        "create_supplier_orders",
        "read_supplier_orders",
        "update_supplier_orders",
        "delete_supplier_orders",
      ],
    },
  ];

  // const newArray = navItems.map((obj) => Object.values(obj))
  const arrayItems = [
    "Users",
    "Products",
    "Customers",
    "Suppliers",
    "Customer Orders",
    "Supplier Orders",
  ];

  const handleMobileMenu = () => {
    setOpenMobileMenu(!openMobileMenu);
  };
  const handleNavigate = (item) => {
    history.push(item);
    setOpenMobileMenu(!openMobileMenu);
  };
  const location = useLocation();
  let side = localStorage.getItem("sidebar") === "true";
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const storedSidebarState = localStorage.getItem("sidebar") === "true";

    setIsSidebarOpen(window.innerWidth <= 776 && storedSidebarState);

    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth <= 776 && storedSidebarState);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`page-main-header ${side ? "open" : ""}`}
      style={{ height: "70px" }}
    >
      <div className="main-header-right row lg-px-0">
        <div className="mobile-sidebar col-12 d-flex align-items-center justify-content-between ms-3 px-4">
          <div
            className="flex justify-content-center"
            style={{ maxWidth: "fit-content" }}
          >
            <div className="d-block d-xl-none carousle-container-width">
              <Carosels items={arrayItems} />
            </div>
            <ul className="d-none d-xl-flex align-items-center">
              {navItems
                .filter((item) =>
                  hasPermissions(permissions, item.givenPermission)
                )
                .map((items, index) => {
                  return (
                    <li
                      onClick={() => setitemNav(index + 1)}
                      className="nav-linkss"
                      style={{ letterSpacing: "0.5px", fontSize: "15px" }}
                    >
                      <Link
                        className={`nav-item nav-item-link mx-1 ${
                          location.pathname === items.link
                            ? "active-navClass"
                            : ""
                        } text-primary`}
                        to={items.link}
                      >
                        {items.item}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div>
            <UserMenu />
          </div>
        </div>
        <script id="result-template" type="text/x-handlebars-template">
          <div className="ProfileCard u-cf">
            <div className="ProfileCard-avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-airplay m-0"
              >
                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                <polygon points="12 15 17 21 7 21 12 15"></polygon>
              </svg>
            </div>
            <div className="ProfileCard-details">
              <div className="ProfileCard-realName"></div>
            </div>
          </div>
        </script>
        <script id="empty-template" type="text/x-handlebars-template">
          <div className="EmptyMessage">
            {
              "Your search turned up 0 results. This most likely means the backend is down, yikes!"
            }
          </div>
        </script>
      </div>
    </div>
  );
};
export default Header;
