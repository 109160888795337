import React from "react";

import Select from "react-select";

import "../input/index.scss";
import "./index.scss";

const SearchSelectField = ({
  className,
  value,
  name,
  onChange,
  options = [],
  valueKey = "id",
  labelKey = "name",
  errorMessage = "",
  disabled,
  isSearchable = true,
}) => {
  const getLabel = (option) => option[labelKey];
  const getValue = (option) => option[valueKey];

  const formattedOptions = options?.map((option) => ({
    ...option,
    label: getLabel(option),
    value: getValue(option),
  }));

  const getValueHandler =
    formattedOptions.find((item) => item?.value == value) || {};

  const customFilter = (option, rawInput) => {
    const input = rawInput?.toLowerCase().trim();
    const words = input?.split(/\s+/);

    return words.every((word) => option?.label?.toLowerCase()?.includes(word));
  };

  return (
    <>
      <Select
        options={formattedOptions}
        filterOption={customFilter}
        className={`py-0 ${className} ${errorMessage ? "inputError" : ""}`}
        name={name}
        value={getValueHandler}
        onChange={onChange}
        isDisabled={disabled}
        styles={{ zIndex: 99, fontSize: "12px", backgroundColor: "red" }}
        isSearchable={isSearchable}
      />
      {errorMessage && <span className="error-message">{errorMessage}</span>}
    </>
  );
};

export default SearchSelectField;
