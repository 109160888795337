import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { Info } from 'react-feather';

function TooltipItem(props) {
  const { item, id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <Button className="p-0 m-0" color="" id={'Tooltip-' + id}>
        <Info size={16}/>
      </Button>
      <Tooltip
        placement={item.placement}
        isOpen={tooltipOpen}
        target={'Tooltip-' + id}
        toggle={toggle}
        style={{ backgroundColor: '#2f3c4e' }}
      >
        {props.tooltipText}
      </Tooltip>
    </span>
  );
}

TooltipItem.propTypes = {
  item: PropTypes.object,
  id: PropTypes.string,
};

function TooltipCustom({tooltipText}) {
  return (
    <>
      {[
        {
          placement: 'top',
          text: 'Tooltip on Top',
        },
      ].map((tooltip, i) => {
        // eslint-disable-next-line react/no-array-index-key
        return <TooltipItem key={i} item={tooltip} id={i} tooltipText={tooltipText}/>;
      })}
    </>
  );
}

export default TooltipCustom;