import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const HelmetComponent = ({ titleText, description, keywords }) => {
  const { configurations } = useSelector((state) => state.configurations);
  const [name, setName] = useState("");

  useEffect(() => {
    let getname = configurations?.companyShortName;
    setName(getname);
  }, [configurations]);
  return (
    <Helmet defer={false}>
      <meta charSet="utf-8" />
      <title>{`${name ? name : ""} | ${titleText}`}</title>
      {/* <meta name="description" content={description} />
      <meta name="keywords" content={keywords} /> */}
    </Helmet>
  );
};

export default HelmetComponent;
