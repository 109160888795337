import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  getAllAttribute,
  getAllAttributeKeys,
  getAttributeValuesByKey,
} from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "./../../components/common/customLoader/customLoader";
import AttributeForm from "./form";
import queryString from "query-string";
import { Edit } from "react-feather";
import { PER_PAGE_API } from "../../constant/pagination";
import { Pagination } from "../../components/common/pagination";
import "./index.scss";
import AttributesFilters from "./AttributesFilters";
import Search from "../../components/common/search/search";
import PermissionBase from "../../components/common/permissionBase/permissionBase";

const AllAttributeListing = () => {
  const [attributeList, setAttributeList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const [transition, setTransition] = useState(false);
  const formRef = useRef(null);
  const [keysList, setKeysList] = useState([]);
  const [editId, setEditid] = useState("");
  const [formType, setFormType] = useState("add");
  const [hideForm, setHideForm] = useState(null);
  const [apply, setApply] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [initialValues, setIntialValues] = useState({
    attr_type: "",
    attr_value: "",
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });
  const [filters, setFilters] = useState({
    attribute_key: "",
    attribute_value: "",
  });
  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  useEffect(() => {
    setHideForm(id);
    if (hideForm) {
      setTransition(false);
    }
  }, [id]);

  const getUsersListing = async () => {
    try {
      setLoading(true);
      const { data } = await getAllAttribute(
        paginationData.perPage,
        paginationData.currentPage,
        searchQuery,
        filters
      );
      if (data.status === "Success") {
        setAttributeList(data?.data?.data || []);
      }
      setLoading(false);
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };
  const getKeysList = async () => {
    try {
      setLoading(true);
      const { data } = await getAllAttributeKeys();
      if (data.status === "Success") {
        setKeysList(data?.data?.data);
      }
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    setAttributeList([]);
    getUsersListing();
    getKeysList();
  }, [paginationData.currentPage, apply, searchQuery]);

  const formSuccessHandler = () => {
    getUsersListing();
    setTransition(false);
  };

  return (
    <Fragment>
      <Breadcrumb parent="Attribute" title="listing" />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="container-fluid position-relative">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={`card mt-2 section ${
                    transition ? "show-all-attributes" : "hide"
                  }`}
                  ref={formRef}
                >
                  <AttributeForm
                    type={formType}
                    initialValues={initialValues}
                    formSuccessHandler={formSuccessHandler}
                    editId={editId}
                    setTransition={setTransition}
                    formRef={formRef}
                    keysList={keysList}
                  />
                </div>
              </div>
            </div>
            <div className={`row`}>
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header attributes-header">
                    <h5>All Attribute</h5>
                    <PermissionBase
                      permissionName={["create_attribute_values"]}
                    >
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          setFormType("add");
                          setTransition(true);
                          setIntialValues({
                            attr_type: "",
                            attr_value: "",
                          });
                        }}
                      >
                        New Attribute Value
                      </button>
                    </PermissionBase>
                  </div>
                  <div className="card-body">
                    <div className="mb-3 d-flex justify-content-end">
                      <Search
                        classes="form-control search-input"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                          setPaginationData((pre) => ({
                            ...pre,
                            currentPage: 1,
                          }));
                        }}
                        value={searchQuery}
                      />
                    </div>

                    <div>
                      <AttributesFilters
                        filters={filters}
                        setFilters={setFilters}
                        setApply={setApply}
                        onChange={onChange}
                        keysList={keysList}
                      />
                    </div>
                  </div>
                  {attributeList?.length ? (
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">{"Attribute Key"}</th>
                              <th scope="col">{"Attribute Value"}</th>
                              <PermissionBase
                                permissionName={["update_attribute_values"]}
                              >
                                <th scope="col">{"Actions"}</th>
                              </PermissionBase>
                            </tr>
                          </thead>
                          <tbody>
                            {attributeList.map((attri, ind) => (
                              <tr key={ind}>
                                <td>{attri?.attribute_key?.title}</td>
                                <td>{attri.value}</td>
                                <PermissionBase
                                  permissionName={["update_attribute_values"]}
                                >
                                  <td>
                                    <span
                                      onClick={() => {
                                        setEditid(attri?.id);
                                        setIntialValues({
                                          ...initialValues,
                                          attr_type: attri?.attribute_key?.id,
                                          attr_value: attri.value,
                                        });
                                        setFormType("edit");
                                        setTransition(true);
                                        window.scrollTo({
                                          top: formRef.current.offsetTop,
                                          behavior: "smooth",
                                        });
                                      }}
                                      className="pointer-cursor"
                                    >
                                      <Edit size={20} color="green" />
                                    </span>
                                  </td>
                                </PermissionBase>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="mt-4 mb-1">
                          <Pagination
                            selectedPage={paginationData.currentPage}
                            pageCount={paginationData.totalPages}
                            onPageChangeHandler={(page) => {
                              setPaginationData({
                                ...paginationData,
                                currentPage: page,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>not found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default AllAttributeListing;
