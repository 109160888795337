import React, { Fragment, useState, useEffect, useRef } from "react";
import UserPanel from "./userPanel";
// import { MENUITEMS } from "../../../components/common/sidebar-component/menu";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import configDB from "../../../customizer/config";
import useMenus from "../../../hooks/menuHook";
import { AlignLeft } from "react-feather";

const Sidebar = (props) => {
  const [sidebar, setSidebar] = useState(
    JSON.parse(localStorage.getItem("sidebar")) || false
  );
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [MENUITEMS] = useMenus();
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [mainmenu, setMainMenu] = useState({ mainmenu: MENUITEMS });
  const wrapper = configDB.data.settings.sidebar.wrapper;
  const layout = "ltr";
  const { t } = useTranslation();
  const sideBarRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    var currentUrl = window.location.pathname;

    MENUITEMS?.filter((items) => {
      if (items.path === currentUrl) setNavActive(items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) setNavActive(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) {
            setNavActive(subSubItems);
            return true;
          } else {
            return false;
          }
        });
        return subItems;
      });
      return items;
    });

    const timeout = setTimeout(() => {
      const elmnt = document.getElementById("myDIV");
      const menuWidth = elmnt?.offsetWidth;
      if (menuWidth > window.innerWidth) {
        setHideRightArrow(false);
        setHideLeftArrowRTL(false);
      } else {
        setHideRightArrow(true);
        setHideLeftArrowRTL(true);
      }
    }, 500);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  }, []);

  function handleResize() {
    setWidth(window.innerWidth - 310);
  }

  const setNavActive = (item) => {
    MENUITEMS?.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
            return true;
          } else {
            return false;
          }
        });
      }
      return menuItem;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };
  // Click Toggle menu
  const toggletNavActive = (item) => {
    if (!item.active) {
      MENUITEMS?.forEach((a) => {
        if (MENUITEMS?.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
          if (!b.children) return false;
          b.children.forEach((c) => {
            if (b.children.includes(item)) {
              c.active = false;
            }
          });
        });
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  useEffect(() => {
    setMainMenu({ mainmenu: MENUITEMS });
  }, [MENUITEMS]);

  const scrollToRight = () => {
    const elmnt = document.getElementById("myDIV");
    const menuWidth = elmnt?.offsetWidth;
    const temp = menuWidth + margin;
    if (temp < menuWidth) {
      setMargin(-temp);
      setHideRightArrow(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideLeftArrow(false);
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
      setHideLeftArrow(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrow(false);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin((margin) => (margin += -width));
      setHideLeftArrowRTL(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };

  let side = localStorage.getItem("sidebar") === "true";

  const openCloseSidebar = () => {
    const newSidebarState = !sidebar;
    setSidebar(newSidebarState);
    localStorage.setItem("sidebar", JSON.stringify(newSidebarState));

    if (window.innerWidth <= 776) {
      setSidebar(!sidebar);
      localStorage.setItem("sidebar", !sidebar);
      document.querySelector(".page-main-header").classList.toggle("open");
      document.querySelector(".page-sidebar").classList.toggle("open");
    } else {
      if (!sidebar) {
        setSidebar(!sidebar);
        localStorage.setItem("sidebar", !sidebar);
        document.querySelector(".page-main-header").classList.remove("open");
        document.querySelector(".page-sidebar").classList.remove("open");
      } else {
        setSidebar(!sidebar);
        localStorage.setItem("sidebar", !sidebar);
        document.querySelector(".page-main-header").classList.add("open");
        document.querySelector(".page-sidebar").classList.add("open");
      }
    }
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth <= 776);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      window.innerWidth <= 778 &&
      sideBarRef.current &&
      !sideBarRef.current.contains(event.target)
    ) {
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <Fragment>
      <div className="flex-grow-1 text-end switch-sm hamburger-class">
        <label className="switch">
          <Link to="#" onClick={() => openCloseSidebar()}>
            <AlignLeft />
          </Link>
        </label>
      </div>
      <div className={`page-sidebar ${side ? "open" : ""}`} ref={sideBarRef}>
        <div className="sidebar" style={{ overflowY: "hidden" }}>
          <Link to="/" className="sidebar-top-part py-0 border-bottom">
            <UserPanel />
          </Link>
          <ul
            className="custom-scrollbar-lower-sidebar sidebar-menu py-0"
            id="myDIV"
            style={
              wrapper === "horizontal_sidebar"
                ? layout === "rtl"
                  ? { marginRight: margin + "px" }
                  : { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <li
              className={`left-arrow ${
                layout === "rtl"
                  ? hideLeftArrowRTL
                    ? "d-none"
                    : ""
                  : hideLeftArrow
                  ? "d-none"
                  : ""
              }`}
              onClick={
                wrapper === "horizontal_sidebar" && layout === "rtl"
                  ? scrollToLeftRTL
                  : scrollToLeft
              }
            >
              <i className="fa fa-angle-left"></i>
            </li>
            {mainmenu?.mainmenu?.map((menuItem, i) => (
              <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                {menuItem.sidebartitle ? (
                  <div className="sidebar-title">{menuItem.sidebartitle}</div>
                ) : (
                  ""
                )}
                {menuItem.type === "sub" ? (
                  <Link
                    to="#"
                    className="sidebar-header"
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    <menuItem.icon />
                    <span>{t(menuItem.title)}</span>
                    <i className="fa fa-angle-right pull-right"></i>
                  </Link>
                ) : (
                  ""
                )}
                {menuItem.type === "link" ? (
                  <Link
                    to={`${menuItem.path}`}
                    className={`sidebar-header ${
                      menuItem.active ? "active" : ""
                    }`}
                    onClick={() => toggletNavActive(menuItem)}
                  >
                    <menuItem.icon />
                    <span>{t(menuItem.title)}</span>
                    {menuItem.children ? (
                      <i className="fa fa-angle-right pull-right"></i>
                    ) : (
                      ""
                    )}
                  </Link>
                ) : (
                  ""
                )}
                {menuItem.children ? (
                  <ul
                    className={`sidebar-submenu ${
                      menuItem.active ? "menu-open" : ""
                    }`}
                    style={
                      menuItem.active
                        ? { opacity: 1, transition: "opacity 500ms ease-in" }
                        : {}
                    }
                  >
                    {menuItem.children.map((childrenItem, index) => (
                      <li
                        key={index}
                        className={
                          childrenItem.children
                            ? childrenItem.active
                              ? "active"
                              : ""
                            : ""
                        }
                      >
                        {childrenItem.type === "sub" ? (
                          <Link
                            to="#"
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            <i className="fa fa-circle"></i>
                            {t(childrenItem.title)}{" "}
                            <i className="fa fa-angle-right pull-right"></i>
                          </Link>
                        ) : (
                          ""
                        )}

                        {childrenItem.type === "link" ? (
                          <Link
                            to={`${childrenItem.path}`}
                            className={childrenItem.active ? "active" : ""}
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            <i className="fa fa-circle"></i>
                            {t(childrenItem.title)}{" "}
                          </Link>
                        ) : (
                          ""
                        )}
                        {childrenItem.children ? (
                          <ul
                            className={`sidebar-submenu ${
                              childrenItem.active ? "menu-open" : "active"
                            }`}
                          >
                            {childrenItem.children.map(
                              (childrenSubItem, key) => (
                                <li
                                  className={
                                    childrenSubItem.active ? "active" : ""
                                  }
                                  key={key}
                                >
                                  {childrenSubItem.type === "link" ? (
                                    <Link
                                      to={`${childrenSubItem.path}`}
                                      className={
                                        childrenSubItem.active ? "active" : ""
                                      }
                                      onClick={() =>
                                        toggletNavActive(childrenSubItem)
                                      }
                                    >
                                      <i className="fa fa-circle"></i>
                                      {t(childrenSubItem.title)}
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </li>
            ))}
            <li
              className={`right-arrow ${
                layout === "rtl"
                  ? hideRightArrowRTL
                    ? "d-none"
                    : ""
                  : hideRightArrow
                  ? "d-none"
                  : ""
              }`}
              onClick={
                wrapper === "horizontal_sidebar" && layout === "rtl"
                  ? scrollToRightRTL
                  : scrollToRight
              }
            >
              <i className="fa fa-angle-right"></i>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
