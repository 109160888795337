import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

const productsReducer = createSlice({
  name: "products",
  initialState: initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setProductsEmpty: (state) => {
      state.products = [];
    },
  },
});

export const { products, setProducts, setProductsEmpty } =
  productsReducer.actions;

export default productsReducer.reducer;
