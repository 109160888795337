import React, { Fragment, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import httpRequest from "../../../axios";
import ReturnOrderForm from "./returnOrderForm";
import { PAGE_TITLES } from "../../../constant/pageTitles";
import Breadcrumb from "../../../components/common/breadcrumb";
import HelmetComponent from "../../../components/common/Helmet";

const ReturnOrder = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order = searchParams.get("order");
  const { type } = useParams();
  const [preSelctedProducts, setPreSelectedProducts] = useState([]);

  const [initialValues, setIntialValues] = useState({
    products: [
      // {
      //   name: "",
      //   price: 0,
      //   units: [
      //     {
      //       id: "",
      //       value: "",
      //       name: "",
      //     },
      //   ],
      //   discount: 0,
      //   productcode: "",
      //   productGst: "",
      //   totalPrice: "",
      //   baseUnit: "",
      // },
    ],
    supplier_id: "",
    payment_method: "",
    paid_amount: "",
    return_adjust: "cashback",
  });

  const { id } = useParams();
  var allProductId = [];

  const getOrderValues = async (id) => {
    try {
      const { data } = await httpRequest.get(
        type === "customer" ? "customerOrders/" + id : "orders/" + id
      );
      if (data.status === "Success") {
        let order = data?.data || null;
        if (order && order?.data?.length) {
          let products = order?.data?.map((item) => {
            let units;
            if (item.requested_stock.length) {
              units = item?.requested_stock?.map((stocks) => ({
                id: stocks?.unit_id,
                value: stocks?.stock || 0,
                name: stocks?.unit_name,
                isBaseUnit: stocks?.is_base_unit,
                originalValue: stocks?.originalValue,
                unitSymbol: stocks?.symbol,
              }));
            }
            allProductId.push(parseInt(item.product_id));
            return {
              id: item?.product_id,
              label: item?.product_name,
              name: item.product_id,
              price: item.product_cost,
              units: units,
              discount: item?.discount,
              productcode: item?.product_id,
              productGst: item?.gst,
              totalPrice: parseFloat(item?.amount_without_gst),
              sellingUnitName: item?.selling_unit_title,
              sellingUnitId: item?.selling_unit_id,
              stockWithSellingUnit: item?.stock_with_selling_unit,
            };
          });
          setPreSelectedProducts(allProductId);
          delete order.data;
          delete initialValues.supplier_id;
          setIntialValues({
            ...initialValues,
            // ...order,
            id: order?.id,
            description: order?.description,
            paid_amount: order?.paid_amount,
            payment_method: order?.payment_method,
            profile_id: order?.profile_id,
            total_order_amount: order?.total_order_amount,
            total_payable_amount: order?.total_payable_amount,
            products: products,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderValues(id);
  }, [id]);

  return (
    <Fragment>
      <HelmetComponent
        titleText={
          type === "customer"
            ? PAGE_TITLES["CustomerOrders"]
            : PAGE_TITLES["SupplierOrders"]
        }
      />
      <Breadcrumb title="New Order" parent="Orders" parentlink="Orders" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="text-capitalize">Return Order</h5>
              </div>
              <ReturnOrderForm
                initialValues={initialValues}
                id={id}
                type="return"
                preSelctedProducts={preSelctedProducts}
                orderType={type}
                order={order}
                user={type}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ReturnOrder;
