import * as Yup from "yup";

const UnitSchema = Yup.object().shape({
  id: Yup.number().required("Key is required"),
});
const ProductSchema = Yup.object().shape({
  name: Yup.string().required("Product is required"),
  price: Yup.number().required("Price is required"),
  units: Yup.array()
    .of(UnitSchema)
    .required("At least one product is required"),
});

export const ProductsSchema = (isReceived, user, payable) => {
  let schema = Yup.object().shape({
    products: Yup.array().of(ProductSchema).required("Product is requires"),
    profile_id: Yup.string().required(
      `${user === "customer" ? "Customer" : "Seller"} is required`
    ),
    payment_method: Yup.string().required("Payment Method is required"),
    // paid_amount: Yup.number().when("is_paid", {
    //   is: (v) => v === "NO",
    //   then: (schema) =>
    // .required("Paid Amount is required")
    // .positive("Amount must be greater than 0")
    //     schema.max(payable, `Paid Amount cannot exceed payabel`),
    // }),
    is_paid: Yup.string().required("Paid is required"),
    is_received: Yup.string().required("Received is required"),

    scheduled_delivery_date: Yup.string().when("is_received", {
      is: (v) => v === "NO",
      then: (schema) => schema.required("Scheduled delivery date is required"),
    }),
    description: Yup.string().max(
      500,
      "Please enter a description with a maximum of 500 characters"
    ),
    tag: Yup.string().max(
      20,
      "Please enter a tag with a maximum of 20 characters"
    ),
  });
  return schema;
};
