import React from 'react'
import "./index.css"

const CustomLoader = () => {
  return (
    <div class="loader">Loading...</div>
    // <div className='d-flex justify-content-center'>
    //     <div class="hm-spinner"></div>
    // </div>
    // <div class="loader"></div>
//     <div class="mesh-loader">
//     <div class="set-one">
//         <div class="circle"></div>
//         <div class="circle"></div>
//     </div>
//     <div class="set-two">
//         <div class="circle"></div>
//         <div class="circle"></div>
//     </div>
// </div> 

// <div class="circle-loader">
//     <div></div>
//     <div></div>
//     <div></div>
//     <div></div>
//     <div></div>
//     <div></div>
//     <div></div>
//     <div></div>
// </div>    
    
    

  )
}

export default CustomLoader