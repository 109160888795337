import * as Yup from "yup";

export const PaymentValidation = (payable) => {
  let schema = Yup.object().shape({
    amount: Yup.number()
      .positive("Amount must be greater than 0")
      .max(payable, `Amount cannot exceed payable`)
      .required("Amount is required"),
    deposit_date: Yup.string().required("Date is required"),
    payment_method: Yup.string().required("Payment Method is required"),
    // cheque_number: Yup.string().required("Cheque Number is required"),
    // cheque_number: Yup.string().when("payment_method", {
    //   is: (v) => v !== "CASH",
    //   then: (schema) => schema.required("Cheque Number is required"),
    // }),
  });

  return schema;
};

export const MakePaymentValidation = (payable) => {
  let schema = Yup.object().shape({
    amount: Yup.number()
      .positive("Amount must be greater than 0")
      .required("Amount is required"),
    deposit_date: Yup.string().required("Date is required"),
    payment_type: Yup.string().required("Payment Type required"),
    payment_method: Yup.string().required("Payment Method is required"),
    // cheque_number: Yup.string().required("Cheque Number is required"),
    // cheque_number: Yup.string().when("payment_method", {
    //   is: (v) => v !== "CASH",
    //   then: (schema) => schema.required("Cheque Number is required"),
    // }),
  });

  return schema;
};
