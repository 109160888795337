import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import man from "../../../assets/images/faq/2.jpg";
import { User, LogOut, Settings } from "react-feather";
import { Link } from "react-router-dom";
import { setLoginStateEmpty } from "../../../store/slices/login";
import { Edit } from "react-feather";
import { useSelector } from "react-redux";
import { setConfigurationsEmpty } from "../../../store/slices/configurations";
import PermissionBase from "../permissionBase/permissionBase";
import "./index.scss";
import {
  setProfilesEmpty,
  setSupplierProfiles,
} from "../../../store/slices/profiles";
import { setProductsEmpty } from "../../../store/slices/products";

const UserMenu = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const {
    user: { name },
  } = useSelector((state) => state.login);
  const logout = () => {
    dispatch(setLoginStateEmpty());
    dispatch(setProfilesEmpty());
    dispatch(setProductsEmpty());
  };

  const handleEditProfile = () => {
    setShowDropdown(false);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  return (
    <li className="position-relative relative">
      <div
        onClick={() => setShowDropdown(!showDropdown)}
        className="d-flex gap-2 align-items-center text-secondary cursor-pointer userbrand"
        style={{ cursor: "pointer" }}
      >
        <div>
          <span className="" style={{ fontFamily: "revert-layer" }}>
            {name ? name : ""}
          </span>
        </div>
        <div>
          <User className="user-profile-image" size={20} />
        </div>
      </div>
      <ul
        className={`profile-dropdown profile-dropdown-hover ${
          showDropdown ? "show-dropdown-user" : "d-none"
        }`}
        ref={dropdownRef}
        style={{
          top: "100%",
          width: "170px",
        }}
      >
        {/* "update_own_profile" */}

        <PermissionBase permissionName={["update_own_profile_password"]}>
          <li className="user-list-hover p-2 w-100">
            <Link
              to="/change-password"
              className="d-flex align-items-center gap-2"
              onClick={handleEditProfile}
            >
              <Settings className="p-0 m-0" color="#212529" size={15} />
              <span style={{ color: "#212529", fontSize: "15px" }}>
                Change Password
              </span>
            </Link>
          </li>
        </PermissionBase>
        <PermissionBase permissionName={["update_own_profile"]}>
          <li className="user-list-hover p-2 w-100">
            <Link
              to="/user-profile/edit"
              className="d-flex align-items-center gap-2"
              onClick={handleEditProfile}
            >
              <User className="p-0 m-0" color="#212529" size={15} />
              <span style={{ color: "#212529", fontSize: "15px" }}>
                Edit Profile
              </span>
            </Link>
          </li>
        </PermissionBase>
        <br />
        <li onClick={logout} className="user-list-hover p-2 w-100">
          <div className="d-flex align-items-center gap-2 ">
            <LogOut className="p-0 m-0" color="#212529" size={15} />
            <span style={{ color: "#212529", fontSize: "15px" }}>Log out</span>
          </div>
        </li>
      </ul>
    </li>
  );
};

export default UserMenu;
