import React from "react";
import FilterAccordian from "../../components/common/Filter/filter";
import InputField from "../../components/form/input/input";
import SearchSelectField from "../../components/form/select/SearchSelect";
import ButtonField from "../../components/form/button";

const ReportFilter = ({ filters, setFilters, setApply, onChange, type }) => {
  const handleReset = () => {
    setFilters({
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date().toISOString().split("T")[0],
      opotion_mode: "SALE",
      product_breakdown: "NO",
    });
  };
  return (
    <FilterAccordian>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setApply((pre) => !pre);
        }}
      >
        <div className="border-top p-3">
          <div className="row">
            <div className="col-2">
              <InputField
                label="Start Date"
                placeholder={"Start Date"}
                name="start_date"
                value={filters.start_date}
                onChange={(e) => onChange(e.target.name, e.target.value)}
                type={"date"}
              />
            </div>
            <div className="col-2">
              <InputField
                label="End Date"
                placeholder={"End Date"}
                name="end_date"
                value={filters.end_date}
                onChange={(e) => onChange(e.target.name, e.target.value)}
                type={"date"}
              />
            </div>

            {type !== "summry" && (
              <>
                <div className="col-2">
                  <label className="text-capitalize">Sale/Purchase</label>
                  <SearchSelectField
                    className={"inputclass rounded flex-grow-1"}
                    name="opotion_mode"
                    value={filters.opotion_mode}
                    valueKey="value"
                    labelKey="label"
                    onChange={(e) => onChange("opotion_mode", e.value)}
                    options={[
                      { label: "Sale", value: "SALE" },
                      { label: "Purchase", value: "PURCHASE" },
                    ]}
                  />
                </div>

                <div className="col-2">
                  <label className="text-capitalize">Products Breakdown</label>
                  <SearchSelectField
                    className={"inputclass rounded flex-grow-1"}
                    name="product_breakdown"
                    value={filters.product_breakdown}
                    valueKey="value"
                    labelKey="label"
                    onChange={(e) => onChange("product_breakdown", e.value)}
                    options={[
                      { label: "Yes", value: "YES" },
                      { label: "No", value: "NO" },
                    ]}
                  />
                </div>
              </>
            )}

            {/* /////////////////////// */}
          </div>
          <div className="d-flex gap-2 justify-content-end">
            <ButtonField
              buttonText="Reset"
              onClick={handleReset}
              className="btn btn-danger"
              type="button"
            />
            <ButtonField type="Submit" buttonText="Apply" />
          </div>
        </div>
      </form>
    </FilterAccordian>
  );
};

export default ReportFilter;
