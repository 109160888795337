import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PERMISSION_NAMES } from "../constant/routePermissions";
import AppLayout from "../components/app";
import { AuthRoutes } from "./authRoutes";
import { PrivateRoutes } from "./privateRoutes";

import SignIn from "../containers/auth/signin";
import NoAccessPage from "../containers/accessPage";
import AddUser from "../containers/users/addUser";
import UsersListing from "../containers/users/allUsers";
import RolesListing from "../containers/roles&permissions/allRoles";
import AddRole from "../containers/roles&permissions/addRole";
import AttributeListing from "../containers/attributes/attrubuteListing";
import AddAttribute from "../containers/attributes/addAttribute";
import EditUser from "../containers/users/editUser";
import EditRole from "../containers/roles&permissions/editRole";
import AddAttributeKey from "../containers/attributes/keys/addKey";
import EditAttributeKey from "../containers/attributes/keys/editKey";

import UnitsListing from "../containers/units/listing";
import UnitCategoriesListing from "../containers/unitCategories/listing";
import AddUnitCategory from "../containers/unitCategories/add";
import EditUnitCategory from "../containers/unitCategories/edit";
//
import ExpenseListing from "../containers/expenses/listing";
import ExpenseCategoriesListing from "../containers/expenseCategories/listing";
import AddExpenseCategory from "../containers/expenseCategories/add";
import EditExpenseCategory from "../containers/expenseCategories/edit";
//
import Products from "../containers/products/products";
import AddProducts from "../containers/products/add";

// import Addaddon from "../containers/orderaddon/addAddon"
import AddonListing from "../containers/orderaddon/listing";
import EditAddon from "../containers/orderaddon/editAddon";

import TransactionListing from "../containers/transaction/listing";
import InOutStockListing from "../containers/InOutStock/listing";

import AllOrders from "../containers/orders/allOrders";
import AddOrder from "../containers/orders/addOrder";
import EditProduct from "../containers/products/editProduct";
import EditOrder from "../containers/orders/editOrder";
import PaymentHistory from "../containers/Payments";
import ConfigurationsListing from "../containers/configurations/listing";

import PdfViewer from "../components/common/pdfviewer/pdfviewer";

import UserProfile from "../containers/userProfile/userProfile";
import Reports from "../containers/reports/reports";
import ForgetPassword from "../containers/auth/forgetPassword";
import ResetPassword from "../containers/auth/resetPassword";
import SummaryReport from "../containers/reports/summaryReport";
import DetailReport from "../containers/reports/detailReport";
import UpdatePassword from "../containers/auth/updatePassword";

import AllCustomerProfiles from "../containers/customersProfiles/allProfiles";
import AddCustomerProfile from "../containers/customersProfiles/addProfile";
import EditCustomerProfile from "../containers/customersProfiles/editProfile";

import AllSupplierProfiles from "../containers/supplierProfiles/allProfiles";
import AddSupplierProfile from "../containers/supplierProfiles/addProfile";
import EditSupplierProfile from "../containers/supplierProfiles/editProfile";
import Dashboard from "../containers/dashboard";
import ReturnOrder from "../containers/orders/return-order/returnOrder";
import ReturnOrderListing from "../containers/return-order/listings";
import ReturnOrders from "../containers/return-order";
import WalletTransactionListing from "../containers/WalletTransactions/listing";
import ViewReturnOrder from "../containers/return-order/ViewReturnOrder";

const AppRoutes = () => {
  const { isAuthenticated = false, permissions } = useSelector(
    (state) => state.login
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/not-allowed" component={NoAccessPage} />
        <Route path="/order/pdf" component={PdfViewer} />
        <AuthRoutes
          path="/login"
          component={SignIn}
          isAuthenticated={isAuthenticated}
          exact
        />
        <AuthRoutes
          path="/forgot-password"
          component={ForgetPassword}
          isAuthenticated={isAuthenticated}
          exact
        />
        <AuthRoutes
          path="/reset-password"
          component={ResetPassword}
          isAuthenticated={isAuthenticated}
          exact
        />

        <AppLayout>
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={["/dashboard", "/"]}
            component={Dashboard}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_user"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/user/add"}
            component={AddUser}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_user"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/user/edit/:id"}
            component={EditUser}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["update_user"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/users"}
            component={UsersListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_user"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/roles"}
            component={RolesListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_role"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/role/add"}
            component={AddRole}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_role"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/role/edit/:id"}
            component={EditRole}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["update_role"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/attribute/:id"}
            component={AttributeListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_attribute_keys"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/attributes"}
            component={AddAttribute}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_attribute_values"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/attributes/keys"}
            component={AddAttributeKey}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_attribute_keys"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/attributes/key/edit/:id"}
            component={EditAttributeKey}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["update_attribute_keys"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/units"}
            component={UnitsListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_units"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/unit-categories"}
            component={UnitCategoriesListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_unit_categories"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/unit-category/add"}
            component={AddUnitCategory}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_unit_categories"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/unit-category/edit/:id"}
            component={EditUnitCategory}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["update_unit_categories"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/expense"}
            component={ExpenseListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_expense"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/expense-categories"}
            component={ExpenseCategoriesListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_expense_categories"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/expense-category/add"}
            component={AddExpenseCategory}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_expense_categories"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/expense-category/edit/:id"}
            component={EditExpenseCategory}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["update_expense_categories"]}
            exact
          />
          {/* transaction component auth */}
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/transaction"}
            component={TransactionListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_wallet_transactions"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/wallet-transaction"}
            component={WalletTransactionListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_transaction"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/in-out-stock"}
            component={InOutStockListing}
            permissions={permissions}
            givenPermissions={
              PERMISSION_NAMES["read_product_in_out_stock_report"]
            }
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/products"}
            component={Products}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_products"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/products/add"}
            component={AddProducts}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_products"]}
            exact
          />
          {/* //////////////////////////// */}
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/profiles/customer"}
            component={AllCustomerProfiles}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_customer_profile"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/profile/customer/add"}
            component={AddCustomerProfile}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_customer_profile"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/profile/customer/edit/:id"}
            component={EditCustomerProfile}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["update_customer_profile"]}
            exact
          />
          {/* //////////////// */}
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/profiles/supplier"}
            component={AllSupplierProfiles}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_supplier_profile"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/profile/supplier/add"}
            component={AddSupplierProfile}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_customer_profile"]}
            exact
          />
          {/* Order add on    */}
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/orderaddon"}
            component={AddonListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_order_add_on"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/profile/supplier/edit/:id"}
            component={EditSupplierProfile}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["update_customer_profile"]}
            exact
          />
          {/* Orders */}
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/orders/:type"}
            component={AllOrders}
            permissions={permissions}
            givenPermissions={
              PERMISSION_NAMES[("read_customer_orders", "read_supplier_orders")]
            }
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/order/:type/add"}
            component={AddOrder}
            permissions={permissions}
            givenPermissions={
              PERMISSION_NAMES[
                ("create_customer_orders", "create_supplier_orders")
              ]
            }
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/order/:type/edit/:id"}
            component={EditOrder}
            permissions={permissions}
            givenPermissions={
              PERMISSION_NAMES[
                ("update_customer_orders", "update_supplier_orders")
              ]
            }
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/order/:type/return/:id"}
            component={ReturnOrder}
            permissions={permissions}
            givenPermissions={
              PERMISSION_NAMES["create_return_order_by_customer"]
            }
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/return-orders/:type"}
            component={ReturnOrders}
            permissions={permissions}
            givenPermissions={
              PERMISSION_NAMES["create_return_order_by_customer"]
            }
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/return-order/:type/:id"}
            component={ViewReturnOrder}
            permissions={permissions}
            givenPermissions={
              PERMISSION_NAMES["create_return_order_by_customer"]
            }
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/product/edit/:id"}
            component={EditProduct}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["update_products"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/payment-history/:id"}
            component={PaymentHistory}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_transaction"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/configurations"}
            component={ConfigurationsListing}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["read_configuration"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/user-profile/edit"}
            component={UserProfile}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["update_own_profile"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/change-password"}
            component={UpdatePassword}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["update_own_profile_password"]}
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/summary/report"}
            component={SummaryReport}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_product_summary_report"]} // temporary routes pending from backend
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/detail/report"}
            component={DetailReport}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_product_detail_report"]} // temporary routes pending from backend
            exact
          />
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            path={"/product-custom/report"}
            component={DetailReport}
            permissions={permissions}
            givenPermissions={PERMISSION_NAMES["create_product_detail_report"]} // temporary routes pending from backend
            exact
          />
        </AppLayout>
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoutes;
