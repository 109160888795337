import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerProfiles: [],
  supplierProfiles: [],
};

const profilesReducer = createSlice({
  name: "profiles",
  initialState: initialState,
  reducers: {
    setCustomerProfiles: (state, action) => {
      state.customerProfiles = action.payload;
    },
    setSupplierProfiles: (state, action) => {
      state.supplierProfiles = action.payload;
    },
    setProfilesEmpty: (state) => {
      state.customerProfiles = [];
      state.supplierProfiles = [];
    },
  },
});

export const {
  profiles,
  setCustomerProfiles,
  setSupplierProfiles,
  setProfilesEmpty,
} = profilesReducer.actions;

export default profilesReducer.reducer;
