import React, { useState } from "react";
import { ChevronUp, ChevronDown, Filter } from "react-feather";
import { Accordion } from "react-bootstrap";
import { Card, Collapse, Button } from "reactstrap";

const FilterAccordian = ({ children }) => {
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  return (
    <Accordion
      defaultActiveKey="0"
      className="bg-light"
      style={{ color: "#000" }}
    >
      <Button
        as={Card.Header}
        className="w-100 border-0 px-3 pt-2 rounded d-flex justify-content-between align-items-center"
        color="default"
        onClick={() => toggle(1)}
      >
        <div className="d-flex align-items-center gap-2">
          <h5 className="text-capitalize">Filters</h5>
          <div>
            <Filter size={16} />
          </div>
        </div>
        <div>{isOpen ? <ChevronDown /> : <ChevronUp />}</div>
      </Button>
      <Collapse isOpen={isOpen === 1}>{children}</Collapse>
    </Accordion>
  );
};

export default FilterAccordian;
