import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import { getUsersService } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import UserForm from "./form";
import { AddNewUser } from "../../constant";
import { Pagination } from "../../components/common/pagination";
import Search from "../../components/common/search/search";
import { PER_PAGE_API } from "../../constant/pagination";
import { Edit } from "react-feather";
import Dropdowns from "../../components/common/dropdown/dropdown";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonField from "../../components/form/button";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const UsersListing = () => {
  const [usersList, setUsersList] = useState([]);
  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState([]);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });
  const [selectedOption, setSelectedOption] = useState("ALL");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const getUsersListing = async () => {
    try {
      setLoading(true);
      const {
        data: { data = [] },
      } = await getUsersService(
        paginationData.perPage,
        paginationData.currentPage,
        searchQuery
      );
      setUsersList(data?.data);
      setFilteredUsersList(data?.data);
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data.current_page,
        totalRecords: data.total,
        perPage: data?.per_page,
        totalPages: Math.ceil(data.total / data?.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong. Please reload the page or contact the admin.",
      });
    }
  };

  useEffect(() => {
    getUsersListing();
  }, [paginationData.currentPage, searchQuery]);

  useEffect(() => {
    let filteredList;
    if (selectedOption === "ALL") {
      filteredList = usersList;
    } else {
      filteredList = usersList.filter(
        (item) => item.status.toLowerCase() === selectedOption.toLowerCase()
      );
    }
    setFilteredUsersList(filteredList);
  }, [selectedOption, usersList]);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Users"]} />
      <Breadcrumb parent="Users" title="Listing" parentlink="users" />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mt-2">
                <div className="card-header d-flex justify-content-between items-center">
                  <h5 className="text-capitalize">All Users</h5>
                  <PermissionBase permissionName={["create_user"]}>
                    <Link
                      to={"user/add"}
                      className="btn btn-success text-capitalize"
                    >
                      Add new user
                    </Link>
                  </PermissionBase>
                </div>
                <div className="card-body">
                  <div className="mb-3 d-flex justify-content-end">
                    <Search
                      classes="form-control search-input"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setPaginationData((pre) => ({
                          ...pre,
                          currentPage: 1,
                        }));
                      }}
                      value={searchQuery}
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">User Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Role</th>
                          <th scope="col">Status</th>
                          <PermissionBase permissionName={["update_user"]}>
                            <th scope="col">Edit</th>
                          </PermissionBase>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUsersList.length ? (
                          filteredUsersList.map((user, ind) => (
                            <tr key={ind}>
                              <td>{user?.id}</td>
                              <td className="truncate-line-custom">
                                {user?.username}
                              </td>
                              <td>{user?.email}</td>
                              <td>{user?.role?.name}</td>
                              <td>{user?.status}</td>
                              <PermissionBase permissionName={["update_user"]}>
                                <td>
                                  <Link
                                    to={`/user/edit/${user.id}`}
                                    className=""
                                  >
                                    <Edit size={20} color="green" />
                                  </Link>
                                </td>
                              </PermissionBase>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">No data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UsersListing;
