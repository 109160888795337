import * as Yup from "yup";

export const AttributesValidation = Yup.object().shape({
  attr_type: Yup.string().required("Attribute Type is required"),
  attr_value: Yup.string().required("Attribute Value is required"),
});

export const AttributesKeyValidation = Yup.object().shape({
  attr_type: Yup.string().required("Attribute key is required"),
});
