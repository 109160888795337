import { useState } from "react";
import { Form, Formik } from "formik";
import ButtonField from "../../../components/form/button";
import InputField from "../../../components/form/input/input";
import httpRequest from "../../../axios";
import { useSelector, useDispatch } from "react-redux";
import show_Toast from "../../../helpers/toast.helper";

import { useHistory } from "react-router-dom";
import {
  ATTRIBUTE_KEYS,
  CREATE_ATTRIBUTE_KEYS,
} from "../../../constant/apiEndPoints";
import { AttributesKeyValidation } from "../../../utils/validations/attributes.validations";
import { setAttributeMenus } from "../../../store/slices/menus";

const AddAttributeKeyForm = ({
  initialValues = {
    attr_type: "",
  },
  id,
  type = "add",
  setFormSubmit,
  setTransition,
  formType,
  setIntialValues,
  keysList,
}) => {
  // console.log("formType: ", formType, "type: ", type);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const history = useHistory();
  const createAttribute = async (values, setSubmitting, resetForm) => {
    setLoading(true);
    try {
      if (formType === "add") {
        var { data } = await httpRequest.post(CREATE_ATTRIBUTE_KEYS, {
          title: values.attr_type,
        });
      } else {
        var { data } = await httpRequest.put(ATTRIBUTE_KEYS + "/update/" + id, {
          title: values.attr_type,
        });
      }

      if (data.status === "Success") {
        setTransition(false);
        setIntialValues({
          attr_type: "",
        });
        if (formType === "edit") {
          setFormSubmit((pre) => !pre);
        } else {
          setFormSubmit((pre) => !pre);
          resetForm({});
        }

        show_Toast({
          status: true,
          message:
            formType === "edit" || type !== "add"
              ? "Update successfully"
              : "Created successfully",
        });
      }

      setSubmitting(false);
      setLoading(false);
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        createAttribute(values, setSubmitting, resetForm);
      }}
      validationSchema={AttributesKeyValidation}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;
        return (
          <Form className="form theme-form">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 d-flex align-items-center gap-2">
                  <div className="flex-grow-1">
                    <InputField
                      placeholder="Attribute Key"
                      label="Attribute Key"
                      type="text"
                      name="attr_type"
                      value={values.attr_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={
                        errors.attr_type &&
                        touched.attr_type &&
                        errors.attr_type
                      }
                      error={
                        errors.attr_type && touched.attr_type ? true : false
                      }
                    />
                  </div>
                  <div className="d-flex justify-content-end gap-2">
                    <ButtonField
                      className={"btn btn-red mt-1"}
                      buttonText={`Cancel`}
                      type="button"
                      onClick={() => setTransition(false)}
                    />
                    <ButtonField
                      className={"btn btn-primary mt-1"}
                      buttonText={formType === "edit" ? "Update" : "Create"}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                {isLoading && <div>Loading...</div>}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default AddAttributeKeyForm;
