import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import httpRequest from "../../../axios";
import HelmetComponent from "../../../components/common/Helmet";
import { PAGE_TITLES } from "../../../constant/pageTitles";
import Breadcrumb from "../../../components/common/breadcrumb";
import ReturnOrderForm from "../../orders/return-order/returnOrderForm";
import ReturnOrderView from "./ReturnOrderView";

const ViewReturnOrder = () => {
  const { type, id } = useParams();

  const [preSelctedProducts, setPreSelectedProducts] = useState([]);

  const [initialValues, setIntialValues] = useState({
    products: [],
    profile_id: "",
    payment_method: "",
    paid_amount: "",
    return_adjust: "",
    description: "",
    total_order_amount: "",
    return_amount: "",
  });

  const getSingleReturnValues = async (type, id) => {
    try {
      const { data } = await httpRequest.get(`/return-orders/${type}/${id}`);
      if (data.status === "Success") {
        let values = data?.data?.data;
        let modifiedProducts = values?.products?.map((item) => {
          let units;
          if (item?.requested_stock?.length) {
            units = item?.requested_stock?.map((stocks) => ({
              id: stocks?.unit_id,
              value: stocks?.stock || 0,
              name: stocks?.unit_name,
              isBaseUnit: stocks?.is_base_unit,
              originalValue: stocks?.originalValue,
              unitSymbol: stocks?.symbol,
            }));
          }
          return {
            id: item?.product_id,
            label: item?.product_name,
            name: item.product_id,
            price: item.product_cost,
            units: units,
            discount: item?.discount,
            productcode: item?.product_id,
            productGst: item?.gst,
            totalPrice: item?.product_price,
            sellingUnitName: item?.selling_unit_title,
            sellingUnitSymbol: item?.selling_unit_symbol,
            sellingUnitId: item?.selling_unit_id,
            stockWithSellingUnit: item?.stock_with_selling_unit,
          };
        });

        setPreSelectedProducts(modifiedProducts);

        setIntialValues({
          ...initialValues,
          products: modifiedProducts,
          profile_id: data?.data?.profile_id,
          payment_method: values?.transaction_type,
          paid_amount: "",
          return_adjust: values?.return_adjust,
          description: values?.description,
          total_order_amount: values?.total_order_amount,
          return_amount: values?.return_amount,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleReturnValues(type, id);
  }, [id, type]);

  return (
    <Fragment>
      <HelmetComponent
        titleText={
          type === "customer"
            ? PAGE_TITLES["ReturnCustomerOrders"]
            : PAGE_TITLES["ReturnSupplierOrders"]
        }
      />
      <Breadcrumb
        title="Return Customer Order"
        parent="Orders"
        parentlink="Orders"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="text-capitalize">
                  Return {type[0].toUpperCase()}
                  {`${type.slice(1)}`} Order
                </h5>
              </div>
              <ReturnOrderView
                initialValues={initialValues}
                id={id}
                type="edit"
                preSelctedProducts={preSelctedProducts}
                orderType={type}
                user={type}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViewReturnOrder;
