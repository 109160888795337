import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";

import RolesForm from "./form";
import HelmetComponent from "../../components/common/Helmet";
import { PAGE_TITLES } from "../../constant/pageTitles";

const AddRole = () => {
  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Roles"]} />
      <Breadcrumb title="Add New Role" parent="Form" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="text-capitalize">Add Role</h5>
              </div>
              <RolesForm type={"add"} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddRole;
