import React, { useEffect, useState } from "react";

import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import SearchSelectField from "../../components/form/select/SearchSelect";

import {
  CREATE_PRODUCT,
  CREATE_PRODUCTS,
  UPDATE_PRODUCTS,
} from "../../constant/apiEndPoints";
import { CreateProductValidations } from "../../utils/validations/products.validations";

import httpRequest from "../../axios";
import show_Toast from "../../helpers/toast.helper";
import SelectField from "../../components/form/select";
import MultiSelect from "../../components/form/multiselect";
import InputField from "../../components/form/input/input";
import TextArea from "../../components/form/textarea/input";
import ButtonField from "../../components/form/button";
import SubAttributeForm from "./subAttributeFrom";

const ProductFrom = ({
  initialValues,
  type = "add",
  id,
  preSelctedProducts = [],
}) => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [allUnits, setAllUnits] = useState([]);
  const [allUnitCategories, setallUnitCategories] = useState([]);

  const [allAttributes, setAttributes] = useState([]);

  const [selectedProductOptions, setSelectedProductOptions] = useState([]);

  const getUnits = async (id) => {
    try {
      const { data } = await httpRequest.get("getUnitCategoryWithUnits/" + id);
      if (data.status === "Success") {
        let unit = data.data.units;
        if (unit.length) {
          unit = unit.map((item) => ({
            label: item.name,
            value: item.id,
            id: item.id,
          }));
          setAllUnits(unit);
        } else {
          setAllUnits([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUnits(initialValues?.category_unit_id);
  }, []);

  const getUnitCategories = async () => {
    try {
      const { data } = await httpRequest.get("unitCategories?pageSize=-1");
      if (data.status === "Success") {
        let unit = data?.data?.data;
        if (unit.length) {
          unit = unit.map((item) => ({
            label: item.title,
            value: item.id,
            id: item.id,
          }));
          setallUnitCategories(unit);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAttributes = async () => {
    try {
      const { data } = await httpRequest.get("attributesKeys?pageSize=-1");
      if (data.status === "Success") {
        let att = data?.data?.data;
        if (att.length) {
          setAttributes(att);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (type === "edit") {
      setSelectedProductOptions([...preSelctedProducts]);
    }
  }, [type, preSelctedProducts]);

  useEffect(() => {
    getUnitCategories();
    getAttributes();
  }, []);

  const handleProductNameChange = (
    index,
    setFieldValue,
    value,
    previousValue
  ) => {
    let newSelectedProductOptions = [...selectedProductOptions];
    if (!newSelectedProductOptions.includes(parseInt(value))) {
      newSelectedProductOptions.push(parseInt(value));
    }
    setSelectedProductOptions(newSelectedProductOptions);
    setFieldValue(`attributes.${index}.name`, parseInt(value));
  };
  // const handleProductNameChange = (
  //   index,
  //   setFieldValue,
  //   selectedOption,
  //   previousValue
  // ) => {
  //   let newSelectedProductOptions = [...selectedProductOptions];
  //   const selectedValue = selectedOption ? selectedOption.value : null;

  //   if (
  //     selectedValue !== null &&
  //     !newSelectedProductOptions.includes(selectedValue)
  //   ) {
  //     newSelectedProductOptions.push(selectedValue);
  //   }

  //   setSelectedProductOptions(newSelectedProductOptions);
  //   setFieldValue(`attributes.${index}.name`, selectedValue);
  // };

  const createProductHandler = async (values, setSubmitting) => {
    let attributes = values?.attributes?.map((element, i) => {
      return {
        key_id: element.name,
        value_id: element.attribute_value,
      };
    });

    let Unit = values?.units?.map((item) => ({
      unit_id: item.id,
    }));

    let newValues = {
      ...values,
      units: Unit,
      attributes,
    };

    try {
      setLoading(true);
      let data;
      if (id) {
        data = await httpRequest.put(UPDATE_PRODUCTS + `/` + id, newValues);
      } else {
        data = await httpRequest.post(CREATE_PRODUCTS, newValues);
      }

      if (data.data.status === "Success") {
        show_Toast({
          status: true,
          message: "Success",
        });
      }

      setSubmitting(false);
      setLoading(false);
      history.push("/products");
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const onAdd = (func, val) => {
    if (val.attributes.length > 0) {
      const lastIndex = val.attributes.length - 1;
      const lastProduct = val.attributes[lastIndex];
      const isFieldsFilled = lastProduct?.name && lastProduct?.attribute_value;
      if (isFieldsFilled) {
        func({
          name: "",
          attribute_value: "",
        });
      } else {
        show_Toast({
          status: false,
          message: "Please fill both fields",
        });
      }
    } else {
      func({
        name: "",
        attribute_value: "",
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        createProductHandler(values, setSubmitting);
      }}
      validationSchema={CreateProductValidations}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;

        return (
          <Form className="form theme-form">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="card-header p-0 pb-2 mb-2">
                    <h5 className="text-capitalize">Attributes</h5>
                  </div>
                  {/* const productOptionsForThisRow = [];
                            allAttributes.forEach((option) => {
                              if (product.name) {
                                if (product.name == option.id) {
                                  productOptionsForThisRow.push(option);
                                }
                              } else {
                                productOptionsForThisRow.push(option);
                              }
                            });
                          */}

                  <div className="">
                    <FieldArray name="attributes">
                      {({ push, remove }) => (
                        <>
                          {values?.attributes?.map((product, index) => {
                            const updatedOptions = allAttributes.filter(
                              (option) => {
                                return (
                                  !values.attributes.some(
                                    (attr) => attr.name == option.id
                                  ) || product.name == option.id
                                );
                              }
                            );

                            return (
                              <div
                                key={index}
                                className={`row mb-4 highlight pb-4`}
                                style={{ margin: "0 0" }}
                              >
                                <div className="col-md-6">
                                  {/* <SelectField
                                    className={"form-control digits"}
                                    name={`attributes.${index}.name`}
                                    value={product.name}
                                    onChange={(event) =>
                                      handleProductNameChange(
                                        index,
                                        setFieldValue,
                                        event.target.value,
                                        product.name
                                      )
                                    }
                                    labelKey={"title"}
                                    onBlur={handleBlur}
                                    options={updatedOptions}
                                  /> */}
                                  <SearchSelectField
                                    className={"inputclass rounded"} // You can adjust the className accordingly
                                    name={`attributes.${index}.name`}
                                    value={product.name}
                                    onChange={(selectedOption) =>
                                      handleProductNameChange(
                                        index,
                                        setFieldValue,
                                        selectedOption.value,
                                        product.name
                                      )
                                    }
                                    labelKey={"title"}
                                    options={updatedOptions}
                                  />
                                  <ErrorMessage
                                    name={`attributes.${index}.name`}
                                    component="div"
                                    className="error-message"
                                  />
                                </div>

                                <div className="col-md-6 d-flex gap-2">
                                  <div className="flex-grow-1">
                                    <SubAttributeForm
                                      index={index}
                                      product={product}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                    />
                                  </div>
                                  <ButtonField
                                    type="button"
                                    className="btn btn-red"
                                    buttonText="-"
                                    onClick={() => {
                                      const newSelectedProductOptions =
                                        selectedProductOptions.filter(
                                          (item) => item != product.name
                                        );

                                      setSelectedProductOptions(
                                        newSelectedProductOptions
                                      );
                                      remove(index);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                          <ButtonField
                            type="button"
                            buttonText="+ Add More"
                            className="btn btn-success mb-3 float-end"
                            onClick={() => onAdd(push, values)}
                          />
                        </>
                      )}
                    </FieldArray>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-header p-0 pb-2 mb-2">
                    <h5 className="text-capitalize">Detail</h5>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <InputField
                        placeholder="Product Title"
                        label="Title"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.name && touched.name && errors.name
                        }
                        error={errors.name && touched.name ? true : false}
                      />
                    </div>

                    <div className="col-md-4">
                      <InputField
                        placeholder="Product Code"
                        label="Code"
                        type="text"
                        name="code"
                        value={values.code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.code && touched.code && errors.code
                        }
                        error={errors.code && touched.code ? true : false}
                      />
                    </div>
                    <div className="col-md-4">
                      <InputField
                        placeholder="GST"
                        label="GST"
                        type="text"
                        name="gst"
                        value={values.gst}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="exampleFormControlSelect9">Status</label>
                      <SelectField
                        className={"form-control digits"}
                        labelKey={"title"}
                        valueKey="title"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={[
                          { id: 1, title: "ACTIVE" },
                          { id: 2, title: "INACTIVE" },
                        ]}
                        errorMessage={
                          errors.status && touched.status && errors.status
                        }
                        error={errors.status && touched.status ? true : false}
                      />
                    </div>
                  </div>
                  <div className="card-header p-0 pb-2 mb-2">
                    <h5 className="text-capitalize">Units</h5>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-6">
                      <InputField
                        placeholder="Product unit price"
                        label="Unit Price"
                        type="string"
                        name="unit_price"
                        value={values.unit_price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.unit_price &&
                          touched.unit_price &&
                          errors.unit_price
                        }
                        error={
                          errors.unit_price && touched.unit_price ? true : false
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Unit Category </label>
                      <SelectField
                        className={"form-control digits"}
                        labelKey={"label"}
                        valueKey="value"
                        name="category_unit_id"
                        value={values.category_unit_id}
                        onChange={(e) => {
                          getUnits(e.target.value);
                          setFieldValue("units", []);
                          setFieldValue("category_unit_id", e.target.value);
                        }}
                        onBlur={handleBlur}
                        options={allUnitCategories}
                        errorMessage={
                          errors.category_unit_id &&
                          touched.category_unit_id &&
                          errors.category_unit_id
                        }
                        error={
                          errors.category_unit_id && touched.category_unit_id
                            ? true
                            : false
                        }
                      />
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="">Selling Units</label>
                      <MultiSelect
                        isMulti={true}
                        options={allUnits}
                        value={values.units}
                        handleChangeOptions={(values) => {
                          setFieldValue("units", values);
                        }}
                      />
                      <ErrorMessage
                        name={`units`}
                        component="div"
                        className="error-message"
                      />
                    </div>

                    {values?.units?.length ? (
                      <div className="col-md-6">
                        <label htmlFor="exampleFormControlSelect9">
                          Select a selling unit
                        </label>
                        <SelectField
                          className={"form-control digits"}
                          labelKey={"label"}
                          valueKey="value"
                          value={values.selling_unit_id}
                          name="selling_unit_id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={values.units}
                          errorMessage={
                            errors.selling_unit_id &&
                            touched.selling_unit_id &&
                            errors.selling_unit_id
                          }
                          error={
                            errors.selling_unit_id && touched.selling_unit_id
                              ? true
                              : false
                          }
                        />
                      </div>
                    ) : null}
                  </div>

                  <div className="col-12">
                    <TextArea
                      placeholder="Product Description"
                      label="Description"
                      type="text"
                      name="description"
                      textAreaRows="2"
                      value={`${values.description ? values.description : ""}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorMessage={
                        errors.description &&
                        touched.description &&
                        errors.description
                      }
                      error={
                        errors.description && touched.description ? true : false
                      }
                    />
                  </div>

                  <div className="col-md-12">
                    <div className="card-footer d-flex justify-content-end pe-0 pb-0 gap-2">
                      <ButtonField
                        className={"btn btn-red"}
                        buttonText={`Cancel`}
                        onClick={goBack}
                        type="button"
                      />
                      <ButtonField
                        className={"btn btn-primary"}
                        buttonText={type === "edit" ? "Update" : "Create"}
                        type="submit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProductFrom;
