import React from "react";
import ExpenseListings from "./expenseListings";
import SaleListings from "./saleListings";
import PurchaseListings from "./purchaseListings";

const DashboardListing = ({
  listings: { expensesListing = [], purchaseListing = [], salesListing = [] },
}) => {
  return (
    <div>
      <SaleListings salesListing={salesListing} />
      <PurchaseListings purchaseListing={purchaseListing} />
      <ExpenseListings expensesListing={expensesListing} />
    </div>
  );
};

export default DashboardListing;
