import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import { getRolesService } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import { PER_PAGE_API } from "../../constant/pagination";
import Search from "../../components/common/search/search";
import { Pagination } from "../../components/common/pagination";
import { Edit } from "react-feather";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";

const RolesListing = () => {
  const [rolesList, setRolesList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [search, setSearch] = useState([]);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const getRolesList = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await getRolesService(
        paginationData.perPage,
        paginationData.currentPage,
        search
      );
      setRolesList(data?.data);
      setLoading(false);
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.current_page,
        totalRecords: data?.total,
        perPage: data?.per_page,
        totalPages: Math.ceil(data.total / data?.per_page),
      }));
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getRolesList();
  }, [paginationData.currentPage, search]);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Roles"]} />
      <Breadcrumb parent="Users" title="listing" />

      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mt-2">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="text-capitalize">All roles</h5>
                  <PermissionBase permissionName={["create_role"]}>
                    <Link
                      to={"role/add"}
                      className="btn btn-success text-capitalize"
                    >
                      Add new role
                    </Link>
                  </PermissionBase>
                </div>
                <div className="card-body">
                  <div className="mb-3 d-flex justify-content-end">
                    <Search
                      classes="form-control search-input"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPaginationData((pre) => ({
                          ...pre,
                          currentPage: 1,
                        }));
                      }}
                      value={search}
                    />
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Role</th>
                          <PermissionBase permissionName={["update_role"]}>
                            <th scope="col">Action</th>
                          </PermissionBase>
                        </tr>
                      </thead>
                      <tbody>
                        {rolesList.length
                          ? rolesList?.map((role, ind) => (
                              <tr key={ind}>
                                <td>{role.name}</td>
                                <PermissionBase
                                  permissionName={["update_role"]}
                                >
                                  <td>
                                    <Link
                                      to={"/role/edit/" + role.id}
                                      className=""
                                    >
                                      <Edit size={20} color="green" />
                                    </Link>{" "}
                                  </td>
                                </PermissionBase>
                              </tr>
                            ))
                          : "No data found"}
                      </tbody>
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default RolesListing;
