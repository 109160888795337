import React from "react";

import "./index.css";

const ExpenseListings = ({ expensesListing }) => {
  return (
    <>
      {expensesListing?.length ? (
        <div className="card my-2 rounded-0">
          <div className="card-header">
            <h5 className="text-capitalize">Expenses</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive sellers">
              <table className="table table-border">
                <thead>
                  <tr>
                    <th scope="col" className="column">
                      Id
                    </th>
                    <th scope="col" className="column">
                      Category
                    </th>
                    <th scope="col" className="column">
                      Amount
                    </th>
                    <th scope="col" className="column">
                      Date
                    </th>
                    <th scope="col" className="column">
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {expensesListing?.map((expense, ind) => (
                    <tr key={ind}>
                      <td>{expense?.id}</td>
                      <td>{expense?.expense_category?.title}</td>
                      <td>{expense?.amount}</td>
                      <td>
                        {new Date(expense?.created_at).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                      </td>
                      <td>{expense?.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ExpenseListings;
