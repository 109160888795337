import * as Yup from "yup";

export const signInValidation = Yup.object().shape({
  email_username: Yup.string().required("Please enter email or username"),
  password: Yup.string().required("Please enter password"),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Email is not valid").required("Email is required"),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});
export const createUserValidation = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  username: Yup.string().required("Username is required"),
  userRole: Yup.string().required("Assign Role is required"),
  email: Yup.string().email("Email is not valid").required("Email is required"),
  password: Yup.string().required("Password is requires"),
});

export const editUserValidation = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  username: Yup.string().required("Username is required"),
  userRole: Yup.string().required("Assign Role is required"),
  email: Yup.string().email("Email is not valid").required("Email is required"),
  status: Yup.string().required("Status is required"),
});
