import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";

import ButtonField from "../../components/form/button";
import CheckBoxField from "../../components/form/checkbox/checkbox";
import InputField from "../../components/form/input/input";

import show_Toast from "../../helpers/toast.helper";
import httpRequest from "../../axios";
import { ROLES, CREA_ROLE, UPDATE_ROLE } from "../../constant/apiEndPoints";
import { RoleValidation } from "../../utils/validations/roles.validation";
import { getPermissionsService } from "../../services";
import { useHistory } from "react-router-dom";
import CustomLoader from "../../components/common/customLoader/customLoader";

const RolesForm = ({
  type = "add",
  initialValues = {
    name: "",
    terms_conditions: "",
    is_private: false,
  },
  preSelectedPermission = [],
  id,
}) => {
  const [permissionList, setPermissionsList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();

  const getPermissionList = async () => {
    try {
      const { data } = await getPermissionsService();
      if (data.status === "Success") {
        let allPermissions = data?.data?.data || [];
        let Groups = [];
        let allPermissionsWithGroup = [];
        if (allPermissions.length) {
          allPermissions.forEach((element) => {
            if (!Groups.includes(element.group)) {
              Groups.push(element.group);
            }
          });
          if (Groups.length) {
            Groups.forEach((singleGroup) => {
              let singlePermssions = [];
              allPermissions.forEach((singlePer) => {
                if (singleGroup === singlePer.group) {
                  singlePermssions.push(singlePer);
                }
              });
              allPermissionsWithGroup.push(singlePermssions);
            });
            setPermissionsList(allPermissionsWithGroup);
          }
        }
      }
      // setPermissionsList(data?.data || []);
    } catch (error) {
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  const createRole = async (values, setSubmitting) => {
    setLoading(true);

    try {
      let checkedPermissions = [];
      permissionList.forEach((perm) => {
        perm.forEach((item) => {
          if (item.is_default) {
            checkedPermissions.push(item);
          }
        });
      });

      if (!(checkedPermissions && checkedPermissions.length)) {
        show_Toast({
          status: false,
          message: "Select any permission first",
        });
      } else {
        const selectedPermissions = checkedPermissions.map((item) => item.id);

        if (type === "add") {
          var { data } = await httpRequest.post(CREA_ROLE, {
            ...values,
            permissions: selectedPermissions,
            terms_conditions: "terms_conditions",
          });
        } else {
          var { data } = await httpRequest.put(UPDATE_ROLE + "/" + id, {
            ...values,
            permissions: selectedPermissions,
            terms_conditions: "terms_conditions",
          });
        }
        setSubmitting(false);
        setLoading(false);
        show_Toast({
          status: true,
          message:
            data.status && type == "add"
              ? "Added Successfully"
              : "Updated Successfully",
        });
      }
      setSubmitting(false);
      history.push("/roles");
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    getPermissionList();
  }, []);

  useEffect(() => {
    if (type === "edit" && permissionList.length) {
      const permissionModified = permissionList.map((groupPermission) => {
        let groupPermissions = groupPermission.map((singlePermission) => {
          if (preSelectedPermission.includes(singlePermission.id)) {
            return { ...singlePermission, is_default: true };
          } else {
            return singlePermission;
          }
        });
        return groupPermissions;
      });
      setPermissionsList([...permissionModified]);
    }
    // console.log("preSelectedPermission: ", preSelectedPermission)
  }, [type, preSelectedPermission, permissionList.length]);
  // console.log("us preSelectedPermission: ", preSelectedPermission);

  const onValueSelect = (checked, permObj, mainIndex) => {
    const permissionModified = permissionList[mainIndex].map(
      (singlePermission) => {
        if (singlePermission.id === permObj.id) {
          return { ...permObj, is_default: checked };
        } else {
          return singlePermission;
        }
      }
    );
    permissionList[mainIndex] = permissionModified;
    setPermissionsList([...permissionList]);
  };

  const onValueSelectMain = (checked, mainIndex) => {
    const permissionModified = permissionList[mainIndex].map(
      (singlePermission) => {
        return { ...singlePermission, is_default: checked };
      }
    );
    permissionList[mainIndex] = permissionModified;
    setPermissionsList([...permissionList]);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        createRole(values, setSubmitting);
      }}
      enableReinitialize
      validationSchema={RoleValidation}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
        } = props;
        return (
          <>
            {isLoading ? (
              <div>
                <CustomLoader />
              </div>
            ) : (
              <Form className="form theme-form">
                <div className="card-body pt-3">
                  <div className="row">
                    <div className="col-sm-9">
                      <InputField
                        placeholder="Name"
                        label="name"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.name && touched.name && errors.name
                        }
                        error={errors.name && touched.name ? true : false}
                      />
                    </div>
                    {/* <div className="col-sm-3 d-flex align-items-center">
                  <CheckBoxField
                    defaultChecked={values.is_private}
                    title={"Private"}
                    checkId={"is_private"}
                    checkFor={"is_private"}
                    onclick={(e) => {
                      setFieldValue("is_private", e.target.checked);
                    }}
                  />
                </div> */}
                    {/* <div className="col-12">
                  <textarea
                    className="form-control textareaclass"
                    placeholder="Terms and conditions"
                    name="terms_conditions"
                    onChange={handleChange}
                    defaultValue={values.terms_conditions}
                  >
                  </textarea>
                </div> */}
                  </div>
                  <div className="permissions-list row mt-2">
                    <div className="card-header ml-0 p-2">
                      <h5 className="text-capitalize">Permissions</h5>
                    </div>
                    {/* {permissionList &&
                  permissionList.length &&
                  permissionList.map((permission, index) => (
                    <div
                      className="popup-item mt-1 col-sm-6 col-md-4"
                      key={index}
                    >
                      <CheckBoxField
                        defaultChecked={!!permission.is_default}
                        title={permission.display_name}
                        checkId={permission.name}
                        checkFor={permission.name}
                        onclick={(e) => {
                          onValueSelect(e.target.checked, permission);
                        }}
                      />
                    </div>
                  ))} */}

                    {permissionList &&
                      permissionList.length &&
                      permissionList.map((permissionGroup, mainIndex) => (
                        <div className="col-md-12 mt-4">
                          <div
                            className={`mt-0 mb-3 d-flex ${
                              mainIndex !== 0 ? "mt-4" : ""
                            }`}
                            style={{ borderBottom: "1px solid #3131310b" }}
                          >
                            <h5 className="me-4" style={{ fontWeight: "700" }}>
                              {permissionGroup[0].group}
                            </h5>
                            <CheckBoxField
                              defaultChecked={permissionGroup.every(
                                (item) => item.is_default
                              )}
                              // title={permissionGroup[0].group}
                              checkId={permissionGroup[0].group}
                              checkFor={permissionGroup[0].group}
                              onClick={(e) => {
                                onValueSelectMain(e.target.checked, mainIndex);
                              }}
                            />
                          </div>
                          <div className="d-flex gap-4">
                            {permissionGroup &&
                              permissionGroup.length &&
                              permissionGroup.map((permission, index) => (
                                <div className="popup-item" key={index}>
                                  <CheckBoxField
                                    defaultChecked={permission.is_default}
                                    title={permission.display_name}
                                    checkId={permission.name}
                                    checkFor={permission.name}
                                    onClick={(e) => {
                                      onValueSelect(
                                        e.target.checked,
                                        permission,
                                        mainIndex
                                      );
                                    }}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end gap-2">
                  {/* <ButtonField
                className={"btn btn-red"}
                buttonText={`Cancel`}
                type="button"
              /> */}
                  <ButtonField
                    className={"btn btn-primary"}
                    buttonText={type === "edit" ? "Update" : "Create"}
                    disabled={isSubmitting}
                  />
                </div>
              </Form>
            )}
          </>
        );
      }}
    </Formik>
  );
};
export default RolesForm;
