import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  publicConfigurations: [],
};

const publicConfigurationsReducer = createSlice({
  name: "publicConfig",
  initialState: initialState,
  reducers: {
    setPublicConfigurations: (state, action) => {
      state.publicConfigurations = action.payload;
    },
    setPublicConfigurationsEmpty: (state, action) => {
      state.publicConfigurations = [];
    },
  },
});

export const { setPublicConfigurations, setPublicConfigurationsEmpty } =
  publicConfigurationsReducer.actions;
export default publicConfigurationsReducer.reducer;
