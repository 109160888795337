import * as Yup from "yup";

export const UnitValidations = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  symbol: Yup.string().required("Symbol is required"),
  value: Yup.string().required("Value is required"),
  unit_category_id: Yup.string().required("Category is required"),
});

export const UnitCategoryValidations = (type) => {
  let schema = Yup.object().shape({
    unit_category_title: Yup.string().required(
      "Unit Category Title is required"
    ),
    unit_name:
      type === "add"
        ? Yup.string().required("Unit Name is required")
        : Yup.string(),
    unit_symbol:
      type === "add"
        ? Yup.string().required("Unit Symbol is required")
        : Yup.string(),
    unit_value:
      type === "add"
        ? Yup.string().required("Unit Value is required")
        : Yup.string(),
  });
  return schema;
};
