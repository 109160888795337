import { Home } from "react-feather";

export const MENUITEMS = [
  {
    title: "Users",
    icon: Home,
    type: "link",
    path: "/users",
    active: true,
  },
  {
    title: "Roles & Permissions",
    icon: Home,
    type: "link",
    path: "/roles",
  },
  {
    title: "Attributes",
    icon: Home,
    type: "link",
    path: "/attribute",
  },
];
