import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../components/common/breadcrumb";
import AddAttributeKeyForm from "./form";

import { useHistory, useParams } from "react-router-dom";
import { getAttributeKeyByKeyID } from "../../../services";
import show_Toast from "../../../helpers/toast.helper";

const EditAttributeKey = () => {
  const history = useHistory();
  const { id } = useParams();

  const [initialValues, setIntialValues] = useState({
    attr_type: "",
  });

  const getAttributeKeyTitle = async (id) => {
    try {
      const { data } = await getAttributeKeyByKeyID(id);
      if (data.status === "Success") {
        const { title = "" } = data?.data;
        setIntialValues({
          attr_type: title,
        });
      }
    } catch (error) {
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    if (id) {
      getAttributeKeyTitle(id);
    } else {
      history.push("/users");
    }
  }, [id]);

  return (
    <Fragment>
      <Breadcrumb title="Edit User" parent="Users" parentlink="users" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5 className="text-capitalize">Edit Attribute</h5>
              </div>
              <AddAttributeKeyForm
                id={id}
                initialValues={initialValues}
                formType="edit"
              />
              ;
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditAttributeKey;
