import * as Yup from "yup";

const AttributesSchema = Yup.object().shape({
  name: Yup.string().required("Attribute is required"),
  attribute_value: Yup.number().required("Attribute values is required"),
});

export const CreateProductValidations = Yup.object().shape({
  name: Yup.string().required("Title is required"),
  code: Yup.string().required("Code is required"),
  status: Yup.string().required("Status is required"),
  selling_unit_id: Yup.string().required("Assign selling unit to any unit"),
  unit_price: Yup.string().required("Unit Price is required"),
  attributes: Yup.array().of(AttributesSchema).required("Product is required"),
  units: Yup.array().min(1, "Unit is required"),
  category_unit_id: Yup.string().required("Unit Category is required"),
});
