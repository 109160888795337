import { useState } from "react";
import { Form, Formik } from "formik";
import ButtonField from "../../components/form/button";
import InputField from "../../components/form/input/input";
import httpRequest from "../../axios";
import { useSelector } from "react-redux";
import show_Toast from "../../helpers/toast.helper";
import {
  CREATE_ATTRIBUTE_VALUES,
  UPDATE_ATTRIBUTE_VALUES,
} from "../../constant/apiEndPoints";
import SelectField from "../../components/form/select";
import { AttributesValidation } from "../../utils/validations/attributes.validations";
import CustomLoader from "../../components/common/customLoader/customLoader";

const AttributeForm = ({
  type = "add",
  initialValues = {
    attr_type: "",
    attr_value: "",
  },
  formSuccessHandler = () => {},
  editId = "",
  setTransition,
  keysList,
}) => {
  const [isLoading, setLoading] = useState(false);

  const createAttribute = async (values, setSubmitting, resetForm) => {
    setLoading(true);
    try {
      let res;
      if (type === "add") {
        res = await httpRequest.post(CREATE_ATTRIBUTE_VALUES, {
          attribute_key_id: values.attr_type,
          value: values.attr_value,
        });
      } else {
        res = await httpRequest.put(UPDATE_ATTRIBUTE_VALUES + "/" + editId, {
          attribute_key_id: values.attr_type,
          value: values.attr_value,
        });
      }

      if (res?.data?.status === "Success") {
        show_Toast({
          status: true,
          message:
            type === "add" ? "Added Successfully" : "Updated Successfully",
        });
      }
      setSubmitting(false);
      setLoading(false);
      resetForm({});
      formSuccessHandler();
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        createAttribute(values, setSubmitting, resetForm);
      }}
      enableReinitialize
      validationSchema={AttributesValidation}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          resetForm,
        } = props;
        return (
          <>
            {isLoading ? (
              <div>
                <CustomLoader />
              </div>
            ) : (
              <Form className="form theme-form">
                <div className="card-body pb-0">
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <label htmlFor="exampleFormControlSelect9">
                        Attribute Key
                      </label>
                      <SelectField
                        className={"form-control digits"}
                        name="attr_type"
                        value={values.attr_type}
                        onChange={handleChange}
                        labelKey={"title"}
                        onBlur={handleBlur}
                        options={keysList}
                        errorMessage={errors.attr_type}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <InputField
                        placeholder="Attribute value"
                        label="Attribute value"
                        type="text"
                        name="attr_value"
                        value={values.attr_value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.attr_value &&
                          touched.attr_value &&
                          errors.attr_value
                        }
                        error={
                          errors.attr_value && touched.attr_value ? true : false
                        }
                      />
                    </div>
                    <div className="col-sm-12 col-md-4 d-flex align-items-center justify-content-end justify-content-md-start gap-2">
                      <ButtonField
                        className={"btn btn-primary order-2"}
                        buttonText={type === "add" ? "Create" : "Update"}
                        disabled={isSubmitting}
                      />
                      <ButtonField
                        className="btn btn-red order-1"
                        type="button"
                        buttonText="Reset"
                        onClick={() => {
                          resetForm();
                          setTransition(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </>
        );
      }}
    </Formik>
  );
};
export default AttributeForm;
