import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../components/common/breadcrumb";

import { Link, useHistory, useParams } from "react-router-dom";
import {
  getProductService,
  getSingleUnitCategoryService,
  updateProductPriceById,
} from "../../services";
import show_Toast from "../../helpers/toast.helper";
import UnitForm from "../units/form";

import ProductListing from "./allProducts";
import CustomLoader from "./../../components/common/customLoader/customLoader";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";
import ModalBox from "../../components/common/modal/modal";
import { PRODUCT_PRICE_UPDATE } from "../../constant/apiEndPoints";
import httpRequest from "../../axios/index";

const Products = () => {
  const [productsList, setProductsList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(0);
  const [search, setSearch] = useState([]);
  const [price, setPrice] = useState("");
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [productId, setProductId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const toggleModel = () => {
    setShowModal(!showModal);
  };

  const [filters, setFilters] = useState({
    productCode: "",
    status: "",
    inStock: "ALL",
  });

  const [apply, setApply] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const onChange = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const handlePriceUpdate = async () => {
    try {
      setLoader(true);
      const { data } = await httpRequest.put(
        PRODUCT_PRICE_UPDATE + "/" + productId,
        { unit_price: price }
      );
      if (data.status === "Success") {
        show_Toast({
          status: true,
          message: "Price Updated Successfully!",
        });
        getUsersListing();
        setLoader(false);
        setShowModal(false);
      }
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  const getUsersListing = async () => {
    try {
      setLoading(true);
      const {
        data: { data = [] },
      } = await getProductService(
        paginationData.perPage,
        paginationData.currentPage,
        search,
        filters,
        filters.inStock
      );
      setProductsList(data?.data);
      setLoading(false);
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data.current_page,
        totalRecords: data.total,
        perPage: data?.per_page,
        totalPages: Math.ceil(data.total / data?.per_page),
      }));
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getUsersListing();
  }, [paginationData.currentPage, search, apply]);

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Products"]} />
      <Breadcrumb
        title="Products List"
        parent="Products"
        parentlink="products"
      />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mt-2">
                <div className="card-header d-flex justify-content-between">
                  <h5 className="text-capitalize">{"All Products"}</h5>
                  <PermissionBase permissionName={["create_products"]}>
                    <Link className="btn btn-success" to={"/products/add"}>
                      Add New product
                    </Link>
                  </PermissionBase>
                </div>
                <ProductListing
                  productsList={productsList}
                  search={search}
                  setSearch={setSearch}
                  paginationData={paginationData}
                  setPaginationData={setPaginationData}
                  isLoading={isLoading}
                  filters={filters}
                  setFilters={setFilters}
                  setApply={setApply}
                  onChange={onChange}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                  toggle={toggle}
                  price={price}
                  setPrice={setPrice}
                  handlePriceUpdate={handlePriceUpdate}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  toggleModel={toggleModel}
                  setProductId={setProductId}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalBox
        isOpen={showModal}
        toggle={toggleModel}
        title={"Update Unit Price"}
        handlePriceUpdate={handlePriceUpdate}
        loader={loader}
      >
        <form onSubmit={() => handlePriceUpdate()}>
          <div className="form-group">
            <label className="col-form-label" htmlFor="recipient-name">
              Unit Price
            </label>
            <input
              className="form-control"
              type="number"
              placeholder="Price"
              value={price}
              name="price"
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </form>
      </ModalBox>
    </Fragment>
  );
};

export default Products;
