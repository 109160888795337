import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
const Spinner = ({ color, loader, size }) => {
  return (
    <ClipLoader
      color={color}
      loading={loader}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default Spinner;
