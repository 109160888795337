import React from "react";
import "./index.scss";
import { formatNumberandComma } from "../../../services";

const BalanceSheet = ({ data }) => {
  const {
    totalSaleAmount = 0,
    totalPurchaseAmount = 0,
    totalExpenseAmount = 0,
  } = data;
  return (
    <div className="balance-sheet-container">
      <p className="balance-sheet-container__heading">Summary Report</p>
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col-md-6">
            <div className="sheet-heading">Category</div>
            <div className="sheet-data">Total Sales</div>
            <div className="sheet-data">Total Purchases</div>
            <div className="sheet-data1">Total Expense</div>
          </div>
          <div className="col-md-6 d-flex flex-column align-items-end">
            <div className="sheet-heading">Amount</div>
            <div className="sheet-data">
              {formatNumberandComma(totalSaleAmount) || 0}
            </div>
            <div className="sheet-data">
              {formatNumberandComma(totalPurchaseAmount) || 0}
            </div>
            <div className="sheet-data1">
              {formatNumberandComma(totalExpenseAmount) || 0}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceSheet;
