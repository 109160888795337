import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import man from "../../../assets/images/dashboard/user.png";

const UserPanel = () => {
  const { user } = useSelector((state) => state.login);
  const { configurations } = useSelector((state) => state.configurations);
  const [companyName, setCompanyName] = useState("");
  const [mainLogo, setMainLogo] = useState();

  useEffect(() => {
    const logo = configurations.appLogo;
    setMainLogo(logo);
  }, [configurations]);

  useEffect(() => {
    let name = configurations.companyName;
    setCompanyName(name);
  }, [configurations]);

  return (
    <div className="sidebar-user text-center">
      <div>
        <img
          className="img-60 rounded-circle lazyloaded blur-up"
          src={mainLogo}
          alt="#"
        />
      </div>
      <h6 className="mt-3 f-14">{companyName}</h6>
      {/* <p>{user?.role?.name}</p> */}
    </div>
  );
};

export default UserPanel;
