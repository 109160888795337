import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import httpRequest from "../../axios";
import {
  CREATE_UNIT_CATEGORIES,
  UNIT_CATEGORIES,
} from "../../constant/apiEndPoints";
import show_Toast from "../../helpers/toast.helper";
import { UnitCategoryValidations } from "../../utils/validations/units.validations";
import CustomLoader from "../../components/common/customLoader/customLoader";

const UnitForm = ({
  initialValues = {
    unit_category_title: "",
    unit_name: "",
    unit_symbol: "",
    unit_value: "",
  },
  type = "add",
  formSuccessHandler = () => {},
  setTransition,
  editId = "",
}) => {
  const [isLoading, setLoading] = useState(false);

  const createUnit = async (values, setSubmitting) => {
    setLoading(true);
    try {
      if (type === "add") {
        var { data } = await httpRequest.post(CREATE_UNIT_CATEGORIES, values);
      } else {
        delete values.unit_name;
        delete values.unit_symbol;
        delete values.unit_value;
        var { data } = await httpRequest.put(
          UNIT_CATEGORIES + "/update/" + editId,
          values
        );
      }

      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: true,
        message:
          data.status === "Success" && type === "edit"
            ? "Unit Category Updated successfully"
            : "Unit Category Created successfully",
      });
      formSuccessHandler();
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        createUnit(values, setSubmitting);
      }}
      validationSchema={UnitCategoryValidations(type)}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          resetForm,
          isSubmitting,
        } = props;
        return (
          <>
            {isLoading ? (
              <CustomLoader />
            ) : (
              <Form className={`form theme-form`}>
                <div className="card-body">
                  <div className="row">
                    <div className={`col-sm-${type === "add" ? "3" : "6"}`}>
                      <InputField
                        placeholder="Unit Category Title"
                        label="Unit Category title"
                        type="text"
                        name="unit_category_title"
                        value={values.unit_category_title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.unit_category_title &&
                          touched.unit_category_title &&
                          errors.unit_category_title
                        }
                        error={
                          errors.unit_category_title &&
                          touched.unit_category_title
                            ? true
                            : false
                        }
                      />
                    </div>
                    {type !== "edit" ? (
                      <>
                        <div className="col-sm-3">
                          <InputField
                            placeholder="Unit Name"
                            label="Unit Name"
                            type="text"
                            name="unit_name"
                            value={values.unit_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.unit_name &&
                              touched.unit_name &&
                              errors.unit_name
                            }
                            error={
                              errors.unit_name && touched.unit_name
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="col-sm-3">
                          <InputField
                            placeholder="Unit Symbol"
                            label="Unit Symbol"
                            type="text"
                            name="unit_symbol"
                            value={values.unit_symbol}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.unit_symbol &&
                              touched.unit_symbol &&
                              errors.unit_symbol
                            }
                            error={
                              errors.unit_symbol && touched.unit_symbol
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="col-sm-3">
                          <InputField
                            placeholder="Unit Value"
                            label="Unit Value"
                            type="text"
                            name="unit_value"
                            value={values.unit_value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errorMessage={
                              errors.unit_value &&
                              touched.unit_value &&
                              errors.unit_value
                            }
                            error={
                              errors.unit_value && touched.unit_value
                                ? true
                                : false
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="d-flex justify-content-end gap-2">
                    <ButtonField
                      buttonText="Cancel"
                      type="button"
                      className="btn btn-red"
                      onClick={() => {
                        setTransition(false);
                        resetForm();
                      }}
                    />
                    <ButtonField
                      className={"btn btn-primary me-2"}
                      buttonText={type === "edit" ? "Update" : "Create"}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </Form>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default UnitForm;
