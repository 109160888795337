import React, { Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import UnitForm from "./form";

const AddUnitCategory = () => {
  return (
    <Fragment>
      <Breadcrumb
        title="Add New Unit Category"
        parent="Units"
        parentlink="Units Category"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Add New Unit Category</h5>
                <UnitForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddUnitCategory;
