import { Form, Formik } from "formik";
import { parseInt } from "lodash";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { ErrorMessage, Field, FieldArray } from "formik";
import {
  calculateTotalAmount,
  getAllProfiles,
  getProductService,
} from "../../../services";
import { PaymentOptionsOrder } from "../../../constant";
import Select from "react-select";
import { debounce } from "lodash";

import InputField from "../../../components/form/input/input";
import ButtonField from "../../../components/form/button";
import httpRequest from "../../../axios";
import show_Toast from "../../../helpers/toast.helper";
import SearchSelectField from "../../../components/form/select/SearchSelect";
import "./index.css";
import "./index.scss";

import TextArea from "../../../components/form/textarea/input";
import OrderAmount from "../../orders/return-order/OrderAmount";
import PayableAmount from "../../orders/return-order/PayableAmount";
import ReturnAmount from "../../orders/return-order/returnAmount";
import ReturnProductDetailTable from "./ReturnProductDetailTable";
import ReturnMultipleUnits from "./ReturnMultipleUnits";
import {
  CUSTOMER_RETURN_ORDERS,
  SUPPLIER_RETURN_ORDERS,
} from "../../../constant/apiEndPoints";

const ReturnOrderView = ({
  initialValues,
  type,
  id,
  preSelctedProducts = [],
  orderType = "",
  order = "",
  user,
}) => {
  const viewOnly = true;
  const history = useHistory();
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate());

  const [posts, setPosts] = useState([]);
  const [profiles, setprofiles] = useState([]);
  const [isReceived, setIsReceived] = useState(initialValues.is_received);
  const [isPaid, setIsPaid] = useState(initialValues.is_paid);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const getPosts = async () => {
    try {
      const { data } = await getProductService();
      if (data.status === "Success") {
        let poss = data?.data?.data;
        if (poss.length) {
          poss = poss.map((item) => {
            return {
              ...item,
              label: item?.data?.code + " - " + item?.data?.name,
              productcode: item?.data?.code,
              unitPrice: item?.data?.unit_price,
              productgst: item?.data?.gst,
              sellingUnitId: item?.data?.selling_unit_id,
              sellingUnitName: item?.data?.selling_unit_title,
              sellingUnitSymbol: item?.data?.selling_unit_symbol,
            };
          });
          setPosts(poss);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProfiles = async () => {
    try {
      const { data } = await getAllProfiles(-1, 1, "", orderType);
      if (data.status === "Success") {
        let poss = data?.data?.data;

        if (poss.length) {
          poss = poss.map((item) => {
            return {
              ...item,
              label:
                item?.data?.name + "     ( " + item?.data?.mobile_number + " )",
              value:
                item?.data?.name + "     ( " + item?.data?.mobile_number + " )",
            };
          });
          if (user === "customer") {
            poss.unshift({
              label: "walk-in customer",
              value: "walk-in customer",
              id: 0,
            });
          }
        } else {
          if (user === "customer") {
            poss.unshift({
              label: "walk-in customer",
              value: "walk-in customer",
              id: 0,
            });
          }
        }
        setprofiles(poss);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPosts();
    getProfiles();
  }, []);

  useEffect(() => {
    if (type === "edit") {
      setSelectedProducts([...preSelctedProducts]);
    }
  }, [type, preSelctedProducts]);

  useEffect(() => {
    setIsPaid(initialValues.is_paid);
    setIsReceived(initialValues.is_received);
  }, [
    initialValues.is_paid,
    initialValues.is_received,
    initialValues.profile_id,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {}}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          handleBlur,
          handleChange,
          isSubmitting,
        } = props;

        return (
          <Form className="form theme-form">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-end absolute-total gap-4">
                    <OrderAmount
                      totalOrderAmount={initialValues?.total_order_amount}
                    />
                    <ReturnAmount
                      returnAmountInitial={initialValues?.return_amount}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex justify-content-end align-items-center gap-4">
                    <div className="radio radio-primary">
                      <input
                        id="cashback"
                        type="radio"
                        name="topradio"
                        value={"cashback"}
                        onChange={(e) => {}}
                        disabled={viewOnly}
                        checked={values?.return_adjust === "cashback"}
                      />
                      <label htmlFor="cashback">Cashback</label>
                    </div>

                    <div className="radio radio-primary">
                      <input
                        id="depositin"
                        type="radio"
                        name="topradio"
                        value={"depositin"}
                        onChange={(e) => {}}
                        disabled={viewOnly}
                        checked={values?.return_adjust === "depositin"}
                      />
                      <label htmlFor="depositin">Deposit in</label>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <h6 className="">Products</h6>
                    <div className="row">
                      <div className="col-md-12 product-scroll-custom-return">
                        <FieldArray name="products">
                          {({ push, remove }) => (
                            <>
                              {selectedProducts.map((product, index) => {
                                const sellingUnit = posts.find(
                                  (post) => post.id == product.name
                                );
                                return (
                                  <div
                                    key={index}
                                    className={`row highlight mb-2 mt-2 pt-0`}
                                    style={{ fontSize: "12px" }}
                                  >
                                    <div className="mb-1 mt-1">
                                      <ReturnProductDetailTable
                                        code={product?.productcode}
                                        totalPrice={product?.totalPrice}
                                        discount={product?.discount}
                                        productGst={product?.productGst}
                                        label={product?.label}
                                      />
                                    </div>

                                    <div className="col-lg-2 mt-2">
                                      <label htmlFor="">
                                        Cost /
                                        {product?.sellingUnitSymbol
                                          ? `${` ${product?.sellingUnitSymbol}`}`
                                          : ""}
                                      </label>
                                      <Field
                                        className="form-control inputclass"
                                        name={`products.${index}.price`}
                                        value={selectedProducts[index].price}
                                        placeholder="cost"
                                        type="number"
                                        style={{ fontSize: "12px" }}
                                        onChange={(e) => {}}
                                        disabled={viewOnly}
                                      />
                                      <ErrorMessage
                                        name={`products.${index}.price`}
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <div className="col-lg-2 mt-2">
                                      <label htmlFor="">Discount</label>
                                      <Field
                                        className="form-control inputclass"
                                        name={`products.${index}.discount`}
                                        placeholder="discount"
                                        type="number"
                                        style={{ fontSize: "12px" }}
                                        value={selectedProducts[index].discount}
                                        onChange={(e) => {
                                          const { value } = e.target;
                                          const updatedProducts =
                                            selectedProducts.map((prod, idx) =>
                                              idx === index
                                                ? {
                                                    ...prod,
                                                    discount: value,
                                                  }
                                                : prod
                                            );

                                          setSelectedProducts(updatedProducts);
                                        }}
                                        disabled={viewOnly}
                                      />
                                      <ErrorMessage
                                        name={`products.${index}.price`}
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                    <ReturnMultipleUnits
                                      index={index}
                                      product={product}
                                      currentSelectedUnits={
                                        sellingUnit?.data?.units || []
                                      }
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      disabled={viewOnly}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      {values?.payment_method ? (
                        <div className="col-md-12 mb-3">
                          <label htmlFor="" className="text-capitalize">
                            Payment method
                          </label>
                          <SearchSelectField
                            className={
                              "inputclass rounded flex-grow-1 zindex-top"
                            }
                            name="payment_method"
                            value={values.payment_method}
                            onChange={(e) => {
                              setFieldValue("payment_method", e.id);
                            }}
                            onBlur={handleBlur}
                            options={PaymentOptionsOrder}
                            // disabled={values.return_adjust !== "cashback"}
                            disabled={viewOnly}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="col-md-12 mt-3 mb-3">
                        <label>
                          {orderType === "customer" ? "Customer" : "Supplier"}
                        </label>
                        <Select
                          className="border rounded"
                          options={profiles}
                          name="profile_id"
                          value={profiles.find(
                            (profile) => profile.id === values.profile_id
                          )}
                          onChange={() => {}}
                          onBlur={handleBlur}
                          placeholder={`Search for ${
                            orderType === "customer" ? "Customer" : "Seller"
                          }`}
                          isSearchable
                          isDisabled={viewOnly}
                        />
                      </div>
                      <div className="col-md-12" style={{ width: "100%" }}>
                        <div className="row">
                          <div className="col-md-12">
                            <TextArea
                              placeholder="Description"
                              label="Description"
                              type="text"
                              name="description"
                              textAreaRows="2"
                              value={`${
                                values?.description ? values?.description : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // disabled={true}
                              error={
                                errors.description && touched.description
                                  ? true
                                  : false
                              }
                              errorMessage={
                                errors.description &&
                                touched.description &&
                                errors.description
                              }
                              disabled={viewOnly}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 card-footer d-flex justify-content-end gap-2 me-0 pe-0">
                        <ButtonField
                          className={"btn btn-primary"}
                          buttonText={"Back"}
                          type="button"
                          onClick={() => {
                            history.goBack();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReturnOrderView;
