import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import {
  getExpenseService,
  getUnitCategoryService,
  getUsersService,
  getExpenseCategoryService,
  formatNumberandComma,
} from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import ExpenseForm from "./form";
import "./index.scss";
import Search from "../../components/common/search/search";
import { Pagination } from "../../components/common/pagination";
import { PER_PAGE_API } from "../../constant/pagination";
import { Edit, Filter, ChevronDown, ChevronUp } from "react-feather";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { Accordion } from "react-bootstrap";
import { Card, CardBody, Collapse, Button } from "reactstrap";
import PermissionBase from "../../components/common/permissionBase/permissionBase";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";
const ExpenseListing = () => {
  const [expenseList, setExpenseList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const formRef = useRef(null);

  const [search, setSearch] = useState("");
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [expenseCategoriesList, setExpenseCategoriesList] = useState([]);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [transition, setTransition] = useState(false);
  const [editId, setEditid] = useState("");
  const [formType, setFormType] = useState("add");
  const [initialValues, setIntialValues] = useState({
    expense_category: "",
    amount: "",
    description: "",
  });

  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));

  const getExpenseListing = async (fromDate, toDate, selectedOption) => {
    try {
      setLoading(true);
      const { data } = await getExpenseService(
        paginationData.perPage,
        paginationData.currentPage,
        search,
        fromDate,
        toDate,
        selectedOption
      );
      if (data.status === "Success") {
        setExpenseList(data?.data?.data);
      }
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };
  const getExpenseCategoryListing = async () => {
    try {
      const { data } = await getExpenseCategoryService();
      if (data.status === "Success") {
        setExpenseCategoriesList(data?.data?.data);
      }
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };
  useEffect(() => {
    getExpenseCategoryListing();
  }, []);
  useEffect(() => {
    getExpenseListing();
  }, [paginationData.currentPage, search]);

  const formSuccessHandler = () => {
    getExpenseListing();
  };

  const handleAddUnit = () => {
    setFormType("add");
    setTransition(true);
    setIntialValues({
      expense_category: "",
      amount: "",
      description: "",
    });
  };

  const handleEditExpense = (expense) => {
    setEditid(expense.id);
    setIntialValues({
      ...initialValues,
      expense_category: expense?.expense_category?.id,
      amount: expense.amount,
      description: expense.description,
    });
    setFormType("edit");
  };
  const handleToDate = (e) => {
    setToDate(e.target.value);
  };
  const handleFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const filterApply = () => {
    getExpenseListing(fromDate, toDate, selectedOption);
  };
  const clearFilter = () => {
    setToDate("");
    setFromDate("");
    setSelectedOption("");
    setPaginationData((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
    getExpenseListing("", "", "");
  };

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["Expense"]} />

      <Breadcrumb parent="Expenses" title="listing" parentlink="users" />

      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div
                className={`card mt-2 section ${
                  transition ? "show-units" : "hide"
                }`}
                ref={formRef}
              >
                <ExpenseForm
                  type={formType}
                  initialValues={initialValues}
                  formSuccessHandler={formSuccessHandler}
                  editId={editId}
                  setTransition={setTransition}
                  formRef={formRef}
                />
              </div>
            </div>
          </div>
          <div className={`row`}>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between items-center">
                  <h5 className="text-capitalize">{"All Expenses"}</h5>
                  <PermissionBase permissionName={["create_expense"]}>
                    <button className="btn btn-success" onClick={handleAddUnit}>
                      Add Expense
                    </button>
                  </PermissionBase>
                </div>
                <div className="card-body">
                  <div className="mb-3 d-flex justify-content-end">
                    <Search
                      classes="form-control search-input"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPaginationData((pre) => ({
                          ...pre,
                          currentPage: 1,
                        }));
                      }}
                      value={search}
                    />
                  </div>
                  {/* filters */}

                  <Accordion
                    defaultActiveKey="0"
                    className="bg-light"
                    style={{ color: "#000", border: "1px solid red" }}
                  >
                    <Button
                      as={Card.Header}
                      className="w-100 border-0 px-3 pt-2 rounded d-flex justify-content-between align-items-center"
                      color="default"
                      onClick={() => toggle(1)}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <h5 className="text-capitalize">Filters</h5>
                        <div>
                          <Filter size={16} />
                        </div>
                      </div>
                      <div>{isOpen ? <ChevronDown /> : <ChevronUp />}</div>
                    </Button>
                    <Collapse isOpen={isOpen === 1}>
                      <div className="d-flex align-items-end gap-3 px-3">
                        <div>
                          <InputField
                            type="date"
                            label="Start Date"
                            onChange={handleFromDate}
                            value={fromDate}
                          />
                        </div>
                        <div>
                          <InputField
                            type="date"
                            label="End Date"
                            onChange={handleToDate}
                            value={toDate}
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="">Category:</label>
                          <select
                            className="form-select"
                            value={selectedOption}
                            onChange={handleOptionChange}
                          >
                            <option value="">--Select--</option>
                            {expenseCategoriesList.map((item, index) => {
                              return (
                                <option value={item?.id} key={index}>
                                  {item?.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="mb-4 d-flex gap-2">
                          <ButtonField
                            className="btn btn-danger"
                            buttonText="Clear Filter"
                            onClick={clearFilter}
                          />
                          <ButtonField
                            buttonText="Apply Filter"
                            type="submit"
                            onClick={filterApply}
                          />
                        </div>
                      </div>
                    </Collapse>
                  </Accordion>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Category</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Date</th>
                          <th scope="col">Description</th>
                          <PermissionBase permissionName={["update_expense"]}>
                            <th scope="col">Action</th>
                          </PermissionBase>
                        </tr>
                      </thead>

                      <tbody>
                        {expenseList?.length ? (
                          expenseList?.map((expense, ind) => (
                            <tr key={ind}>
                              <td>{expense?.id}</td>
                              <td>{expense?.expense_category?.title}</td>
                              <td>{formatNumberandComma(expense?.amount)}</td>
                              <td scope="col">
                                {new Date(
                                  expense?.created_at
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                              </td>
                              <td>{expense?.description}</td>
                              <PermissionBase
                                permissionName={["update_expense"]}
                              >
                                <td>
                                  <span
                                    className="pointer-cursor"
                                    onClick={() => {
                                      handleEditExpense(expense);
                                      setTransition(true);
                                      window.scrollTo({
                                        top: formRef.current.offsetTop,
                                        behavior: "smooth",
                                      });
                                    }}
                                  >
                                    <Edit size={20} color="green" />
                                  </span>
                                </td>
                              </PermissionBase>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">no data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ExpenseListing;
