import React, { Fragment, useEffect, useState, useRef } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { Link } from "react-router-dom";
import { getTransactionServices, inOutStockServices } from "../../services";
import show_Toast from "../../helpers/toast.helper";
import CustomLoader from "../../components/common/customLoader/customLoader";
import AddonForm from "./form";
import FilterForm from "./filterform";
import Search from "../../components/common/search/search";
import { Pagination } from "../../components/common/pagination";
import { PAGE_TITLES } from "../../constant/pageTitles";
import HelmetComponent from "../../components/common/Helmet";

// import "./index.scss";

const InOutStockListing = () => {
  const [isLoading, setLoading] = useState(false);
  const [formDate, setFormDate] = useState("");
  const formRef = useRef(null);

  const [search, setSearch] = useState("");
  const [apply, setApply] = useState(false);
  const [filters, setFilters] = useState({
    order_id: "",
    profile_id: null,
    product_id: "",
    type: "ALL",
  });
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 10,
  });

  const [inOutStockList, setInOutStockList] = useState([]);

  const getInOutStockListing = async () => {
    try {
      setLoading(true);
      const { data } = await inOutStockServices(
        paginationData.perPage,
        paginationData.currentPage,
        filters,
        search
      );
      if (data.status === "Success") {
        setInOutStockList(data?.data?.data);
      }
      setPaginationData((pre) => ({
        ...pre,
        currentPage: data?.data?.current_page,
        totalRecords: data?.data?.total,
        perPage: data?.data?.per_page,
        totalPages: Math.ceil(data?.data?.total / data?.data?.per_page),
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      show_Toast({
        status: false,
        message:
          error?.response?.data?.message ||
          "Something went wrong please reload the page or contact with admin",
      });
    }
  };

  useEffect(() => {
    getInOutStockListing();
  }, [paginationData.currentPage, search, apply]);

  const clearFilter = () => {
    setPaginationData((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
    setFilters({
      order_id: "",
      profile_id: null,
      product_id: "",
      type: "",
    });
    getInOutStockListing();
  };

  const filterApply = () => {
    getInOutStockListing();
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const onChangeOption = (nam, val) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [nam]: val,
    }));
  };

  return (
    <Fragment>
      <HelmetComponent titleText={PAGE_TITLES["InOutStock"]} />
      {isLoading ? (
        <CustomLoader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12"></div>
          </div>
          <div className={`row`}>
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between items-center">
                  <h5 className="text-capitalize">{"In/Out Stocks"}</h5>
                </div>
                <div className="card-body">
                  <div className="mb-3 d-flex justify-content-end">
                    <Search
                      classes="form-control search-input"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        setPaginationData((pre) => ({
                          ...pre,
                          currentPage: 1,
                        }));
                      }}
                      value={search}
                    />
                  </div>
                  <FilterForm
                    filters={filters}
                    onChange={onChange}
                    onChangeOption={onChangeOption}
                    onResetApply={clearFilter}
                    onFilterApply={filterApply}
                  />
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Order Id</th>
                          <th scope="col">Profile Type</th>
                          <th scope="col">Product</th>
                          <th scope="col">Type</th>
                          <th scope="col">Stock</th>
                          <th scope="col">Current Stock</th>
                          <th scope="col">Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {inOutStockList?.length ? (
                          inOutStockList?.map((trans, index) => (
                            <tr key={index}>
                              <td>{trans?.order_id}</td>
                              <td>{trans?.profile_type}</td>
                              <td>{trans?.product_name}</td>
                              <td>{trans?.type}</td>
                              <td>{trans?.stock}</td>
                              <td>{trans?.current_stock}</td>
                              <td>
                                {" "}
                                {new Date(trans?.created_at).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  }
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">no data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <div className="mt-4 mb-1">
                      <Pagination
                        selectedPage={paginationData.currentPage}
                        pageCount={paginationData.totalPages}
                        onPageChangeHandler={(page) => {
                          setPaginationData({
                            ...paginationData,
                            currentPage: page,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default InOutStockListing;
