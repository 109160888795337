import { Form, Formik } from "formik";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import httpRequest from "../../axios";
import {
  CREATE_UNIT_CATEGORIES,
  UNIT_CATEGORIES,
  CREATE_EXPENSE_CATEGORIES,
  UPDATE_EXPENSE_CATEGORIES,
} from "../../constant/apiEndPoints";
import show_Toast from "../../helpers/toast.helper";
import { ExpenseCategoryValidations } from "../../utils/validations/expenses.validations";
import CustomLoader from "../../components/common/customLoader/customLoader";
import { hasPermissions } from "../../helpers/permissions.helper";
import { PERMISSION_NAMES } from "../../constant/routePermissions";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const ExpenseForm = ({
  initialValues = {
    title: "",
  },
  type = "add",
  formSuccessHandler = () => {},
  setTransition,
  editId = "",
}) => {
  const history = useHistory();
  const { permissions } = useSelector((state) => state.login);

  const [isLoading, setLoading] = useState(false);

  const createExpense = async (values, setSubmitting) => {
    setLoading(true);
    try {
      if (type === "add") {
        var { data } = await httpRequest.post(
          CREATE_EXPENSE_CATEGORIES,
          values
        );
      } else {
        var { data } = await httpRequest.put(
          UPDATE_EXPENSE_CATEGORIES + "/" + editId,
          values
        );
      }

      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: true,
        message:
          data.status === "Success" && type === "edit"
            ? "Expense Category Updated successfully"
            : "Expense Category Created successfully",
      });
      formSuccessHandler();
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        if (permissions.length) {
          if (
            !hasPermissions(
              permissions,
              PERMISSION_NAMES["create_expense_categories"]
            )
          ) {
            return <Redirect to={{ pathname: "/not-allowed" }} />;
          }
        }
        createExpense(values, setSubmitting);
      }}
      validationSchema={ExpenseCategoryValidations}
      enableReinitialize
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          resetForm,
          isSubmitting,
        } = props;
        return (
          <>
            {isLoading ? (
              <CustomLoader />
            ) : (
              <Form className={`form theme-form`}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <InputField
                        placeholder="Title"
                        label="title"
                        type="text"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errorMessage={
                          errors.title && touched.title && errors.title
                        }
                        error={errors.title && touched.title ? true : false}
                      />
                    </div>
                    <div className="col-sm-6 d-flex align-items-center justify-content-end justify-content-sm-start gap-2">
                      <ButtonField
                        buttonText="Cancel"
                        type="button"
                        className="btn btn-red"
                        onClick={() => {
                          setTransition(false);
                          resetForm();
                        }}
                      />
                      <ButtonField
                        className={"btn btn-primary me-2"}
                        buttonText={type === "edit" ? "Update" : "Create"}
                        disabled={isSubmitting}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default ExpenseForm;
