import React from "react";

const CheckBoxField = ({
  title,
  checkId,
  checkFor,
  value,
  defaultChecked = false,
  onClick = () => {},
}) => {
  return (
    <div className="card-body122 animate-chk">
      <div className="row">
        <div className="col">
          <label className="d-block" htmlFor={checkFor}>
            <input
              className="checkbox_animated"
              name="check-box"
              id={checkId}
              onChange={onClick}
              value={value}
              checked={defaultChecked}
              type="checkbox"
              defaultChecked
            />
            {title}
          </label>
        </div>
      </div>
    </div>
  );
};

export default CheckBoxField;
