import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  configurations: [],
};

const configurationsReducer = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {
    setConfigurations: (state, action) => {
      state.configurations = action.payload;
    },
    setConfigurationsEmpty: (state, action) => {
      state.configurations = [];
    },
  },
});

export const { setConfigurations, setConfigurationsEmpty } =
  configurationsReducer.actions;
export default configurationsReducer.reducer;
