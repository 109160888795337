import React, { useEffect } from "react";
import { calculateGST, formatNumber } from "../../../services";

const PayableAmount = ({ payable, setPayable, products, paid_amount }) => {
  let totalPriceSingleProduct = products.reduce((sum, item) => {
    const { totalPrice = 0, discount = 0, productGst = 0 } = item;
    return (
      sum +
      (totalPrice - discount + calculateGST(totalPrice - discount, productGst))
    );
  }, 0);

  // let totalPriceSingleProduct = products.reduce(
  //   (sum, item) =>
  //     sum +
  //     parseFloat(
  //       item.totalPrice -
  //         parseFloat(item.discount || 0) +
  //         calculateGST(
  //           item.totalPrice - parseFloat(item.discount || 0),
  //           item.productGst
  //         ) || 0
  //     ),
  //   0
  // );

  const calculateOrderAmount = () => {
    const TotalAmount = Number(totalPriceSingleProduct.toFixed(2));
    setPayable(TotalAmount);
  };

  useEffect(() => {
    calculateOrderAmount();
  }, [products, paid_amount]);

  return <h3>Payable: {payable}</h3>;
};

export default PayableAmount;
