import React, { useState } from "react";
import { Formik, Form } from "formik";
import logo from "../../assets/images/endless-logo.png";
import { RESETPASSWORD } from "../../constant";
import { resetPasswordSchema } from "../../utils/validations/auth.validations";
import InputField from "../../components/form/input/input";
import ButtonField from "../../components/form/button";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setIsAuthenticated,
  setPermissions,
  setUser,
} from "../../store/slices/login";
import { setAttributeMenus, setUnitsMenus } from "../../store/slices/menus";
import httpRequest from "../../axios/index";
import show_Toast from "../../helpers/toast.helper";
import { useParams } from "react-router-dom";

import {
  RESET_ENDPOINT,
  ATTRIBUTE_KEYS,
  All_UNITS,
} from "../../constant/apiEndPoints";
import { useLocation } from "react-router-dom";
const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const token = queryParams.get("token");
  const dispatch = useDispatch();

  const resetPassword = async (values, setSubmitting) => {
    let payload = { email: email, token: token, ...values };
    try {
      const response = await httpRequest.post(RESET_ENDPOINT, payload);

      if (response?.data?.status === "Success") {
        show_Toast({
          status: true,
          message: "You password reset sucessfully!",
        });
        history.push("/login");
      }
    } catch (error) {
      setSubmitting(false);
      show_Toast({
        status: false,
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{RESETPASSWORD}?</h4>
                          <p>Make new password</p>
                        </div>
                        <Formik
                          initialValues={{
                            password: "",
                            confirm_password: "",
                          }}
                          validationSchema={resetPasswordSchema}
                          onSubmit={(values, { setSubmitting }) => {
                            resetPassword(values, setSubmitting);
                          }}
                          enableReinitialize
                        >
                          {(props) => {
                            const {
                              values,
                              touched,
                              errors,
                              handleBlur,
                              handleChange,
                              isSubmitting,
                            } = props;
                            return (
                              <Form className="theme-form">
                                <InputField
                                  placeholder="Password"
                                  label="Password"
                                  type="password"
                                  name="password"
                                  classes="pe-5"
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errorMessage={
                                    errors.password &&
                                    touched.password &&
                                    errors.password
                                  }
                                  error={
                                    errors.password && touched.password
                                      ? true
                                      : false
                                  }
                                />

                                <InputField
                                  placeholder="Confirm Password"
                                  label="Confirm Password"
                                  type="password"
                                  name="confirm_password"
                                  classes="pe-5"
                                  value={values.confirm_password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errorMessage={
                                    errors.confirm_password &&
                                    touched.confirm_password &&
                                    errors.confirm_password
                                  }
                                  error={
                                    errors.confirm_password &&
                                    touched.confirm_password
                                      ? true
                                      : false
                                  }
                                />
                                <div className="form-group form-row mt-3 mb-0 d-grid">
                                  <ButtonField
                                    buttonText="Reset"
                                    type="Submit"
                                  />
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
